import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { toastr } from 'react-redux-toastr';
import { selectStatusByKey, selectEngagements } from 'store/selectors/admin-selectors';
import { loadEngagements, unapproveMultiSets } from 'store/actions/admin-actions';
import { adminDialogClasses } from 'features/common/dialog-classes';
import { WaitContainer, ErrorDisplay, WaitButton } from 'features/common';
import { useInputHandler } from 'hooks/general-hooks';
import { normalizeError } from 'utils/general-helpers';
import { validatePropertyCondition, validateExists, createValidationError } from 'utils/validation-helper';

const defaultItem   = {engId: "", rsIds: "", notes: "", signature: "", confirm: ""};

const UnapproveReviewSetTask = () => {
  const { classes }   = buildStyles();
  const dispatch  = useDispatch();
  const user          = useSelector(state => state.settings.user);
  const status        = useSelector(state => selectStatusByKey(state, "unapprove-task"));
  const engagements   = useSelector(selectEngagements);
  const [item, onInputChange, setItem]  = useInputHandler({...defaultItem}, null, onValidate);
  const [validation, setValidation]	= useState({count: 1});
  const [error, setError]         = useState(null);
  const [props, setProps]         = useState({});

  //---
  //Load all the engagements for the list
  useEffect(() => {
    if(!engagements || engagements.params?.filter !== "all"){
      dispatch(loadEngagements({filter: "all"}, 1, 999));
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Reset things if they choose a new engagement
  useEffect(() => {
    if(item.engId){
      //clear things out
      setValidation({count: 1});
      setItem({...defaultItem, engId: item.engId});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.engId]);

  function onValidate(toValidate){
    //Check that we have values for notes, signature, confirmation
    let vState  = validateExists(toValidate) || {count: 0};

    //If the confirm field has changed, validate it
    if(toValidate.confirm !== item.confirm){
      const cState  = validatePropertyCondition(vState, "confirm", toValidate, v => v.length > 0 && v.toLowerCase() === "unapprove");
      vState        = {...vState, ...cState};
    }

    //If signature has changed, validate it
    if(toValidate.signature !== item.signature){
      const sState  = validatePropertyCondition(vState, "signature", toValidate, v => v.length > 0 && v.toLowerCase() === user.displayName.toLowerCase());
      vState        = {...vState, ...sState};
    }

    //Check that the ReviewSet Ids field can be parsed (if it has changed)
    if(toValidate.rsIds !== props.rsIds){
      try{
        const ids   = parseIds(toValidate.rsIds);
        // vState      = {count: 0};
        setProps({...props, rsIds: ids});
      }
      catch(ex){
        const errors  = createValidationError("rsIds", true, ex.message, validation);
        vState  ={vState, ...errors, count: (vState.count || 0) + 1};
      }
    }
    
    setValidation(vState);
  }

  //Does the work on unapproving the reviewsets
  async function onUnapprove(){
    setError(null);
    const result  = await dispatch(unapproveMultiSets(item.engId, props.rsIds, item.signature, item.notes));
    if(!result.ok){
      const error   = normalizeError(result.response, "Unapprove failed");
      setError(error);
    }
    else{
      toastr.success("ReviewSets Unapproved Successfully");
      setItem({...defaultItem});
      setValidation({count: 1});
    }
  }

  return (
    <Grid container className={classes.taskRoot}>
      <WaitContainer isWaiting={!engagements} message="Initializing...">

        <Grid item xs={7} container>
          <Grid item xs={10} className={classes.formRow}>
            <FormControl className={classes.formControl} label="Engagement" fullWidth>
              <InputLabel id="label-engid" className={classes.inputLabel}>Engagement</InputLabel>
              <Select name="engId" labelId="label-engid" label="Engagement" value={item.engId} onChange={onInputChange} disabled={status.isWorking} fullWidth className={classes.selectContainer} classes={{select: classes.select}}  size="small">
                <MenuItem value="" disabled className={classes.option}><em>Select Engagement</em></MenuItem>
                {engagements && _.map(engagements.items, option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.name}</MenuItem>)}              
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.formRow}>
            <TextField name="rsIds" label="ReviewSet IDs" value={item.rsIds} onChange={onInputChange} multiline minRows={3} 
            fullWidth className={classes.formControl} placeholder="Enter a range of Ids (100 - 120) or specific ids separated by a comma (100, 102, 105)"
            error={validation?.rsIds?.hasError} helperText={validation?.rsIds?.message}/>
          </Grid>

          <Grid item xs={12} className={classes.formRow}>
            <TextField name="notes" label="Notes" value={item.notes} onChange={onInputChange} multiline minRows={3} fullWidth className={classes.formControl} placeholder="Enter the reason for unapproving.  This will be applied to all ReviewSets."/>
          </Grid>

          <Grid item xs={8} className={classes.formRow}>
            <TextField name="signature" label="Signature" value={item.signature} onChange={onInputChange} fullWidth className={classes.formControl} placeholder="Enter your full name"/>
          </Grid>

          <Grid item xs={8} className={classes.formRow}>
            <TextField name="confirm" label="Confirmation" value={item.confirm} onChange={onInputChange} fullWidth className={classes.formControl} placeholder="Type 'unapprove' to continue"/>
          </Grid>
        </Grid>

        <Grid item xs={5} container direction="column" justifyContent="space-between">
          <Typography className={classes.info}>
            This action will affect multiple ReviewSets. Please confirm that all ReviewSets listed above should be unapproved. The notes and signature above will be applied to all unapprovals. If any of the ids above are invalid or already approved, this action will be rolled back and no changes will be made. There is no confirmation dialog, clicking the Unapprove button will initiate the action. To confirm that you understand this, please type "unapprove" in the confirm text field to the left.
          </Typography>

          <Grid item container justifyContent="flex-end">
            <WaitButton color="primary" size="small" isWaiting={status.isWorking} disabled={validation?.count > 0} onClick={onUnapprove}>Unapprove</WaitButton>
          </Grid>

        </Grid>

        <Grid container>
          <ErrorDisplay error={error} />
        </Grid>

      </WaitContainer>
    </Grid>
  );
}

export default UnapproveReviewSetTask;

const buildStyles   = makeStyles()(theme => ({
  ...adminDialogClasses(theme),
  taskRoot  : {
    padding     : `${theme.spacing(1)} ${theme.spacing(0)}`, //`
  },
  info  : {
    margin    : `${theme.spacing(2)} ${theme.spacing(2)}`, //`,
    fontSize  : 14,
    fontWeight: 300,
  }
}));

function parseIds(idString, isValidateOnly){

  var parseItem   = function(item){
      if(item.indexOf('-') > 0){
          //Validate the range
          let parts   = item.split('-');  //shouldn't be spaces after earlier actions
          let start   = parseInt(parts[0]);
          let end     = parseInt(parts[1]);
          if(_.isNaN(start) || _.isNaN(end) || start >= end){
              throw new Error(`${item} is an invalid range`);
          }
          let items   = [];
          for(var i = start; i <= end; i++){ items.push(i); }
          return items;   //return an array here.  will be flattened later
      }
      else{
          var id  = parseInt(item);
          if(_.isNaN(id) || !id){
              throw new Error(`${item} is an invalid id`);
          }
          else{
              return id;
          }
      }
  }

  let working     = idString.replace(/\s/g,'');
  working         = _.trimEnd(working, "-,");
      if(working.length > 0){
      let arr         = working.split(',');
      arr             = _.map(arr, parseItem);
      arr             = _.flatten(arr);
      return arr;
  }

  return [];
}