import { ADMIN_ACTIONS } from '../actions/admin-actions';
import { createReducer, INITIALIZED } from 'utils/reducer-helper';
import { addOrReplace, removeItem } from 'store/store-helpers';

const DEFAULT_STATUS  = {
  isWorking         : false,
  isInitialized     : false,
  error             : null,
};

const INITIAL_STATE   = {
  status        : {
    users           : {...DEFAULT_STATUS},
    engagements     : {...DEFAULT_STATUS},
    assignments     : {...DEFAULT_STATUS},
    doctypes        : {...DEFAULT_STATUS},
    checklists      : {...DEFAULT_STATUS},
    reportQueue     : {...DEFAULT_STATUS},
  },

  allEngagements  : null,
  users       : null,
  engagements : null,
  assignments : null,
  engAssignments : null,
  usrAssignments : null,
  doctypes    : null,
  checklists  : null,
  checklist   : null,
  reportQueue : null,

  currentDocType  : null,
};

function statusChanged(state, action){
  const current   = state.status[action.key];
  const updated   = {...current, ...action.value};
  return {
    ...state,
    status  : {
      ...state.status,
      [action.key]  : {
        ...state.status[action.key],
        ...updated
      },
    }
  };
}

//#region Users

function usersLoaded(state, action){
  return {
    ...state,
    users     : {
      items       : action.response,
      pagination  : action.pagination,
      loadTime    : new Date(),
      lastFilter  : action.filter,
    },
    status    : {
      ...state.status,
      users   : {...INITIALIZED},
    }    
  };
}

function userSaved(state, action){
  if(action.ok){
    return {
      ...state,
      users   : {
        ...state.users,
        items   : addOrReplace(state.users.items, u => u.id === action.userId, action.response),
      },
      status  : {
        ...state.status,
        users   : {...INITIALIZED},
      }
    }
  }
  return state;
}

//#endregion

function allEngagementsLoaded(state, action){
  return {
    ...state,
    allEngagements     : {
      items       : action.response,
      pagination  : action.pagination,
      loadTime    : new Date(),
      params      : action.params,
    },
    status    : {
      ...state.status,
      engagements   : {...INITIALIZED}
    }    
  };
}

function engagementsLoaded(state, action){
  return {
    ...state,
    engagements     : {
      items       : action.response,
      pagination  : action.pagination,
      loadTime    : new Date(),
      params      : action.params,
    },
    status    : {
      ...state.status,
      engagements   : {...INITIALIZED}
    }    
  };
}

function engagementAssignmentsLoaded(state, action){

  return {
    ...state,
    engAssignments   : {
      items         : action.response,
      loadTime      : new Date(),
      params        : action.params,
      engagementId  : action.engagementId,
    },
    status    : {
      ...state.status,
      assignments   : {...INITIALIZED}
    }    
  };
}

function userAssignmentsLoaded(state, action){
  return {
    ...state,
    usrAssignments   : {
      items         : action.response,
      loadTime      : new Date(),
      params        : action.params,
      userId        : action.userId,
    },
    status    : {
      ...state.status,
      assignments   : {...INITIALIZED}
    }    
  };
}

function assignmentSaved(state, action){
  if(action.ok){
    const assignment = action.response;
    let engAssignments = state.engAssignments;
    let usrAssignments = state.usrAssignments;

    if(engAssignments && engAssignments.engagementId === assignment.entityId){
      //change is to the current displayed engagement
      const existing = engAssignments.items.find(a => a.id === action.id);
      engAssignments = {
        ...engAssignments,
        items   : addOrReplace(engAssignments.items, u => u.id === action.id, {...existing, ...assignment}),
      };
    }
    
    if(usrAssignments && usrAssignments.userId === assignment.assignedUserId){
      //the change is to the currently displayed user
      const existing = usrAssignments.items.find(a => a.id === action.id);
      usrAssignments = {
        ...usrAssignments,
        items   : addOrReplace(usrAssignments.items, u => u.id === action.id, {...existing, ...assignment}),
      };
    }

    return {
      ...state,
      engAssignments,
      usrAssignments,
      status  : {
        ...state.status,
        assignments   : {...INITIALIZED},
      }
    };
    
  }
  return state;
}

function assignmentDeleted(state, action){
  if(action.ok){
    const assignment = action.response;
    const engagementId = action.engagementId;
    let engAssignments = state.engAssignments;
    let usrAssignments = state.usrAssignments;

    if(engAssignments && 
        (engAssignments.engagementId === assignment.entityId ||
         engAssignments.engagementId === engagementId)
      ){
      //changing the engagement
      engAssignments = {
        ...engAssignments,
        items   : removeItem(engAssignments.items, u => u.id === action.id),
      };
    }
    
    if(usrAssignments && usrAssignments.userId === assignment.assignedUserId){
      //the change is to the currently displayed user
      usrAssignments = {
        ...usrAssignments,
        items   : removeItem(usrAssignments.items, u => u.id === action.id),
      };
    }

    return {
      ...state,
      engAssignments,
      usrAssignments,
      status  : {
        ...state.status,
        assignments   : {...INITIALIZED},
      }
    };
  }
  return state;
}

function settingSaved(state, action, key){
  if(action.ok){
    let stateChanges  = null;
    
    if(action.key === "engagements"){
      //need to update this reducer as well
      stateChanges  = {
        engagements   : {
          ...state.engagements,
          items   : addOrReplace(state.engagements?.items ?? [], u => u.id === action.id, action.response),
        },
        allEngagements : {
          ...state.allEngagements,
          items   : addOrReplace(state.engagements?.items ?? [], u => u.id === action.id, action.response),
        }
      }
    }
    else if(action.key === "doctypes" || key === "doctypes"){
      stateChanges = {
        doctypes : {
          ...state.doctypes,
          items: addOrReplace(state.doctypes?.items ?? [], i => i.id === action.id, action.response),
        }
      };
    }

    return {
      ...state,
      ...stateChanges,
      status  : {
        ...state.status,
        [key]   : {...INITIALIZED},
      }
    }
  }
  return state;
}

function checklistsLoaded(state, action){
  return {
    ...state,
    checklists     : {
      items       : action.response,
      pagination  : action.pagination,
      loadTime    : new Date(),
      params      : action.params,
    },
    status    : {
      ...state.status,
      checklists   : {...INITIALIZED}
    }    
  };
}

function checklistLoaded(state, action){
  return {
    ...state,
    checklist     : action.response,
    status    : {
      ...state.status,
      checklists   : {...INITIALIZED}
    }    
  };
}

function docTypesLoaded(state, action){
  return {
    ...state,
    doctypes  : {
      items       : action.response,
      pagination  : action.pagination,
      loadTime    : new Date(),
      params      : action.params,
    },
    status    : {
      ...state.status,
      doctypes   : {...INITIALIZED}
    }    
  };
}

function docTypeLoaded(state, action){
  return {
    ...state,
    currentDocType  : action.response,
    status    : {
      ...state.status,
      doctypes   : {...INITIALIZED}
    }    
  };
}

function docTypeCopiedOrVersioned(state, action){
  //If there is no state.doctypes loaded, then we're in the engagement view, and doctypes are pulled from settings reducer
  const dtState = state.doctypes ? { ...state.doctypes, items: [action.response, ...state.doctypes.items] } : state.doctypes;
  
  return {
    ...state,
    doctypes  : dtState,
    status  : {
      ...state.status,
      doctypes   : {...INITIALIZED}
    }
  };
}

function reportQueueLoaded(state, action){
  return {
    ...state,
    reportQueue  : {
      items       : action.data ?? action.response,
      pagination  : action.pagination,
      loadTime    : new Date(),
      params      : action.params,
    },   
  };
}

//----------------------
// The action map that is turned into the reducer
const adminReducer    = {

  [ADMIN_ACTIONS.STATUS_CHANGE]    : statusChanged,
  [ADMIN_ACTIONS.USERS_LOADED]     : usersLoaded,
  [ADMIN_ACTIONS.USER_SAVED]       : userSaved,
  [ADMIN_ACTIONS.ENGAGEMENTS_LOADED]: engagementsLoaded,
  [ADMIN_ACTIONS.ALL_ENGAGEMENTS_LOADED]: allEngagementsLoaded,
  [ADMIN_ACTIONS.ASSIGNMENTS_LOADED]: engagementAssignmentsLoaded,
  [ADMIN_ACTIONS.USER_ASSIGNMENTS_LOADED] : userAssignmentsLoaded,
  [ADMIN_ACTIONS.ASSIGNMENT_SAVED]  : assignmentSaved,
  [ADMIN_ACTIONS.ASIGNMENT_DELTED]  : assignmentDeleted,
  [ADMIN_ACTIONS.CHECKLISTS_LOADED] : checklistsLoaded,
  [ADMIN_ACTIONS.CHECKLIST_LOADED]  : checklistLoaded,
  [ADMIN_ACTIONS.DOCTYPES_LOADED]   : docTypesLoaded,
  [ADMIN_ACTIONS.DOCTYPE_VERSIONED] : docTypeCopiedOrVersioned,
  [ADMIN_ACTIONS.DOCTYPE_COPIED]    : docTypeCopiedOrVersioned,
  [ADMIN_ACTIONS.DOCTYPE_LOADED]    : docTypeLoaded,
  [ADMIN_ACTIONS.REPORTQUEUE_LOADED]: reportQueueLoaded,
  
  // [ADMIN_ACTIONS.ENGAGEMENT_SAVED]  : engagementSaved,
  [ADMIN_ACTIONS.DOCTYPE_CIRCULATION_TOGGLED]: (state, action) => settingSaved(state, action, "doctypes"),
  [ADMIN_ACTIONS.SETTING_SAVED]     : (state, action) => settingSaved(state, action, action.key),
};

//The reducer
export default createReducer(INITIAL_STATE, adminReducer);