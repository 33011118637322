import { createStore } from 'redux';
import configureReducers from './reducers/root-reducer';
import configureMiddleware from './middleware-config';

// let _store = null;

export default function configureStore(preloadedState) {

  const initialState  = window.initialReduxState;
  const rootReducer   = configureReducers(preloadedState);
  const middlewares   = configureMiddleware();

  const store   = createStore(
    rootReducer,
    initialState,
    middlewares,
  );

  //For HMR, try to hot-replace the reducer, if possible (doesn't always work)
  if(process.env.NODE_ENV === "development"){
    
    console.log("attempting to hot replace reducer");

    if(module.hot){
      module.hot.accept("./reducers/root-reducer.js", () => {
        const newRoot   = require("./reducers/root-reducer").default;
        store.replaceReducer(newRoot);
      });
    }
  }

  return store;
}
