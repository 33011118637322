import _ from 'lodash';
import { DOC_ACTIONS } from '../actions/document-actions';
import { createReducer } from 'utils/reducer-helper';
import { addOrReplace, removeItem } from '../store-helpers';
import { prepareCheckList } from 'utils/checklist-helpers';

const INITIAL_STATE   = {
  responses     : [],
  //No initial state, it will get populate with the items, keyed by their check list item id
}

const _itemKey  = (action) => action.props.checkListItemId;
const _inputKey = (action) => action.props.checkListInputId;

function docLoaded(state, action){
  //Need to clear out the state from the previous document...
  const doc           = action.response;
  const primaryRR     = _.find(doc.reviewRecords, rr => rr.primaryReviewRecordId === null);
  const responses     = primaryRR.checkListResponses;
  const checkLists    = _.map(doc.checkLists, cl => prepareCheckList(cl));
  const items         = _.flatMap(checkLists, cl => _.map(cl.checkListItems, cli => reduceCheckListItem(cli, responses)));

  //reduce the array of items into an object with the id of the item as the key
  const itemsObj  = items.reduce((obj, val) => { 
    const next  = {...obj, [val.id] : val};
    return next;
  }, {});

  return {
    ...state,
    responses   : responses,
    ...itemsObj
  };
}

function docError(state, action){
  return INITIAL_STATE;   //reset the state...
}

function inputResponding(state, action){
  const itemKey   = _itemKey(action);
  const inputKey  = _inputKey(action);

  const existing  = state[itemKey];
  const updates   = {
    ...existing,
    isWorking     : true,
    error         : null,
    responding    : addOrReplace(existing.responding, r => r.id === inputKey,
      {
        id          : inputKey,
        isWorking   : true,
        error       : null,
      }),
  }

  return {
    ...state,
    [itemKey]   : updates,
  }
}

function inputResponded(state, action){
  const itemKey     = _itemKey(action);
  const inpKey      = _inputKey(action);
  const existing    = state[itemKey];
  const isDelete    = action.props.isDelete;

  //Add the response to the collection for this item...
  const newResponses  = isDelete ? removeItem(state.responses, r => r.checkListInputId === inpKey) : addOrReplace(state.responses, r => r.checkListInputId === inpKey, action.response);
  const newResponding = removeItem(existing.responding, r => r.id === inpKey) || [];
  const updated       = reduceCheckListItem(existing, newResponses, newResponding);
  
  return {
    ...state,
    responses   : newResponses,
    [itemKey]   : updated,
  };
}

function inputResponseError(state, action){
  const itemKey   = _itemKey(action);
  const inputKey  = _inputKey(action);
  const existing  = state[itemKey];

  const value     = {
    id          : inputKey,
    isWorking   : false,
    error       : action.response,
  };
  
  const newItem   = {
    ...existing,
    isWorking   : false,
    error       : action.response,
    responding  : addOrReplace(existing.responding, r => r.id === inputKey, value)
  };
  
  return {
    ...state,
    [itemKey]   : newItem,
  };
}


//----------------------
// The action map that is turned into the reducer
const docReducer    = {

  [DOC_ACTIONS.ITEM_LOADED]      : docLoaded,
  [DOC_ACTIONS.ITEM_ERROR]       : docError,

  [DOC_ACTIONS.INPUT_RESPONDING]  : inputResponding,
  [DOC_ACTIONS.INPUT_RESPONDED]   : inputResponded,
  [DOC_ACTIONS.INPUT_ERROR]       : inputResponseError,
};

//The reducer
export default createReducer(INITIAL_STATE, docReducer);

function reduceCheckListItem(cli, responses, responding){
  const inputIds    = _.map(cli.checkListInputs, inp => inp.id);
  const boolInput   = _.find(cli.checkListInputs, inp => inp.discriminator === "Bool");
  const myResponses = _.compact(_.map(inputIds, id => _.find(responses, rsp => rsp.checkListInputId === id)));
  const bResponse   = _.find(myResponses, rsp => rsp.checkListInputId === boolInput.id);

  let item   = {
    ...cli,
    responses       : myResponses,
    primaryInput    : boolInput,
    primaryResponse : bResponse,
    isWorking       : false,
    error           : null, 
    responding      : responding || [],     
  };

  return item;
}