import _ from 'lodash';
import { createSelector } from 'reselect';

const _getDocument        = state => state.doc.item;
const _getDocuments       = state => state.docs.items;
const _getResponses       = state => state.clitems.responses;
const _getStatuses        = state => state.doc.itemStatuses;
const _getCheckLists      = state => state.doc.itemCheckLists;
const _getExistingItems   = state => state.docs.existingItems;
const _getSettings        = state => state.settings;
const _getProtocols       = state => state.settings.protocols;
// const _inEngagementId     = (state, engagementId) => engagementId;

export const selectExistingDocs = createSelector(
  [_getExistingItems, _getSettings],
  (existing, settings) => {
    if(!existing || !settings) return null;

    const docTypes  = settings.documentTypes;
    let pairs       = {};
    const getMatch  = (key) => {
      if(!pairs[key]){
        pairs[key]  = _.find(docTypes, dt => dt.id === key);
      }
      return pairs[key];
    }
    const docs      = _.map(existing, e => {
      return { 
        ...e,
        documentType  : getMatch(e.documentTypeId),
      };
    });

    return docs;

  }
);

export const selectDocuments = createSelector(
  [_getDocuments, _getSettings],
  (docs, settings) => {
    if(!docs || docs.length === 0) return null;

    if(!docs.every(d => !!d.reviewRecords)){
      //This is the ReviewSets list
      return docs;
    }
    else{
      //This is the engagements list
      const unique      = _.uniqBy(docs, d => d.id);
      const withTypes   = fillDocTypes(settings.documentTypes, unique);
      const final       = _.map(withTypes, d => { 
        const prr   = d.reviewRecords.length === 1 ? d.reviewRecords[0] : _.find(d.reviewRecords, rr => rr.primaryReviewRecordId === null);
        return {
          ...d, 
          primaryReviewSetId  : prr.reviewSetId,
        }
      });

      return final;
    }
  }
)

//------------------
// Gets the document with additional relevant information necessary
// for the different views
export const selectDocument = createSelector(
  [_getDocument, _getStatuses, _getSettings, (_, rsId) => rsId],
  (doc, statuses, settings, reviewSetId) => {
    if(!doc || !reviewSetId) return null;

    // console.log("** Doc Selector", reviewSetId);
    //ReviewRecord for this reviewset
    const rsetId    = reviewSetId ? parseInt(reviewSetId) : -1;
    const crr       = reviewSetId ? _.find(doc.reviewRecords, rr => rr.reviewSetId === rsetId) : null;
    if(!crr) return null;     //don't have the current document yet

    //Primary review record
    const prr         = doc.primaryReviewRecord; //_.find(doc.reviewRecords, rr => rr.primaryReviewRecordId === null);
    //approved checklist statuses
    const approved    = _.filter(statuses, cls => cls.status === "APPROVE");
    //document / review record is approved if every checklist is approved
    const isApproved  = doc.documentType.checkListCount === approved.length;

    //Get the input labels (confirm / reject)
    const protocol    = _.find(settings.protocols, p => p.id === doc.documentType.protocolId);  //doc type has the protocol
    const labels      = {
      confirm       : protocol.propertyBag["qConfirmLabel"] || "Confirm",
      reject        : protocol.propertyBag["qRejectLabel"] || "Reject",
      na            : "N/A",
    }

    return {
      ...doc,
      primaryReviewRecord   : prr,
      currentReviewRecord   : crr,
      isPrimary             : Boolean(prr.id === crr.id), //not the same objects because we modify the primary in the reducer...
      isApproved            : isApproved,
      
      statusCode            : isApproved ? 100 : 0,
      inputLabels           : labels,
    };
  }
);

export const selectInputLabels = createSelector(
  [_getDocument, _getProtocols],
  (doc, protocols) => {
    //Get the input labels (confirm / reject)
    const protocol    = _.find(protocols, p => p.id === doc.documentType.protocolId);  //doc type has the protocol
    const labels      = {
      confirm       : protocol.propertyBag["qConfirmLabel"] || "Confirm",
      reject        : protocol.propertyBag["qRejectLabel"] || "Reject",
      na            : "N/A",
    };

    return labels;
  }
)

//----------
// Gets the progress of a document, meaning how many of the checklist quesitons have been answered (across all checklists).
// returns an array: [answeredCount, totalCount]
export const selectDocumentProgress = createSelector(
  [_getCheckLists, _getResponses],
  (checkLists, responses) => {
    if(!checkLists || checkLists.length === 0) return [0,0];

    // const prr         = _.find(doc.reviewRecords, rr => rr.primaryReviewRecordId === null);
    // const responses   = prr.checkListResponses;
    const assertions  = _.flatMap(checkLists, list => { return _.filter(list.checkListItems, itm => itm.discriminator !== "Section"); });
    
    const finished      = _.filter(assertions, asn => { 
        let answer      = false;
        if(asn.checkListInputs && responses){
            //Need to filter out the comments since they aren't relevant in this calculation
            //Need to find specific responses so that we don't confuse a comment response with a response to a required element
            const inputs    = _.filter(asn.checkListInputs, cli => cli.discriminator !== "Comment");
            const matches   = _.filter(responses, rsp => _.findIndex(inputs, inp => inp.id === rsp.checkListInputId) >= 0);
            answer          = inputs.length === matches.length;
        }
        return answer;
    });

    return [finished.length, assertions.length];

  }
);

function fillDocTypes(docTypes, items){
  let pairs       = {};
  const getMatch  = (key) => {
    if(!pairs[key]){
      pairs[key]  = _.find(docTypes, dt => dt.id === key);
    }
    return pairs[key];
  }
  
  const docs      = _.map(items, e => {
    const match   = getMatch(e.documentTypeId);
    return { 
      ...e,
      documentType      : match,
      documentTypeName  : match?.name,
    };
  });

  return docs;
}