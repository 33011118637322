import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import EngagementRouter from 'features/engagements/engagement-router';
import ReportsView from 'features/reports/reports-view';
import AdminView from 'features/admin/admin-view';
import TestingView from 'features/testing/testing-view';
import { appRoutes } from 'utils/route-helpers';
import AppLayout from './app-layout';

const AppRouter = () => {

  return (
    <AppLayout >
      <div id="app-router">
        <Routes>

          <Route path="engagements/*">
            <Route path=":engagementId/*" element={<EngagementRouter />} />        
          </Route>

          <Route path={appRoutes.reports.list} element={<ReportsView />}/>
          <Route path={appRoutes.test} element={<TestingView />} />

          <Route path={"admin/*"} element={<AdminView />} />
        </Routes>

        <Outlet />
      </div>
    </AppLayout>
  );
};

export default AppRouter;