import _ from 'lodash';
import { APP_ACTIONS } from '../actions/app-actions';
import { ADMIN_ACTIONS } from '../actions/admin-actions';
import { addOrReplace } from 'store/store-helpers';
import { createReducer } from '../../utils/reducer-helper';

const INITIAL_STATE   = {
  isInitialized       : false,
  isWorking           : false,
  error               : null,
  
  //settings init
};

function settingsLoaded(state, action){
  const settings = action.response.settings;
  return {
    ...state,
    isInitialized     : true,
    // ...action.response,
    ...settings,
  };
}

function settingSaved(state, action, key = null){
  //Make sure this is a setting we care about / have
  const myKey   = action.key === "doctypes" ? "documentTypes" : action.key || key;
  if(state[myKey] === undefined) return state;

  return {
    ...state,
    [myKey]   : addOrReplace(state[myKey], item => item.id === action.response.id, action.response),
  };
}

function docTypeVersioned(state, action){
  const originalId  = action.id;
  const newId       = action.response.id;

  //Mark the existing one as having a new version
  const existing    = _.find(state.documentTypes, dt => dt.id === originalId);
  const updated     = {...existing, propertyBag   : {...existing.propertyBag, nextVersion: newId.toString() }};
  let items         = addOrReplace(state.documentTypes, i => i.id === existing.id, updated);
  items             = [...state.documentTypes, action.response];

  let newState  = {...state};
  newState.documentTypes  = items;
  return newState;
}

function docTypeCopied(state, action){
  //Returns a new doc type, so just add it to the collections
  if(!state.documentTypes) return state;  //nothing changed if we don't have the Document Types loaded here

  return {
    ...state,
    documentTypes: [...state.documentTypes, action.response],
  };
}
//----------------------
// The action map that is turned into the reducer
const settingsReducer    = {
  [APP_ACTIONS.SETTINGS_LOADED]     : settingsLoaded,

  [ADMIN_ACTIONS.SETTING_SAVED]     : settingSaved,
  [ADMIN_ACTIONS.DOCTYPE_VERSIONED] : docTypeVersioned,
  [ADMIN_ACTIONS.DOCTYPE_COPIED]    : docTypeCopied,
  [ADMIN_ACTIONS.DOCTYPE_CIRCULATION_TOGGLED]: (state, action) => settingSaved(state, action, "documentTypes"),
};

//The reducer
export default createReducer(INITIAL_STATE, settingsReducer);