import { APP_ACTIONS } from '../actions/app-actions';
import { createReducer } from '../../utils/reducer-helper';

const INITIAL_STATE   = {
  
  isInitializing      : false,
  isInitialized       : false,
  initializeError     : null,
  serverConfig        : null,

  isAuthenticating    : false,
  isAuthenticated     : false,
  authenticationError : null,
  authStatus          : null,
  tokenError          : null,

  profile             : null,
  username            : null,
  currentUser         : null,
  
  isLoadingSettings   : false,
  settings            : null,
  environment         : null,

  isWorking           : false,
  error               : null,

  lastHeartbeat       : null,  
};

function initializing(state, action){
  return {
    ...state,
    isInitialized     : false,
    isInitializing    : true,
    initializeError   : null,
  };
}

function initialized(state, action){
  return {
    ...state,
    isInitializing      : false,
    isInitialized       : true,
    initializeError     : null,
    serverConfig        : action ? action.serverConfig : null,
  };
}

function initializeFailed(state, action){
  return {
    ...state,
    isInitialized     : false,
    isInitializing    : false,
    initializeError   : action.response.error,
  };
}

function authChanging(state, action){
  return {
    ...state,
    isAuthenticating    : true,
    authenticationError : null, 
    authStatus          : action.status,
  };
}


function loggedIn(state, action){
  const response    = action.response;

  return {
    ...state,
    isAuthenticating    : false,
    authenticationError : null,    
    ...response,
  };
}

function loginFailed(state, action){
  return {
    ...state,
    isAuthenticating    : false,
    authenticationError : action.response.error,    
  };
}

function tokenFailed(state, action){
  return  {
    ...state,
    tokenError    : action.response.error,
  };
}

function loggedOut(state, action){
  return {
    ...state,
    isAuthenticating      : false,
    authenticationError   : null,
    isAuthenticated       : false,
    profile               : null,
    username              : null,
    currentUser           : null,
    token                 : null,
    //TODO: Assignments,
    //TODO: CookieBlocked
  };
}

function settingsLoading(state, action){
  return {
    ...state,
    isLoadingSettings   : true,
    settings            : null,
  };
}

function settingsLoaded(state, action){
  const settings = action.response.settings;
  const env = action.response.environment;

  return {
    ...state,
    isLoadingSettings : false,
    error             : null,
    settings          : settings,
    environment       : env,
    currentUser       : settings.user,
    //TODO: Assignments,
    //TODO: CookieBlocked
  };
}

function working(state, action){
  return {
    ...state,
    isWorking       : true,
    error           : null,
    lastHeartbeat   : null,
  };
}
function error(state, action){
  return {
    ...state,
    isWorking       : false,
    error           : action.response,
    lastHeartbeat   : null,
  };
}

function heartbeat(state, action){
  return {
    ...state,
    isWorking       : false,
    error           : null,
    lastHeartbeat   : action.response,
  };
}


//----------------------
// The action map that is turned into the reducer
const appReducer    = {

  [APP_ACTIONS.INITIALIZING]      : initializing,
  [APP_ACTIONS.INITIALIZED]       : initialized,
  [APP_ACTIONS.INITIALIZE_FAILED] : initializeFailed,
  
  [APP_ACTIONS.LOGGING_IN]        : authChanging,
  [APP_ACTIONS.LOGGED_IN]         : loggedIn,
  [APP_ACTIONS.LOGIN_ERROR]       : loginFailed,
  [APP_ACTIONS.TOKEN_FAILED]      : tokenFailed,

  [APP_ACTIONS.LOGGING_OUT]       : authChanging,
  [APP_ACTIONS.LOGGED_OUT]        : loggedOut,

  [APP_ACTIONS.SETTINGS_LOADING]  : settingsLoading,
  [APP_ACTIONS.SETTINGS_LOADED]   : settingsLoaded,
  // [APP_ACTIONS.ENVIRONMENT_LOADED]: environmentLoaded,

  [APP_ACTIONS.WORKING]           : working,
  [APP_ACTIONS.ERROR]             : error,

  [APP_ACTIONS.HEARTBEAT]         : heartbeat,
};

//The reducer
export default createReducer(INITIAL_STATE, appReducer);

//-----
// Used for unit tests, will create the reducer with preloaded state
export const createReducerForTest = (preloadedState) => {
  const initState = {
    ...INITIAL_STATE,
    ...preloadedState,
  };

  return createReducer(initState, appReducer);
};