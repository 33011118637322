import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { shallowEqual } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useInputHandler } from 'hooks/general-hooks';
import { adminDialogClasses } from 'features/common/dialog-classes';
import { WaitButton, StatusCard, Loading } from 'features/common';
import { flatten, unFlatten, swapNulls, optionTypeNames, formatOptionChoices } from 'utils/general-helpers';
import Validation from 'utils/validation-helper';
import { defaultInputs } from 'utils/checklist-helpers';
import { IconButton } from '@mui/material';
import { useBoolState } from '../../../../hooks/general-hooks';
import { removeItem, replaceAt } from '../../../../store/store-helpers';
import { isSameWithoutWhitespace } from '../../../../utils/general-helpers';

const choicesToList = (values) => {
  return _.filter(values.choices.split(",").map(c => c.trim()), v => v.length > 0);
}
const listToChoices = (list) => {
  return list.join(", ");
}

const AddOptionsDialog = ({isOpen, input, onClose, onSave, onDelete, isEditable}) => {
  const { classes } = buildStyles();
  const isAdd   = !input;
  const [original, setOriginal]   = useState(null);
  const [values, onChange, setValues, onSelectChange]  = useInputHandler({}, null, onValidate);
  const [validation, setValidation]	= useState({canSave: false});
  const [isList, toggleList] = useBoolState(false);
  const [itemList, setItemList] = useState(null);
  const [newItem, setNewItem] = useState("");
  const [itemError, setItemError] = useState(null);
  const [isReady, setReady] = useState(false);

  //Need to respond to changes to the input
  useEffect(() => {
    const item  = !!input ? input : {...defaultInputs.option};
    let prepared  = swapNulls(flatten(item, ["caption", "optionType"], ["choices"]));
    prepared.choices  = formatOptionChoices(prepared.choices, 999);  //need to de-json-ize the choices
    setOriginal(prepared);
    setValues(prepared);
    setReady(true);
  }, [input, setValues]);

  //Handle the toggle between choices and list (single field, draggable list)
  useEffect(() => {
    if(isList){
      //switch to list
      const items = choicesToList(values);
      setItemList(items);
    }
    else if (itemList !== null){
      //switch from list
      const str = listToChoices(itemList);
      setValues({...values, choices: str});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isList]);

  //If the itemList changes, need to update the values collection
  useEffect(() => {
    if(itemList !== null){
      const updated = listToChoices(itemList);
      if(!isSameWithoutWhitespace(updated, values.choices || "")){
        const newValues = {...values, choices: updated};
        setValues(newValues);
        onValidate(newValues)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemList]);

  if(!isOpen) return null;
  
  //===called by the input handler to validate the values
  function onValidate(toValidate){
    //Validate that there is a name
    if(toValidate.choices?.length > 3){
      const isChanged   = Validation.hasChanges(original, toValidate);
      setValidation({...validation, canSave: isChanged});
    }
    else{
      setValidation({...validation, canSave: false});
    }
  }

  //===Saves the changes to this input when user clicks the save button
  async function onSaveChanges(){

    if(!shallowEqual(original, values)){
      let changes   = unFlatten(values, original, ["caption", "optionType"], ["choices"]);
      if(changes.propertyBag){
        changes.propertyBag.choices = JSON.stringify(changes.propertyBag.choices.split(","));  //need to turn the choices into JSON
      }

      const updated   = {...(input || defaultInputs.option), ...changes};
      onSave(updated);
    }
    onClose();
  }

  const onNewItemChange = (e) => {
    // const previous = itemList[index];
    const next = e.currentTarget.value;
    setNewItem(next);
  }

  const onAdornMouseDown = (e) => {
    e.preventDefault();
  };

  const onKeyDown = (e) => {
    if(e.keyCode === 13) onAddNewItem();
    else if(e.keyCode === 27) setNewItem("");
}

  const onAddNewItem = () => {
    if(newItem.trim().length > 0){
      const itm = newItem.trim();
      const existing = itemList.find(i => i.toLowerCase() === itm.toLowerCase());
      if(existing){
        setItemError("Cannot add a duplicate item");
        return;
      }
      else{
        setItemError(null);
      }
      const newList = [...itemList, itm];
      setItemList(newList);
      setNewItem("");      
    }
  }

  const onDeleteItem = (index) => () => {
    const newList = removeItem(itemList, itemList[index]);
    setItemList(newList);
  }

  const onReorder = (result) => {
    const fromIndex = result.source.index;
    const toIndex = result.destination.index;
    if(fromIndex !== toIndex){
      const from = itemList[fromIndex];
      const to = itemList[toIndex];
      let newList = replaceAt(itemList, fromIndex, to);
      newList = replaceAt(newList, toIndex, from);
      setItemList(newList);
    }    
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className={classes.dialog} maxWidth="sm">
      
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between">
          <Typography className={classes.titleText}>
            <Tooltip title="Text Input">
              <MenuIcon fontSize="small" className={classes.titleIcon}/>
            </Tooltip>
            {isAdd ? "New " : ""}Options Input
          </Typography>
          <CloseIcon fontSize="small" onClick={onClose} className={classes.closeIcon}/>
        </Grid>
      </DialogTitle>

      <DialogContent>
        {!isReady && <Loading message="initializing" /> }
        {isReady && (
          <Grid container>
            <Grid item container className={classes.formRow}>
              <TextField name="caption" value={values.caption} onChange={onChange} disabled={!isEditable} fullWidth label="Caption" className={classes.field}
                error={validation?.caption?.hasError} helperText={validation?.caption?.message} autoComplete="off"/>
            </Grid>

            <Grid item container className={classes.formRow}>
              <FormControl className={classes.formControl} label="Type" fullWidth disabled={!isEditable}>
                <InputLabel id="type-label" className={classes.selectLabel}>Type</InputLabel>
                <Select id="optionType" labelId="type-label" label="Type" value={values.optionType} onChange={onSelectChange("optionType")} fullWidth className={classes.selectContainer} classes={{select: classes.select}} size="small">
                  <MenuItem value="2" className={classes.option}>{optionTypeNames(2)}</MenuItem>
                  <MenuItem value="0" className={classes.option}>{optionTypeNames(0)}</MenuItem>
                  <MenuItem value="1" className={classes.option}>{optionTypeNames(1)}</MenuItem>            
                </Select>
              </FormControl>
            </Grid>

            {!isList && 
            <Grid item container className={classes.formRow}>
              <Grid item xs={11}>
                <TextField name="choices" value={values.choices} onChange={onChange} disabled={!isEditable} fullWidth label="Choices" placeholder="enter a comma-delimited list of choices" className={classes.field} 
                  {...validation?.choices?.fieldProps} autoComplete="off"/>
                </Grid>
                <Grid item xs={1} container alignItems="flex-end" justifyContent="flex-end">
                  <IconButton size="small" onClick={toggleList}><ListAltIcon fontSize="small" /></IconButton>
                </Grid>
            </Grid>
            }

            {isList && itemList && 
              <Grid container className={classes.formRow}>
                <Grid item xs={11} className={classes.tableContainer}>
                  <Grid container className={classes.formRow}>
                    <Grid xs={9}>
                      <FormControl error={!!itemError} title={itemError || "Enter item to add to the list and press 'Enter'"}>
                        <Input value={newItem} onChange={onNewItemChange} onKeyDown={onKeyDown} error={!!itemError} placeholder="Enter item to add" fullWidth className={classes.field} 
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle add"
                                onClick={onAddNewItem}
                                onMouseDown={onAdornMouseDown}
                                size="large">
                                <AddIcon fontSize="small"/>
                              </IconButton>
                            </InputAdornment>
                          }
                        />                  
                      </FormControl>
                    </Grid>
                    <Grid xs={3} container justifyContent="flex-end">
                      {itemList.length > 0 && <Typography className={classes.itemCount}>{itemList.length} items</Typography>}
                    </Grid>
                  </Grid>
                  {itemList.length > 0 && 
                    <DragDropContext onDragEnd={onReorder}>
                      <Droppable droppableId="options">
                        {(provided) => (
                          <ul {...provided.droppableProps} ref={provided.innerRef} className={classes.optionList}>
                            {itemList.map((item, index) => {
                              return (
                                <Draggable key={item} draggableId={item} index={index}>
                                  {(drap) => (
                                    <li ref={drap.innerRef} {...drap.draggableProps} {...drap.dragHandleProps}>
                                      <Grid container alignItems="center">
                                        <Grid item xs={10}>{item}</Grid>
                                        <Grid item xs={2} container justifyContent="flex-end">
                                          <IconButton size="small" onClick={onDeleteItem(index)}><DeleteIcon fontSize="small" /></IconButton>
                                        </Grid>
                                      </Grid>
                                    </li>
                                )}
                                </Draggable>
                              );

                            })}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  }
                  {itemList.length === 0 &&
                    <Grid container alignItems="flex-start" justifyContent="center" className={classes.optionList}>
                      <StatusCard status="info" message="The list is empty" isVisible={true} />
                    </Grid>
                  }
                </Grid>
                <Grid item xs={1}>
                  <IconButton size="small" onClick={toggleList}><TextFieldsIcon fontSize="small" /></IconButton>
                </Grid>
              </Grid>
            }

          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button size="small" onClick={onClose}>Cancel</Button>
        {(isEditable && !isAdd) && <Button size="small" onClick={onDelete}>Delete</Button>}
        {isEditable && <WaitButton disabled={!validation.canSave} onClick={onSaveChanges} color="secondary" size="small">Save</WaitButton>}
      </DialogActions>

    </Dialog>
  );
}

export default AddOptionsDialog;

const buildStyles   = makeStyles()(theme => ({
  ...adminDialogClasses(theme),
  root  : {
    padding     : theme.spacing(1),
  },  
  itemCount: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  optionList: {
    listStyle: "none",
    height: 250,
    "& li": {
      border: `1px solid ${theme.palette.grey[300]}`, //`
      borderRadius: 4,
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`, //`,
      marginBottom: theme.spacing(0.5),
    }
  }
}));