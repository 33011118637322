import _ from 'lodash';
import Apis, { paginationHeader, downloadFile } from '../quanta-api';
import { getNowString } from 'utils/general-helpers';
import { getSearchProps } from 'utils/action-helpers';
import { getActionWrapper } from 'store/store-helpers';
import { ADMIN_REPORT_ACTIONS } from './admin-report-actions';

const NO_OP   = "NO_OP";
export const ADMIN_ACTIONS  = {
  STATUS_CHANGE     : "ADMIN_STATUS_CHANGE",
  USERS_LOADED      : "ADMIN_USERS_LOADED",
  USERS_EXPORTED    : "ADMIN_USERS_EXPORTED",
  USER_SAVED        : "ADMIN_USER_SAVED",
  USER_ASSIGNMENTS_LOADED   : "USER_ASSIGNMENTS_LOADED",
  ENGAGEMENTS_LOADED: "ADMIN_ENGAGEMENTS_LOADED",
  ENGAGEMENT_SAVED  : "ADMIN_ENGAGEMENT_SAVED",
  SETTING_SAVED     : "ADMIN_SETTING_SAVED",
  ASSIGNMENTS_LOADED: "ASSIGNMENTS_LOADED",
  ASSIGNMENT_SAVED  : "ASSIGNMENT_SAVED",
  ASIGNMENT_DELTED  : "ASIGNMENT_DELTED",
  SET_DELETED       : "SET_DELETED",
  SETS_UNAPPROVED   : "SETS_UNAPPROVED",
  DOCTYPES_LOADED   : "DOCTYPES_LOADED",
  DOCTYPE_LOADED    : "DOCTYPE_LOADED",
  DOCTYPE_VERSIONED : "DOCTYPE_VERSIONED",
  DOCTYPE_COPIED    : "DOCTYPE_COPIED",
  DOCTYPE_CIRCULATION_TOGGLED: "DOCTYPE_CIRCULATION_TOGGLED",
  CHECKLISTS_LOADED : "CHECKLISTS_LOADED",
  CHECKLIST_LOADED  : "CHECKLIST_LOADED",
  ALL_ENGAGEMENTS_LOADED : "ALL_ENGAGEMENTS_LOADED",
  REPORTS_QUEUED   : "REPORTS_QUEUED",
  ...ADMIN_REPORT_ACTIONS
};

//Wraps an action call in status updates
const actionStatusWrapper   = getActionWrapper(ADMIN_ACTIONS.STATUS_CHANGE, NO_OP);

export const clearStatus = (statusKey) => async(dispatch, getState) => {
  return dispatch({type: ADMIN_ACTIONS.STATUS_CHANGE, key: statusKey, value: {isWorking: false, error: null}});
}

//#region Users 

export const loadUsers = (filter, pageNumber = 1, pageSize = 25) => async(dispatch, getState) => {

  const myFilter = getSearchProps(filter);

  return actionStatusWrapper(dispatch)(async() => {
    const headers   = paginationHeader(pageNumber, pageSize);
    const response  = await Apis.Users.get("", myFilter, headers);
    return response;
  }, "users", ADMIN_ACTIONS.USERS_LOADED, {myFilter});
}

export const exportUsers = (filter) => async(dispatch, getState) => {
  const myFilter = getSearchProps(filter);

  dispatch({type: ADMIN_ACTIONS.STATUS_CHANGE, key: "users", value: {isWorking: true, error: null}});
  
  const headers = paginationHeader(1, 10000);
  const result = await Apis.Users.get("/export", {...myFilter, responseType: "arrayBuffer"}, headers);
  if(result.ok){
    const isOk  = await downloadFile(result, "text/csv");
    if(!isOk){
      console.log("Export failed.");
    }
    // dispatch({type: ADMIN_ACTIONS.NO_OP, data: result});
    dispatch({type: ADMIN_ACTIONS.STATUS_CHANGE, key: "users", value: {isWorking: false, error: null}});
  }
  else{
    dispatch({type: ADMIN_ACTIONS.STATUS_CHANGE, key: "users", value: {isWorking: false, error: result}});
  }

}

export const loadUserAssignments = (userId) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    const filter    = {filter: `user:${userId}`};
    const response  = await Apis.Assignments.get("", filter);
    return response;
  }, "users", ADMIN_ACTIONS.USER_ASSIGNMENTS_LOADED, {userId: userId}); 
}

export const saveUser = (id, model) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    let response  = null;
    if(id === -1){
      response  = await Apis.Users.post("", model);
    }
    else{
      response  = await Apis.Users.put(`/${id}`, model);
    }
    return response;
  }, "users", ADMIN_ACTIONS.USER_SAVED, {userId: id});
}

//#endregion

//#region Engagements
//-- Loads all the engagements, since a few different spots need all engagements, not a filtered list or
// the assigned engagements.
export const loadAllEngagements = () => async(dispatch, getState) => {
  const current   = getState().admin.allEngagements;
  if(!current){
    return actionStatusWrapper(dispatch)(async() => {
      const headers   = paginationHeader(1, 9999);
      return await Apis.Engagements.get("", {filter: "all"}, headers);
    }, "engagements", ADMIN_ACTIONS.ALL_ENGAGEMENTS_LOADED);
  }
}

export const loadEngagements = (filter, pageNumber = 1, pageSize = 25) => async(dispatch, getState) => {
  const myFilter = getSearchProps(filter);

  return actionStatusWrapper(dispatch)(async() => {
    const headers   = paginationHeader(pageNumber, pageSize);
    return await Apis.Engagements.get("", myFilter, headers);
  }, "engagements", ADMIN_ACTIONS.ENGAGEMENTS_LOADED);
}

export const loadEngagementAssignments = (engagementId) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    const filter    = {filter: `eng:${engagementId}`};
    const response  = await Apis.Assignments.get("", filter);
    return response;
  }, "assignments", ADMIN_ACTIONS.ASSIGNMENTS_LOADED, {engagementId: engagementId});  
}

export const saveAssignment = (id, model) => async(dispatch, getState) => {
  const isMe    = (model.assignedUserId === getState().app.currentUser.id);
  return actionStatusWrapper(dispatch)(async() => {
    let response  = null;
    if(id === -1){
      response  = await Apis.Assignments.post("", model);
      return response;
    }
    else{
      response  = await Apis.Assignments.put(`/${id}`, model);
      return response; //{...response, data: model};
    }    
  }, "assignments", ADMIN_ACTIONS.ASSIGNMENT_SAVED, {id: id, isMe: isMe, data: model});
}

export const deleteAssignment = (id, engagementId) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    const response  = await Apis.Assignments.delete(`/${id}`);
    return response;
  }, "assignments", ADMIN_ACTIONS.ASIGNMENT_DELTED, {id, engagementId});
}

//#endregion

//#region Document Types / Checklists
export const loadDocTypes = (filter, pageNumber = 1, pageSize = 25) => async(dispatch, getState) => {
  
  return actionStatusWrapper(dispatch)(async() => {
    const headers   = paginationHeader(pageNumber, pageSize);
    const response  = await Apis.DocumentTypes.get("", filter, headers);
    return response;
  }, "doctypes", ADMIN_ACTIONS.DOCTYPES_LOADED);
}

export const loadDocType = (id) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    const response  = await Apis.DocumentTypes.get(`/${id}`);  //`
    return response;
  }, "doctypes", ADMIN_ACTIONS.DOCTYPE_LOADED, {id: id});
}

export const loadChecklists = (filter, pageNumber = 1, pageSize = 25) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    const headers   = paginationHeader(pageNumber, pageSize);
    return await Apis.Checklists.get("", filter, headers);
  }, "checklists", ADMIN_ACTIONS.CHECKLISTS_LOADED);
}

export const addDocTypeVersion = (id, newName) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    const url   = newName ? `/${id}/addversion?newName=${newName}` : `/${id}/addversion`; //`
    const response  = await Apis.DocumentTypes.put(url);
    return response;
  }, "doctypes", ADMIN_ACTIONS.DOCTYPE_VERSIONED, {id: id});
}

export const copyDocType = (id, protocolId) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    const response  = await Apis.DocumentTypes.put(`/${id}/copyto?protocolId=${protocolId}`);  //`
    return response;
  }, "doctypes", ADMIN_ACTIONS.DOCTYPE_COPIED, {id: id});
}

export const toggleDocTypeCirculation = (id, inCirculation) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    const response  = await Apis.DocumentTypes.put(`/${id}/circulation?target=${inCirculation ? "add" : "remove"}`);  //`
    return response;
  }, "doctypes", ADMIN_ACTIONS.DOCTYPE_CIRCULATION_TOGGLED, {id: id});
}

//#endregion

//#region Clients, Projects, Protocols, Sites

export const saveSetting = (id, model, settingType) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    let response  = null;
    const api     = APIS(settingType, Apis);
      
    if(id === -1){
      const whiteList   = WHITELIST[settingType];
      let myModel = _.pick(model, whiteList); //make sure we're not trying to send the id
      response  = await api.post("", myModel);
    }
    else{
      response  = await api.put(`/${id}`, model);  //`
    }
    return response;
  }, settingType, ADMIN_ACTIONS.SETTING_SAVED, {id: id, key: settingType});  
}

//#endregion

//#region Tasks

//Unapprove multiple reviewsets
export const unapproveMultiSets = (engagementId, ids, signature, notes) => async(dispatch, getState) => {
  return actionStatusWrapper(dispatch)(async() => {
    const model   = {
      engagementId    : engagementId,
      reviewSetIds    : ids,
      signature       : signature,
      statusDate      : getNowString(), //"YYYY-MM-DD HH:mm:ss"), // -- parameter is being ignored in getNowString
      notes           : notes
    };

    const url       = "/unapprovemultiple";
    const response  = await Apis.Reviewsets.post(url, model);
    return response;
  }, "unapprove-task", ADMIN_ACTIONS.SETS_UNAPPROVED, {engagementId: engagementId, ids: ids});
  
}

//Delete a ReviewSet, or Validate that the reviewset can be deleted
export const deleteReviewSet = (id, validateOnly) => async(dispatch, getState) => {
  const action  = validateOnly ? NO_OP : ADMIN_ACTIONS.SET_DELETED;

  return actionStatusWrapper(dispatch)(async() => {
    
    const url     = validateOnly ? `/${id}/validate_delete` : `/${id}/delete`; //`
    let response  = null;
    const api     = Apis.Reviewsets;
    response      = await api.post(url);

    return response;
  }, "delete-rs", action, {id: id});
}

export const queueReviewSetReports = (engagementId) => async (dispatch) => {
  return actionStatusWrapper(dispatch)(async() => {
    const url     = `/push-engagement/${engagementId}`;
    const response  = await Apis.ReportQueue.post(url);
    return response;
  }, "queue-reports", ADMIN_ACTIONS.REPORTS_QUEUED, { engagementId: engagementId });
}
//#endregion

export * from "./admin-report-actions";   //backwards compatibility

const WHITELIST   = {
  clients  : ["name", "propertyBag"],
  projects : ["title", "clientId", "notes", "propertyBag"],
  protocols: ["name", "projectId", "propertyBag"],
  sites    : ["name", "clientId", "propertyBag"],
  engagements : ["name", "isArchived", "siteId", "clientId", "projectId", "protocolId", "propertyBag"],
  doctypes : ["name", "protocolId", "notes", "propertyBag"],
};

//This is used by the settings controller to dynamically retrieve the correct
// api based on the settingType.
const APIS  = (settingType, apis) => {
  switch(settingType){
    case "clients"  : return apis.Clients;
    case "projects"  : return apis.Projects;
    case "protocols"  : return apis.Protocols;
    case "sites"  : return apis.Sites;
    case "engagements" : return apis.Engagements;
    case "doctypes" : return apis.DocumentTypes;
    default: return apis.home;
  }
}