import _ from 'lodash';
import { DOC_ACTIONS } from '../actions/document-actions';
import { createReducer } from 'utils/reducer-helper';
import { addOrReplace, removeItem } from '../store-helpers';
import { prepareCheckList, reduceCheckListStatuses, APPROVE_TYPES } from 'utils/checklist-helpers';

const INITIAL_FILTER  = {approve: true, reject: true, na: true, open: true, answered: true, collapsed: {}}

const INITIAL_STATE = {
  itemError           : null,
  isLoading           : false,
  item                : null,
  //breaking these props out of the item to reduce changing the item, which triggers a re-render
  itemStatuses        : [],
  itemCheckLists      : [],

  loadTime            : null,  

  checkListIndex      : null,
  checkListId         : null,
  checkListFilter     : INITIAL_FILTER,

  //items that are currently busy because they are being responded to
  responding          : {},
  approving           : { checkListId: null, isWorking: false, error: null },
};

function itemLoading(state, action){
  return {
    ...state,
    itemError   : null,
    isLoading   : true,
    item        : null,
    itemStatuses  : [],
  };
}

function itemLoaded(state, action){
  //manipulate the document a little so we don't churn as often
  let doc           = {...action.response}; //close the response so we're working with a fresh version
  let primaryRr     = _.find(doc.reviewRecords, rr => rr.primaryReviewRecordId === null);
  const statuses    = reduceCheckListStatuses(primaryRr); //_.filter(primaryRr.checkListStatuses, status => status.status !== "DISAPPROVE");  //not interested in un-approvals
  const checkLists  = _.map(doc.checkLists, cl => prepareCheckList(cl));
  
  primaryRr         = _.omit(primaryRr, ["checkListResponses", "checkListStatuses"]);
  doc.reviewRecords = addOrReplace(doc.reviewRecords, rr => rr.id === primaryRr.id, primaryRr);

  doc               = {
    ..._.omit(doc, ["checkLists"]), 
    primaryReviewRecord: primaryRr 
  };

  return {
    ...state,
    itemError       : null,
    isLoading       : false,
    item            : doc,
    itemStatuses    : statuses,
    itemCheckLists  : checkLists,
    loadTime        : new Date(),
    checkListIndex  : 0,
    checkListId     : checkLists ? checkLists[0].id : null,
  }
}

function itemError(state, action){
  return {
    ...state,
    itemError   : action.response,
    isLoading   : false,
    item        : null,
    itemStatuses  : [],
  };
}

function itemResponding(state, action){
  const itemKey   = action.props.checkListItemId;
  const itemProps = {
    isWorking     : true,
    isError       : false,
    error         : null,
  };

  const result  = {
    ...state,
    responding      : {...state.responding, [itemKey] : itemProps },
  };

  return result;
}

function itemResponded(state, action){
  const itemKey   = action.props.checkListItemId;
  
  return {
    ...state,
    responding      : _.omit(state.responding, [itemKey]),    //remove the pending item
  };
}

function itemResponseError(state, action){
  const itemKey   = action.props.checkListItemId;
  const itemProps = {
    isWorking     : false,
    isError       : true,
    error         : action.response,
  };

  return {
    ...state,
    responding      : {...state.responding, [itemKey] : itemProps },
  };
}

function filterCheckList(state, action){
  return {
    ...state,
    checkListFilter   : { ...state.checkListFilter, ...action.props},
  };
}

function checkListSelected(state, action){
  return {
    ...state,
    checkListIndex    : action.index,
    checkListId       : state.itemCheckLists ? state.itemCheckLists[action.index].id : null,
    checkListFilter   : INITIAL_FILTER,
  };
}

function checkListApproving(state, action){
  return {
    ...state,
    approving     : { checkListId: action.props.checkListId, isWorking: true, error: null },
  };

}
function checkListApproved(state, action){
  const { reviewRecordId, checkListId, status }   = action.props;
  const isUnapprove = Boolean(status === APPROVE_TYPES.unApprove);
  let statusRecords = state.itemStatuses;

  if(isUnapprove){
    //NOTE: at this time, we're not holding the disapprove status records in the state.
    //Remove any existing approval / preapproval records
    const toRemove    = _.filter(statusRecords, s => s.reviewRecordId === reviewRecordId && s.checkListId === checkListId);
    _.forEach(toRemove, i => {
      statusRecords   = removeItem(statusRecords, i);
    });
    // statusRecords     = removeItem(statusRecords, toRemove);
  }
  else{
    let statusRec     = action.response;
    statusRec.statusDate  = statusRec.statusDate.replace("Z", "");  //remove the timezone, we're using UTC.
    statusRecords     = addOrReplace(statusRecords, s => s.id === statusRec.id, statusRec);
  }

  return {
    ...state,
    approving     : { checkListId: null, isWorking: false, error: null },
    itemStatuses  : statusRecords,
  };
}

function checkListApproveFailed(state, action){
  return {
    ...state,
    approving     : { checkListId: action.props.checkListId, isWorking: false, error: action.response },
  };
}

function clearApproving(state, action){
  return {
    ...state,
    approving: {...INITIAL_STATE.approving},
  }
}

//----------------------
// The action map that is turned into the reducer
const docReducer    = {

  [DOC_ACTIONS.ITEM_LOADING]     : itemLoading,
  [DOC_ACTIONS.ITEM_LOADED]      : itemLoaded,
  [DOC_ACTIONS.ITEM_ERROR]       : itemError,

  [DOC_ACTIONS.INPUT_RESPONDING]  : itemResponding,
  [DOC_ACTIONS.INPUT_RESPONDED]   : itemResponded,
  [DOC_ACTIONS.INPUT_ERROR]       : itemResponseError,

  [DOC_ACTIONS.CHECKLIST_SELECTED]: checkListSelected,
  [DOC_ACTIONS.FILTER_CHECKLIST]  : filterCheckList,

  [DOC_ACTIONS.CHECKLIST_APPROVING]   : checkListApproving,
  [DOC_ACTIONS.CHECKLIST_APPROVED]    : checkListApproved,
  [DOC_ACTIONS.CHECKLIST_APPROVE_FAILED]  : checkListApproveFailed,
  [DOC_ACTIONS.CHECKLIST_APPROVE_CLEAR] : clearApproving,
};

//The reducer
export default createReducer(INITIAL_STATE, docReducer);