import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import WarningIcon from '@mui/icons-material/Warning';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentsIcon from '@mui/icons-material/AssignmentInd';
import DocTypeIcon from '@mui/icons-material/InsertDriveFile';
import { WaitButton, ErrorDisplay } from 'features/common';
import { saveSetting } from 'store/actions/admin-actions';
import { selectStatusByKey } from 'store/selectors/admin-selectors';
import { tryParseInt, shallowEquals, unFlatten } from 'utils/general-helpers';
import ClientDialog from '../dialogs/client-dialog';
import ProjectDialog from '../dialogs/project-dialog';
import ProtocolDialog from '../dialogs/protocol-dialog';
import SiteDialog from '../dialogs/site-dialog';
import EngagementAssignments from './engagement-assignments';
import EngagementDocTypes from './engagement-doctypes';
import { toastr } from 'react-redux-toastr';
import { useAllEngagements } from 'hooks/admin-hooks';
import { useNavigate } from 'react-router-dom';
import { downloadReport } from 'store/actions/report-actions';

const empty_item  = {id: -1, name: "", clientId: -1, protocolId: -1, projectId: -1, siteId: -1, isTraining: false, isArchived: false};
const modelFields     = ["id", "name", "clientId", "protocolId", "projectId", "siteId", "isArchived"];
const propBagFields   = ["isTraining"];

const EngagementEditor = () => {
  const { classes }           = buildStyles();
  const navigate = useNavigate();
  const { engagementId }  = useParams();
  const existing          = useAllEngagements();
  const settings          = useSelector(state => state.settings);
  const status            = useSelector(state => selectStatusByKey(state, "engagements"));
  const dispatch          = useDispatch();
  const isAdd             = engagementId === "add";
  const [original, setOriginal] = useState(empty_item);
  const [updates, setUpdates]   = useState(empty_item);

  const [dialog, setDialog]	= useState({});
  const [tab, setTab]       = useState(0);
  
  const clients   = useMemo(() => prepareArray(settings.clients, "nosort", "nosort"), [settings.clients]);
  const projects  = useMemo(() => prepareArray(settings.projects, "clientId", updates.clientId, "title"), [updates.clientId, settings]);
  const sites     = useMemo(() => prepareArray(settings.sites, "clientId", updates.clientId), [updates.clientId, settings]);
  const protocols = useMemo(() => prepareArray(settings.protocols, "projectId", updates.projectId), [updates.projectId, settings]);
  
  const [dupe, setDupe]         = useState(null);
  const [canSave, setCanSave]   = useState(false);
  const chips                   = _.compact([updates.isTraining ? "training" : null, updates.isArchived ? "archived": null]);
  const engIcon                 = updates.isArchived ? "archive" : (updates.isTraining ? "school" : "folder_open");
  const iconTT                  = updates.isArchived ? "Archived Engagement" : (updates.isTraining ? "Training Engagement" : "Engagement");
  const [isInValid, setInValid] = React.useState(false);  //tracks if the id doesn't yield an actual engagement
  //---
  //Load the engagement we're working with
  useEffect(() => {
    if(existing && engagementId !== "add"){
      const engId   = tryParseInt(engagementId, -1);
      if(engId > 0){
        const eng   = _.find(existing, e => e.id === engId);
        if(eng){
          setOriginal(eng.model);
          setUpdates(eng.model);
          setInValid(false);
        }
        else{
          setInValid(true);
        }
      }
    }
  }, [existing, engagementId])


  //---
  //Warning adornment when the engagement would be a dupe
  const adornment = useMemo(() => {
    if(dupe){
      return <InputAdornment position="end">
        <Tooltip title={`This engagement is identical to engagement ${dupe}`}>
          <WarningIcon color="error" fontSize="small"/>
        </Tooltip>
      </InputAdornment>
    }
    else return null;
  }, [dupe]);

  //---
  // Monitor for changes and track whether it's a dupe, and if it can be saved
  useEffect(() => {
    if(!existing) return;

    const { clientId, protocolId, projectId, siteId}  = updates;
    if(clientId >= 0 && protocolId >= 0 && projectId >= 0 && siteId >= 0){
      const myDupe  = _.find(existing, eng => eng.siteId === siteId && eng.protocolId === protocolId);
      setDupe(myDupe && myDupe.id !== updates.id ? myDupe.id : null);
      setCanSave(!myDupe && isReady(original, updates));
    }
    else{
      setCanSave(false);
      setDupe(null);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updates.clientId, updates.protocolId, updates.projectId, updates.siteId]);

  //---
  // Monitor for changes to the name, and enable/disable the save button appropriately
  //TODO: Switch this to a memo...
  // const canSave = useMemo(() => {return (!dupe && isReady(original, updates)); }, [updates.name, updates.isTraining, updates.isArchived]);
  useEffect(() => {
    setCanSave(!dupe && isReady(original, updates));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updates.name, updates.isTraining, updates.isArchived]);

  //---
  // Handles changes to the inputs
  const onChanged  = (e, eleId, eleVal) => {
    const key     = eleId || e.currentTarget.id;
    const value   = (eleVal === undefined) ? (e.currentTarget?.value ?? e.target?.value) : eleVal;
    let other     = {};
    if(key === "clientId"){
      other  = { projectId: -1, protocolId: -1, siteId : -1 };
    }
    else if(key === "projectId"){
      other   = { protocolId : -1 };
    }

    setUpdates({
      ...updates,
      [key]     : value,
      ...other
    });
  }

  const onOpen = async (itemType, itemValue = -1) => {
    setDialog({[itemType]: itemValue});
  }
  
  const onCloseDialog = (key) => (value) => {
    setDialog({});
    if(key && value){
      if(["clientId", "projectId", "protocolId", "siteId"].indexOf(key) >= 0){
        setUpdates({...updates, [key]: value});
      }
    }
  }

  async function onSave(){
    if(canSave && isReady(original, updates)){
      const model   = unFlatten(updates, original, modelFields, propBagFields);
      const result  = await dispatch(saveSetting(original.id, model, "engagements"));
      if(result?.ok){
        if(isAdd && result.response){
          //redirect to the edit page for this engagement since we're not adding any more
          navigate(`/admin/engagements/${result.response.id}`, { replace: true });
        }
        else if(!isAdd){
          //Update the state so we are working with the current item
          // const engId = tryParseInt(engagementId, -1);
          // const eng   = _.find(existing, e => e.id === engId);
          // setOriginal(eng.model);
          // setUpdates(eng.model);
        }
        
        toastr.success("Engagement Saved Successfully");
      }
    }
  }

  async function onDownloadAssignmentReport(){
    const reportUrl = `admin/assignmentsbyengagement?engagementId=${engagementId}`;
    await dispatch(downloadReport({url: reportUrl}));
  }

  if(isInValid){
    return (
      <Grid container jusitfy="center">
        <Grid item xs={4}/>
        <Grid item xs={4}>
          <ErrorDisplay error="404: Engagement not found" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid id="engagement-editor" container sx={{p: 1}}>
      <Grid item xs={7} container alignItems="center" sx={{mb: 2}}>
        <Tooltip title={iconTT}>
          <Icon className={classes.titleIcon}>{engIcon}</Icon>
        </Tooltip>
        <Typography variant="h5" color="primary">
          {isAdd ? "New Engagement" : "Engagement " + updates?.id}
        </Typography>
        {
          _.map(chips, c => <Chip key={c} className={classes.titleChip} label={c}/>)
        }
      </Grid>

      <Grid item xs={5} container alignItems="center" justifyContent="flex-end">
        <WaitButton id="save" variant="outlined" color="secondary" isWaiting={status.isWorking} disabled={!canSave} sx={{ml: 1}} onClick={onSave}>Save</WaitButton>
        <Button id="cancel" variant="outlined" disabled={status.isWorking} sx={{ml: 1}} onClick={() => navigate("/admin/engagements")}>Cancel</Button>
      </Grid>

      <Grid item xs={8} container direction="column">
        <TextField id="name" value={updates.name} onChange={onChanged} disabled={status.isWorking} 
            fullWidth sx={{mb: 2}} size="small" label="Name"
            error={!!dupe} 
            InputProps={{endAdornment: adornment}}
          />
        <SettingCombo label="Client" settingId="clientId" options={clients} value={updates.clientId} isDisabled={!isAdd || status.isWorking} onChanged={onChanged} onOpen={onOpen} classes={classes} adornIcon="business"/>
        <SettingCombo label="Project" nameKey="title" settingId="projectId" options={projects} value={updates.projectId} isDisabled={!isAdd || status.isWorking || updates.clientId === -1} onChanged={onChanged} onOpen={onOpen} classes={classes} adornIcon="ballot" />
        <SettingCombo label="Protocol" settingId="protocolId" options={protocols} value={updates.protocolId} isDisabled={!isAdd || status.isWorking || updates.projectId === -1} onChanged={onChanged} onOpen={onOpen} classes={classes} adornIcon="tune" />
        <SettingCombo label="Site" settingId="siteId" options={sites} value={updates.siteId} isDisabled={!isAdd || status.isWorking || updates.clientId === -1} onChanged={onChanged} onOpen={onOpen} classes={classes} adornIcon="pin_drop" />
      </Grid>
      
      <Grid item xs={4} container direction="column" sx={{mt: 2, pr: 1}} alignItems="flex-end">
        <SettingSwitch label="Training" settingId="isTraining" value={updates.isTraining} isDisabled={!isAdd || status.isWorking} onChanged={onChanged} classes={classes}/>
        <SettingSwitch label="Archived" settingId="isArchived" value={updates.isArchived} isDisabled={status.isWorking} onChanged={onChanged} classes={classes}/>
        <Button sx={{mt: 6}} onClick={onDownloadAssignmentReport} variant="outlined">Assignments Report</Button>
      </Grid>
      
      {engagementId !== "add" && 
        <Grid item xs={12} container alignItems="center"  sx={{mt: 2}}>
          
          <Grid item xs={12}>
            <Tabs value={tab} onChange={(e,v) => setTab(v)} aria-label="Engagement Tabs">
              <Tab label="Assignments" value={0} icon={<AssignmentsIcon fontSize="small"/>}/>
              <Tab label="Document Types" value={1} icon={<DocTypeIcon fontSize="small"/>} />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <div role="tabpanel" hidden={tab !== 0}>
              {tab === 0 && <EngagementAssignments />}
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
              {tab === 1 && <EngagementDocTypes />}
            </div>
          </Grid>
          
        </Grid>
      }
      {dialog.clientId && <ClientDialog clientId={dialog.clientId} onClose={onCloseDialog("clientId")} props={updates}/>}
      {dialog.projectId && <ProjectDialog projectId={dialog.projectId} onClose={onCloseDialog("projectId")} props={updates} />}
      {dialog.protocolId && <ProtocolDialog protocolId={dialog.protocolId} onClose={onCloseDialog("protocolId")} props={updates} />}
      {dialog.siteId && <SiteDialog siteId={dialog.siteId} onClose={onCloseDialog("siteId")} props={updates} />}
    </Grid>
  );
}

export default EngagementEditor;

const buildStyles   = makeStyles()(theme => ({
  titleIcon   : {
    marginRight   : theme.spacing(1),
    color         : theme.palette.primary.main,
  },
  titleChip   : {
    height        : 28,
    marginLeft    : theme.spacing(1),
  },
  comboGrid   : {
    flexWrap      : "nowrap",
    marginBottom  : theme.spacing(1),    
  },
  formControl   : {
    marginBottom  : theme.spacing(2),
    width         : "100%",
  },
  select        : {
    paddingTop    : 10.5,
    paddingBottom : 10.5,
  },
  selectLabel   : {
    color           : theme.palette.grey[400],
    "&.MuiInputLabel-shrink"  : {
      background    : theme.palette.background.paper,
      color           : theme.palette.grey[600],
      padding       : `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(0)} ${theme.spacing(0)} `, //`
      marginTop       : theme.spacing(0),
    }
  },
  comboLabel: {
    color           : theme.palette.grey[600],
    fontWeight: 300,
    "& span": {
      marginBottom: theme.spacing(-0.5),
    }
  },
  switchGrid      : {
    marginLeft    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  switchLabel   : {
    color           : theme.palette.grey[600],
    fontSize        : 16,
  }
}));

function SettingCombo({label, settingId, options, value, isDisabled, classes, onChanged, onOpen, nameKey, adornIcon}){

  const myName  = nameKey || "name";
  const labelId = `${settingId}-label`;
  const myVal   = value === -1 ? "" : value;

  const adorn   = adornIcon ? <Tooltip title={label}><Icon fontSize="small">{adornIcon}</Icon></Tooltip> : null;
  const newTt   = `New ${label}...`;

  return (
    <Grid container className={classes.comboGrid} alignItems="flex-end">
      <Grid item container>
        <Grid item md={3} sm={4} container alignItems="flex-end"><Typography className={classes.comboLabel}>{adorn}&nbsp;{label}</Typography></Grid>
        <Grid item md={9} sm={8} container>
          <Select id={settingId} labelId={labelId} label={label} value={myVal} onChange={e => onChanged(e, settingId)} disabled={isDisabled} fullWidth classes={{select: classes.select}}  size="small">
            <MenuItem value="" disabled><em>Select {label}</em></MenuItem>
            {_.map(options, option => <MenuItem key={option.id} value={option.id}>{option[myName]}</MenuItem>)}              
          </Select>
        </Grid>
      </Grid>
      <IconButton sx={{ml: 1}} onClick={() => onOpen(settingId, -1)} color="primary" size="small" disabled={isDisabled}>
        <Tooltip title={newTt}>
          <span>
            <AddIcon fontSize="small"/>
          </span>
        </Tooltip>
      </IconButton>
      <IconButton sx={{ml: 1}} onClick={() => onOpen(settingId, value)} color="primary" size="small" disabled={value === -1}>
        <Tooltip title="View / Edit...">
          <span>
            <VisibilityIcon fontSize="small"/>
          </span>
        </Tooltip>
      </IconButton>
    </Grid>
  )
}

function SettingSwitch({label, settingId, value, isDisabled, classes, onChanged}){
  function myChange(e, value){
    onChanged(e, settingId, value);
  }

  return(
    <Grid container className={classes.switchGrid} alignItems="center">
      <Grid item xs={8} container justifyContent="flex-end">
        <Typography className={classes.switchLabel}>{label}</Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end">
        <Switch id={settingId} checked={value} disabled={isDisabled} onChange={myChange}/>
      </Grid>
    </Grid>
  );
}

//===
// Quick method to filter an array
function prepareArray(items, key, value, nameKey = "name"){
  if(value === null || value === -1) return [];

  const filtered  = key === "nosort" ? items : _.filter(items, item => item[key] === value);
  const ordered   = _.orderBy(filtered, [nameKey], ["asc"]);
  return ordered;
}

//===
// Is it ready to be saved, and are there any changes?
function isReady(original, updates){
  const hasChanges  = !shallowEquals(original, updates);
  if(hasChanges){
    const isComplete  = (updates.siteId > 0 && updates.protocolId > 0 && updates.clientId > 0 && updates.projectId > 0);
    return isComplete;    
  }

  return false;
}