import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@mui/material';
import { useCurrentPermissions, useEngagement, useQuery, useSetting } from 'hooks';
import { CltHidden, ErrorDisplay } from 'features/common';
import NavMenu from 'features/app/nav-menu';
import { menuConfig, SET_LIST_SIZE_KEY } from './reviewsets-config';
import { useDispatch, useSelector } from 'react-redux';
import { selectMultiApproveFromEngagement } from 'store/selectors/reviewset-selectors';
import { IAppState, IEngagement } from "app-types";
import ReviewSetsList from './reviewsets-list';
import { useLocation, useMatch } from 'react-router-dom';
import { exportSets, loadSets } from 'store/actions/reviewsets-actions';
import { appRoutesLegacy } from 'utils/route-helpers';
import { shallowEquals } from 'utils/general-helpers';

//The properties that we watch to see if they changed
interface IWatchProps {
  engagementId: number;
  search?: string;
  filter?: string;
  view?: string;
  sort?: string;
}

interface IPageProps {
  pageNumber: number;
  pageSize: number;
}

const ReviewSetsView = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { engagementId, search, filter, sort } = useQuery();
  const engagement = useEngagement() as IEngagement; 
  const reportsMatch = useMatch(appRoutesLegacy.reviewsets.reports); 
  const [defaultPageSize, setDefaultPageSize] = useSetting(SET_LIST_SIZE_KEY, 25);
  const setsState = useSelector(state => (state as IAppState).sets);
  const { items, isLoadingItems, error, pagination } = setsState;
  
  const { isReadOnly } = useCurrentPermissions();
  const isMulti = useSelector(state => (selectMultiApproveFromEngagement as any)(state, engagementId));
  
  const sets = useMemo(() => items.filter(set => set.engagementId === engagementId), [items, engagementId]);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageProps, setPageProps] = useState<IPageProps>({pageNumber: 1, pageSize: defaultPageSize});
  const triggerProps = useMemo<IWatchProps>(() => ({  //Monitor specific properties of the url so we know when to refresh
      engagementId: engagementId!,
      search: search,
      filter: filter,
      view: undefined, //queryParams.view,   //TODO: determine the view.
      sort: sort,
    }), [engagementId, search, filter, sort]);

  const [prevProps, setPrevProps] = useState<IWatchProps>({ engagementId: engagementId! });

  const refresh = async (pageNumber?: number | null, pageSize?: number) => {
    //TODO: Do I need to check for a match with the reports view?
    if (!isLoading && !reportsMatch) { //props.view !== 'reports') {
      setIsLoading(true);

      //TODO: Should I default to the last page when navigating back to the Sets list?
      const pn = pageNumber ?? pageProps.pageNumber;
      const ps = pageSize ?? pageProps.pageSize;
      await dispatch(loadSets(triggerProps, pn, ps));

      if(pn !== pageProps.pageNumber || ps !== pageProps.pageSize){
        setPageProps({pageNumber: pn, pageSize: ps});
      }

      if(ps !== defaultPageSize) setDefaultPageSize(ps);  //update the setting for the default page size

      setPrevProps({...triggerProps});

      setIsInitialized(true);
      setIsLoading(false);
    }
  };
  
  //Determine if the list needs refreshing based on a change to the trigger props
  useEffect(() => {
    if(!isLoading){
      if(!isInitialized) refresh();
      // else if(isRefreshNeeded(prevProps, triggerProps)) {
      else if(!shallowEquals(prevProps, triggerProps)) {
        console.log("refreshing due to change: ", prevProps, triggerProps);
        refresh(1);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isLoading, prevProps, triggerProps]);

  const onPageChanged = (pageNumber: number, pageSize: number) => {
    refresh(pageNumber, pageSize);
  }

  const onExportSets = async () => {
    const result = await dispatch(exportSets({engagementId, search, filter, sort} as any));
    return result;
  }

  return (
    <Grid id="reviewsets-view" container spacing={2} flexWrap="nowrap" sx={{flexDirection: {xs: "column", md: "row"}}}>

      <Helmet>
        <title>{`Eng ${engagementId} ReviewSets`}</title>
      </Helmet>

      <CltHidden smDown>
        <Grid id="reviewset-menu" item>
          <NavMenu 
            settingsKey="engagement-nav" 
            menu={menuConfig} 
            basePath={pathname}
            isWorking={isLoadingItems} 
            conditionProps={{ isMulti: isMulti }} 
          />
        </Grid>
      </CltHidden>

      <Grid id="reviewset-content" container item sx={{pb: 2}}>
        <ErrorDisplay error={error}></ErrorDisplay>
        <ReviewSetsList
          sets={sets}
          target={pathname}   //test that this is correct (used to be match.url)
          canEdit={!isReadOnly}
          pagination={pagination!}
          changePage={onPageChanged}
          engagement={engagement}
          isWorking={isLoading}
          error={error}
          onExport={onExportSets}
        />
      </Grid>

    </Grid>
  );
}

export default ReviewSetsView;