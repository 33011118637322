import React, { ReactNode } from "react";
import { keys, reduce } from "lodash";
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import BookIcon from '@mui/icons-material/BookOutlined';
import DocIcon from '@mui/icons-material/DescriptionOutlined';
import { CltHidden, TipIf } from "features/common";

export interface IBreadcrumb {
  path: string;
  label: string;
  icon: ReactNode;
  isEnabled: boolean;
  name: (v: any) => string | null;
  tip?: (v: any) => string | null;
}

const BC_PARTS : Record<string, (pathPart: any) => IBreadcrumb | null>  = {
  engagementId  : (p: any) => {return {
    path      : "/engagements/:engagementId",
    label     : "Engagement :engagementId",
    icon      : <FolderIcon />,
    isEnabled : Boolean(p.reviewsetId || p.reviewsets || p.documents || p.reports),
    name      : (ex: any) => null,
    tip       : (ex: any) => ex.engName,
  }},
  reviewsetId   : (p: any) => {return {
    path      : "/engagements/:engagementId/reviewsets/:reviewsetId",
    label     : "ReviewSet :reviewsetId",
    icon      : <BookIcon />,
    isEnabled : Boolean(p.documentId),
    name      : (ex: any) => ex.isAdd ? "New ReviewSet" : ex.rsName,
    prefix    : "reviewsets",
  }},
  documentId    : (p: any) => {return {
    path      : "/engagements/:engagementId/reviewsets/:reviewsetId/documents/:documentId",
    label     : "Document :documentId",
    icon      : <DocIcon />,
    isEnabled : false,
    name      : (ex: any) => ex.docName,
  }},
  reviewsets  : (p: any) => {return {
    path      : "/engagements/:engagementId/reviewsets",
    label     : "ReviewSets",
    icon      : null,
    isEnabled : Boolean(p.reviewsetId),
    name      : (ex: any) => "ReviewSets",
  }},
  documents  : (p: any) => {return {
    path      : "/engagements/:engagementId/documents",
    label     : "Documents",
    icon      : null,
    isEnabled : false,
    name      : (ex: any) => "Documents",
  }},
  reports  : (p: any) => {return {
    path      : "/engagements/:engagementId/reports",
    label     : "Reports",
    icon      : null,
    isEnabled : false,
    name      : (ex: any) => "Reports",
  }},

  //react-router v6 includes a * in the params
  "*": (p: any) => null,  //
};

export interface IBreadcrumbProps {
  type: string;
  params: any;
  extras: Record<string, any>;
  onClick?: (item: any) => void;
}

const EngagementBreadcrumb = ({type, params, extras, onClick}: IBreadcrumbProps) => {
  const { classes } = buildStyles();
  
  const props   = BC_PARTS[type](params);
  if(!props) return null;    //If we're not here, don't show the breadcrumb

  //see if there's a prefix breadcrumb to this one...
  const path    = swapKeys(params, props.path);
  const label   = props.name(extras) || swapKeys(params, props.label);
  const tip     = props.tip ? props.tip(extras) : null;

  return (
    <>
      {props.isEnabled && 
        <TipIf tooltip={tip}>
          <Link color="inherit" to={path} className={classes.link} onClick={onClick}>
            {props.icon} {label}
          </Link>
        </TipIf>
      }
      <CltHidden xsDown>
        {!props.isEnabled && 
          <TipIf tooltip={tip}>
            <span color="primary" className={classes.link}>
            {props.icon} {label}
            </span>
          </TipIf>
        }
      </CltHidden>
    </>
  )
}

export default EngagementBreadcrumb;


const buildStyles = makeStyles()((theme: any) => ({
  link: {
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      alignItems: "center",
      fontSize: "1rem",
      textWrap: "pre-wrap",
      "& svg": {
        marginRight: theme.spacing(0.5),
      }
    },
  },
}));

function swapKeys(params: Record<string, any>, value: any){
  const result  = reduce(keys(params), (val, item) => val.replace(`:${item}`, params[item]), value);
  return result;
}