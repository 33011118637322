import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { toastr } from 'react-redux-toastr';
import { selectStatusByKey } from 'store/selectors/admin-selectors';
import { deleteReviewSet } from 'store/actions/admin-actions';
import { loadSets } from 'store/actions/reviewsets-actions';
import { adminDialogClasses } from 'features/common/dialog-classes';
import { IfBlock, PropertiesGrid, WaitButton, ErrorDisplay, StatusCard } from 'features/common';
import { useInputHandler } from 'hooks/general-hooks';
import { useAllEngagements } from 'hooks/admin-hooks';
import { formatDate, normalizeError } from 'utils/general-helpers';
import { validatePropertyCondition, validateExists } from 'utils/validation-helper';

const DeleteReviewSetTask = () => {
  const { classes }   = buildStyles();
  const dispatch  = useDispatch();
  const status        = useSelector(state => selectStatusByKey(state, "delete-rs"));
  const rsWorking     = useSelector(state => state.sets.isLoadingItems);
  const reviewSets    = useSelector(state => state.sets.items);
  const [item, onInputChange, setItem]  = useInputHandler({engId: "", rsId: "", confirm: ""}, null, onValidate);
  const [validation, setValidation]	= useState({canSave: false});
  const [isChecking, setChecking]   = useState(false);
  const [verified, setVerified]     = useState(null);
  const engagements   = useAllEngagements();
  const isWorking     = status.isWorking || rsWorking || isChecking;

  const set   = useMemo(() => {
    if(item.rsId && reviewSets){
      const id  = parseInt(item.rsId);
      return _.find(reviewSets, s => s.id === id);
    }
    
    return null;

  }, [item.rsId, reviewSets]);

  //Reset things, and load the ReviewSets if they choose a new engagement
  useEffect(() => {
    if(item.engId){
      //clear things out
      setVerified(null);
      setItem({engId: item.engId, rsId: "", confirm: ""});
      dispatch(loadSets({engagementId: item.engId}, 1, 999));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.engId]);

  //Clear out the verified prop if they choose a new ReviewSet 
  useEffect(() => {
    if(item.rsId){
      setVerified(null);
    }
  }, [item.rsId]);

  //Called by the inputs to validate the properties
  function onValidate(toValidate){
    //Check that we have values for notes, signature, confirmation
    let vState  = validateExists(toValidate);
    vState      = validatePropertyCondition(vState, "confirm", toValidate, v => v.length > 0 && v.toLowerCase() === "delete");
    setValidation(vState);
  }
  
  //Manual step to verify that this ReviewSet can be deleted.
  async function onVerifyDelete(){
    const result  = await dispatch(deleteReviewSet(item.rsId, true));
    if(!result.ok){
      const error   = normalizeError(result, "Cannot Delete", "Correct the items below to delete this ReviewSet");
      setVerified({isError: true, value: error});
    }
    else{
      setVerified({isError: false, value: "This ReviewSet can be deleted."});
    }

    setChecking(false);
  }

  //Manual step to verify that this ReviewSet can be deleted.
  async function onDelete(){
    setVerified(null);
    const result  = await dispatch(deleteReviewSet(item.rsId, false));
    if(!result.ok){
      const error   = normalizeError(result, "Cannot Delete", "Correct the items below to delete this ReviewSet");
      setVerified({isError: true, value: error});
    }
    else{
      toastr.success("ReviewSet Deleted Successfully");
      setItem({engId: item.engId, rsId: "", confirm: ""});
    }
  }

  const setProps   = [
    {label  : "Id", value: set?.id },
    {label  : "Title",  value: set?.title },
    {label  : "Status", value: set?.status },
    {label  : "Last Modified", value: set ? formatDate(set.lastModifiedDate) : null },
    {label  : "Last Modified By", value: set?.lastModifiedUserName },
    {label  : "Description", value: set?.description },
    {label  : "Notes", value: set?.notes },
  ];
  
  return (
    <Grid id="data-view" container justifyContent="center" className={classes.taskRoot}>

        <Grid item container>
          <Grid item xs={6} className={classes.formRow}>
            <FormControl className={classes.formControl} label="Engagement" fullWidth>
                <InputLabel id="label-engid" className={classes.inputLabel}>Engagement</InputLabel>
                <Select name="engId" labelId="label-engid" label="Engagement" value={item.engId} onChange={onInputChange} disabled={isWorking} fullWidth className={classes.selectContainer} classes={{select: classes.select}}  size="small">
                  <MenuItem value="" disabled className={classes.option}><em>Select Engagement</em></MenuItem>
                  {engagements && _.map(engagements, option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.id}. {option.name}</MenuItem>)}              
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <Typography className={classes.instructions}>Only assigned Engagements will show here.</Typography>
          </Grid>
        </Grid>

        <Grid item container>
          <IfBlock condition={rsWorking}>
            <CircularProgress className={classes.progress}/>
          </IfBlock>
          
          <IfBlock condition={!rsWorking}>
            <Grid item xs={6} className={classes.formRow}>
              <FormControl className={classes.formControl} label="ReviewSet" fullWidth disabled={!item.engId}>
                <InputLabel id="label-rsId" className={classes.inputLabel}>ReviewSet</InputLabel>
                <Select name="rsId" labelId="label-rsId" label="ReviewSet" value={item.rsId} onChange={onInputChange} disabled={isWorking || !item.engId} fullWidth className={classes.selectContainer} classes={{select: classes.select}}  size="small">
                  <MenuItem value="" disabled className={classes.option}><em>Select ReviewSet</em></MenuItem>
                  {reviewSets && _.map(reviewSets, option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.id}: {option.title}</MenuItem>)}              
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} container alignItems="center">
                <Typography className={classes.instructions}>ReviewSet must be Pending, with no Approved or Shared Documents.</Typography>
            </Grid>
          </IfBlock>
        </Grid>

        <IfBlock condition={set}>   
          <Card className={classes.previewCard}>
            <CardContent>
              <Grid container>
                <Grid container item xs={8}>
                  <PropertiesGrid props={setProps} labelWidth={150}/>            
                </Grid>
                <Grid item xs={4} container direction="column" justifyContent="space-between" alignItems="center">
                  <Typography className={classes.instructions}>This action <strong>cannot be undone</strong>; please confirm you are deleting the correct ReviewSet. The Verify button below will confirm this ReviewSet meets the requirements to be deleted.</Typography>
                  <WaitButton onClick={onVerifyDelete} isWaiting={isChecking} disabled={status.isWaiting} variant="outlined" color="secondary">Verify</WaitButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <IfBlock condition={verified}>
            <Grid container justifyContent="center">
              <ErrorDisplay error={verified?.isError ? verified?.value : null} />
              <StatusCard isVisible={!verified?.isError} message={verified?.isError ? null : verified?.value} status="success" />
            </Grid>
          </IfBlock>

          <Grid item container className={classes.confirmRow}>
            <Grid item xs={6} className={classes.formRow}>
              <TextField name="confirm" value={item.confirm} onChange={onInputChange} disabled={isWorking} placeholder="Type 'delete' to continue" fullWidth className={classes.field}/>
                
            </Grid>
            <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
              <WaitButton variant="outlined" color="primary" isWaiting={status.isWaiting} disabled={isWorking || validation?.count > 0} onClick={onDelete}>Delete ReviewSet</WaitButton>
            </Grid>
          </Grid>

        </IfBlock>

    </Grid>
  );
}

export default DeleteReviewSetTask;

const buildStyles   = makeStyles()(theme => ({
  ...adminDialogClasses(theme),
  waitRoot  : {
    padding     : theme.spacing(1),
  },  
  instructions  : {
    fontSize    : 14,
    fontWeight  : "300",
    padding     : theme.spacing(2),
  },
  progress      : {
    padding     : theme.spacing(1),
    fontSize    : 24,
  },
  previewCard   : {
    width     : "100%",
    // padding   : theme.spacing(2),
    background    : `${theme.palette.info.light}33`,
    borderColor   : theme.palette.info.dark,
    marginBottom  : theme.spacing(1),
  },
  previewLabel  : {
    color     : theme.palette.info.dark,
    fontSize  : 15,
    fontWeight: "400",
  },
  confirmRow  : {
    marginTop     : theme.spacing(2),
  }
}));