import Apis, { handleResult } from '../quanta-api';

const STATUS_CHANGE = "ADMIN_STATUS_CHANGE";
const LOADING_ENG = "LOADING_METRICS_ENG", LOADED_ENG = "LOADED_METRICS_ENG", FAILED_ENG = "METRICS_FAILED_ENG";
const LOADING_USER_RECENTS = "LOADING_USER_RECENTS", LOADED_USER_RECENTS = "LOADED_USER_RECENTS", FAILED_USER_RECENTS = "FAILED_USER_RECENTS";
const RS_COUNTS_LOADED = "RS_COUNTS_LOADED", DOC_COUNTS_LOADED = "DOC_COUNTS_LOADED";

export const METRICS_ACTIONS   = {
  STATUS_CHANGE,
  LOADING_ENG,
  LOADED_ENG,
  FAILED_ENG,
  LOADING_USER_RECENTS,
  LOADED_USER_RECENTS,
  FAILED_USER_RECENTS,
  RS_COUNTS_LOADED,
  DOC_COUNTS_LOADED
};

//=======================
// Loads the dashboard metrics for an Engagement
export const loadEngagementMetrics = (id) => async (dispatch, getState) => {
  dispatch({type: LOADING_ENG, id: id});
  const data  = await Apis.Metrics.get(`/engagement/${id}/rollup`);
  return handleResult(dispatch, data, LOADED_ENG, FAILED_ENG, {id: id});
}

export const loadUserRecentActivity = () => async (dispatch, getState) => {
  dispatch({type: LOADING_USER_RECENTS});
  // const user   = getState().app.currentUser;
  const data  = await Apis.Metrics.get("/user/recentactivity");
  return handleResult(dispatch, data, LOADED_USER_RECENTS, FAILED_USER_RECENTS);
}

export const loadEngagementReviewSetCounts = (id) => async (dispatch, getState) => {
  dispatch({type: METRICS_ACTIONS.STATUS_CHANGE, key: "rsCounts", value: {isWorking: true, error: null}});

  try{
    const data  = await Apis.Metrics.get(`/engagement/${id}/reviewsetcounts`);
    const result  = handleResult(dispatch, data, RS_COUNTS_LOADED);

    dispatch({type: METRICS_ACTIONS.STATUS_CHANGE, key: "rsCounts", value: {isWorking: false, error: null, isInitialized: true}});
    return result;

  }
  catch(ex){
    dispatch({type: METRICS_ACTIONS.STATUS_CHANGE, key: "rsCounts", value: { isWorking: false, error: ex}});
  }
}

export const loadEngagementDocumentCounts = (id) => async (dispatch, getState) => {
  dispatch({type: METRICS_ACTIONS.STATUS_CHANGE, key: "docCounts", value: {isWorking: true, error: null}});

  try{
    const data  = await Apis.Metrics.get(`/engagement/${id}/documentcounts`);
    const result  = handleResult(dispatch, data, DOC_COUNTS_LOADED);

    dispatch({type: METRICS_ACTIONS.STATUS_CHANGE, key: "docCounts", value: {isWorking: false, error: null, isInitialized: true}});
    return result;

  }
  catch(ex){
    dispatch({type: METRICS_ACTIONS.STATUS_CHANGE, key: "docCounts", value: { isWorking: false, error: ex}});
  }
}