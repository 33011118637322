import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { formatDate } from 'utils/general-helpers';
import { ItemList, ErrorDisplay } from 'features/common';
import { loadUsers } from 'store/actions/admin-actions';
import { selectStatusByKey } from 'store/selectors/admin-selectors';
import { useDialogs } from 'hooks';
import UserDialog from './user-dialog';
import { exportUsers } from '../../../store/actions/admin-actions';
import { TableCell, TableRow } from '@mui/material';

const UsersView = () => {
  const { classes }   = buildStyles();
  const [dialogs, openDialog, closeDialog]  = useDialogs();
  const status    = useSelector(state => selectStatusByKey(state, "users"));
  const users     = useSelector(state => state.admin.users);
  const [lastQuery, setLastQuery] = useState(null);
  const dispatch  = useDispatch();

  function onRefresh(props, query){
    dispatch(loadUsers(query, props.pageNumber, props.pageSize));
    setLastQuery(query);
  }

  function onEdit(id){
    openDialog("user", id);
  }

  function onAdd(){
    openDialog("user", -1);
  }

  async function onExport(){
    await dispatch(exportUsers(lastQuery));
  }

  const gridConfig  = {
    title     : "Users",
    baseUrl   : "/admin/users",
    searchPlaceholder   : "Search by name...",
    keyFunc   : item => item.id,
    actions   : {
      onEdit      : (id) => onEdit(id),
    },
    cols  : GRID_HEADER,
  }

  return (
    <>
      <Card id="users-card" className={classes.usersCard}>
        <CardContent>
          <>
            <ErrorDisplay error={status.error}></ErrorDisplay>
            <ItemList 
              config={gridConfig} 
              items={users?.items} 
              pagination={users?.pagination} 
              isWorking={status.isWorking}
              onRefresh={onRefresh}
              onAdd={onAdd}
              RowComponent={UserListItem}
              onExport={onExport}
            />
          </>
        </CardContent>
      </Card>
      {dialogs?.user && <UserDialog isOpen={Boolean(dialogs?.user)} onClose={() => closeDialog("user")} userId={dialogs?.user} />}
    </>
  );
}

export default UsersView;

const buildStyles   = makeStyles()(theme => ({
  usersCard  : {
    width       : "100%",
  },
  editButton  : {
    textTransform   : "none",
    fontWeight      : "700",
    padding         : 0,
    color           : "#337ab7",
  }
}));

function UserListItem({item, actions}){
  const { classes }   = buildStyles();
  return (
    <TableRow className={classes.userRow}>
      <TableCell className={classes.itemCol} sx={{minWidth: "55px"}}>{item.id}</TableCell>
      <TableCell className={classes.itemCol}><Button onClick={() => actions.onEdit(item.id)} className={classes.editButton}>{item.displayName}</Button></TableCell>
      <TableCell className={classes.itemCol}>{item.email}</TableCell>
      <TableCell className={classes.itemCol}>{item.role}</TableCell>
      <TableCell className={classes.itemCol}>{formatDate(item.validFrom)}</TableCell>
      {/* <TableCell></TableCell> */}
    </TableRow>
  );
}

const GRID_HEADER   = [
  {
    id        : 0,
    sortKey   : "id",
    label     : "Id",
    style     : {minWidth: "60px"},
  },
  {
    id        : 10,
    sortKey   : "name",
    label     : "Name",
    style     : {minWidth: "150px"},    
    defaulTableCellir: "asc",
  },
  {
    id        : 20,
    sortKey   : "email",
    label     : "Email",
  },
  {
    id        : 30,
    label     : "Role",
    style     : {minWidth: "60px"},
  },
  {
    id        : 40,
    sortKey   : "date",
    label     : "Last Modified",
    style     : {minWidth: "150px"},
  },
  // {
  //   id        : 50,
  //   label     : "Actions",
  //   style     : {minWidth: "45px"},
  // }
];