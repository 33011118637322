// import _ from 'lodash';
import Apis, { handleResult } from '../quanta-api';
import { APPROVE_TYPES } from 'utils/reviewset-helpers';
import { differenceInMinutes } from 'date-fns';

const ITEM_LIFETIME   = 5;    //Num of Minutes until an item becomes stale

export const SET_ACTIONS = {
  ITEM_LOADING      : "SET_LOADING",
  ITEM_LOADED       : "SET_LOADED",
  ITEM_ERROR        : "SET_ERROR",
  ITEM_SAVING       : "SET_SAVING",
  ITEM_SAVED        : "SET_SAVED",
  ITEM_CREATED      : "SET_CREATED",
  ITEM_SAVE_FAILED  : "SET_SAVE_FAILED",

  APPROVING         : "SET_APPROVING",
  APPROVED          : "SET_APPROVED",
  APPROVE_FAILED    : "SET_APPROVE_FAILED",
  APPROVE_CLEARED   : "SET_APPROVE_CLEARED",
};

//=====================
// Loads a specific ReviewSet by it's ID
export const loadSet = (id, engagementId, isForced) => async (dispatch, getState) => {
  //Check to see if we really need to load this item
  const state   = getState().set;
  let item = null;
  let itemEngagementId = -1;
  if(!isForced && !state.itemStatus.isDocChanged && haveItem(id, state)){
    item = state.item;
    itemEngagementId = item.engagementId;
  }
  else {
    dispatch({type: SET_ACTIONS.ITEM_LOADING});  
    item     = await Apis.Reviewsets.get(`/${id}`);
    itemEngagementId = item.data?.engagementId;
  }
  
  if(itemEngagementId && itemEngagementId !== engagementId){
    //trying to access the reviewset with an invalid engagement id
    console.error("ReviewSet requested with invalid engagement id");
    const error = {
      ok: false,
      message: "404: ReviewSet not found",
      id,
    };
    return handleResult(dispatch, error, SET_ACTIONS.ITEM_LOADED, SET_ACTIONS.ITEM_ERROR);    
  }

  return handleResult(dispatch, { id, ...item }, SET_ACTIONS.ITEM_LOADED, SET_ACTIONS.ITEM_ERROR);  
}

//=====================
// Saves changes a ReviewSet
export const saveSet = (id, updates) => async(dispatch, getState) => {
  
  dispatch({type: SET_ACTIONS.ITEM_SAVING});

  const result  = await Apis.Reviewsets.put(`/${id}`, updates);

  if(result.ok){
    //Update the changes with the last modified info
    const user      = getState().app.currentUser;
    const changes   = {
      ...updates,
      lastModifiedDate      : result.data?.validFrom,
      lastModifiedUserName  : user.displayName,
      lastModifiedUserId    : user.id,
    };
    return await dispatch({type: SET_ACTIONS.ITEM_SAVED, id: id, data : changes, response: result });
  }
  else{
    return await dispatch({type: SET_ACTIONS.ITEM_SAVE_FAILED, response: result});
  }
}

//==========================
// Create a new ReviewSet
export const createSet = (props) => async(dispatch, getState) => {
  dispatch({type: SET_ACTIONS.ITEM_SAVING});
  
  const result  = await Apis.Reviewsets.post("", props);
  if(result.ok){
    //Update the changes with the last modified info
    const user      = getState().app.currentUser;
    const set       = {
      ...result.data,
      lastModifiedDate      : result.data?.validFrom,
      lastModifiedUserName  : user.displayName,
      lastModifiedUserId    : user.id,
    };
    return await dispatch({type: SET_ACTIONS.ITEM_CREATED, data : set, response: result });
  }
  else{
    return await dispatch({type: SET_ACTIONS.ITEM_SAVE_FAILED, response: result});
  }
}

//==================
// Approve / Unapprove a reviewset
export const approveReviewSet = (model) => async (dispatch, getState) => {
  const endpoint  = model.status === APPROVE_TYPES.unApprove ? "unapprove" : "approve"; 
  const url       = `/${model.setId}/${endpoint}`;

  dispatch({type: SET_ACTIONS.APPROVING, props: model});
  const result    = await Apis.Reviewsets.post(url, model);
  return handleResult(dispatch, result, SET_ACTIONS.APPROVED, SET_ACTIONS.APPROVE_FAILED, { props: model });
}

//clears out the approving structure in the reducer
export const clearReviewSetApproval = () => async (dispatch) => {
  dispatch({type: SET_ACTIONS.APPROVE_CLEARED});
}

//********************/
// Helper Functions

//--------------
// Check to see if I already have the item and it's fresh
function haveItem(id, state){
  const { item, itemTime }  = state;
  if(item && itemTime){
    if(item.id === id){
      if(differenceInMinutes(new Date(), itemTime) <= ITEM_LIFETIME){
        return true;
      }
    }
  }

  return false;
}