import React from 'react';
import Header from './header';
import ReduxToastr from 'react-redux-toastr';
import { Grid } from '@mui/material';

function AppLayout({children}){

  return (
    <div id="app-layout">
      <Header />
      <Grid component="main" id="app-main" sx={{pt: {xs: "110px", sm: 8}}}>
        <Grid id="app-layout-container" sx={{px: 2}}>
          {children}
        </Grid>
        <ReduxToastr timeout={4000} position="bottom-right" getState={state => state.toastr} transitionIn="fadeIn" transitionOut="fadeOut" progressBar closeOnToastrClick />
      </Grid>
    </div>
  );
}

export default AppLayout;