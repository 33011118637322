import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Loading, WaitButton } from '../../../features/common';
import { loadDocReviewRecords } from 'store/actions/document-actions';
import { IReviewRecord } from "app-types";
import { Link } from 'react-router-dom';
import { RsvTr, RsvTd, RsvRowContainer, RsvActionsCol } from 'features/common/table';
import { useAppParams, useBoolState } from 'hooks';

export interface IDocListItemDeletingProps {
  doc: any,
  onDelete: (doc: any) => void,
  onCancel: () => void
}

const DocumentListItemDeleting = ({doc, onDelete, onCancel}: IDocListItemDeletingProps) => {
  const { classes, cx }     = buildStyles();
  const isRemoving  = useSelector<any, boolean>(state => state.docs.isRemoving);
  const { engagementId } = useAppParams();
  const [isChecking, setChecking] = useBoolState(true);
  const [otherSets, setOtherSets] = useState<null | JSX.Element[]>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function doEffect(){
      // console.log("TODO: get the review records to see if there are secondaries.");
      const result = await loadDocReviewRecords(doc.id);
      if(result.ok){

        //If there is more than one review record, then this one can't be deleted.
        if(result.data.length > 1){
          const reviewRecords = result.data as IReviewRecord[];
          const nonPrimaries = reviewRecords
            .filter(rr => rr.primaryReviewRecordId !== null)
            .map((rr, index, arr) => <span key={index}><Link to={`/engagements/${engagementId}/reviewsets/${rr.reviewSetId}`} key={rr.id} className={classes.secondaryRr}>{rr.reviewSetId}</Link>{index === arr.length - 1 ? "": ", "}</span>);

          setOtherSets(nonPrimaries);
        }
        setChecking(false);
      }
      else{
        setError(result.error);
        setChecking(false);
      }      
    }

    if(doc.primaryReviewRecordId === null) doEffect();
    else setChecking(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  //If we're checking for secondary ReviewSets, show the user we're working
  if(isChecking){
    return (
      <RsvTr className={classes.deleteCol}>
        <RsvRowContainer>
          <RsvTd colSpan={5} className={classes.deleteCol}>
            <Loading isVisible={true} message="Preparing to delete..." className={classes.loadingStyle}/>
          </RsvTd>
        </RsvRowContainer>
      </RsvTr>
    );
  }
  else if(otherSets){
    return (
      <RsvTr className={classes.deleteCol}>
        <RsvRowContainer>
          <RsvTd colSpan={3} className={classes.deleteCol}>
            <Typography className={classes.message}><span className={classes.bold}>This document is attached to other ReviewSets and cannot be deleted here.</span></Typography>
            <Typography className={classes.message}>To delete it here, you must first remove it from the following ReviewSets: {otherSets}</Typography>
          </RsvTd>
        </RsvRowContainer>
        <RsvActionsCol colSpan={2} className={cx(classes.deleteCol, classes.deleteActions)}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Button variant="outlined" size="small" color="inherit" className={classes.btn} onClick={onCancel} disabled={isRemoving}>Cancel</Button>
          </Grid>
        </RsvActionsCol>
      </RsvTr>
    );
  }
  else if(!!error){
    return (
      <RsvTr className={classes.deleteCol}>
        <RsvTd colSpan={3} className={classes.deleteCol}>
          <Typography className={classes.message}>The system encountered an error while checking to see if this document can be deleted.</Typography>
          <Typography className={classes.message}>{error}</Typography>
        </RsvTd>
        <RsvTd colSpan={2} className={cx(classes.deleteCol, classes.deleteActions)}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Button variant="outlined" size="small" color="inherit" className={classes.btn} onClick={onCancel} disabled={isRemoving}>Cancel</Button>
          </Grid>
        </RsvTd>
      </RsvTr>
    );
  }

  return (
    <RsvTr className={classes.deleteCol}>
      <RsvRowContainer>
        <RsvTd colSpan={3} className={classes.deleteCol}>
          <Grid container direction="column">
            <Typography className={classes.message}>Press continue to remove <span className={classes.italic}>{doc.documentKey} - {doc.documentTypeName}</span></Typography>
            {!doc.primaryReviewRecordId && <Typography className={classes.message}><span className={classes.bold}>WARNING:</span> The document and all responses and statuses will be permanently deleted</Typography>}
          </Grid>
        </RsvTd>
      </RsvRowContainer>
      <RsvActionsCol colSpan={2} className={cx(classes.deleteCol, classes.deleteActions)}>
        <Grid container alignItems="center" justifyContent="flex-end">
          <WaitButton onClick={() => onDelete(doc)} isWaiting={isRemoving} disabled={isRemoving} variant="outlined" size="small" color="secondary" className={classes.btn}>Continue</WaitButton>
          <Button variant="outlined" size="small" color="inherit" className={classes.btn} onClick={onCancel} disabled={isRemoving}>Cancel</Button>
        </Grid>
      </RsvActionsCol>
    </RsvTr>
  );
}

export default DocumentListItemDeleting;

const buildStyles = makeStyles()((theme: any) => ({
  deleteCol   : {
    backgroundColor   : theme.palette.error.light, //"#ed5565",
    color             : theme.palette.error.contrastText, 
    verticalAlign     : "middle !important",   
  },
  btn   : {
    padding       : theme.spacing(0.5),
    marginRight   : theme.spacing(0.5),
  },
  deleteActions  : {
    textAlign         : "right",
    "& .MuiButton-label"  : {
      fontSize        : "0.75rem",
    }
  },
  primaryRr: {
    fontWeight: 700,
  },
  secondaryRr: {
    fontWeight: 500,
  },
  message: {
    fontSize: "1rem",
  },
  bold: {
    fontWeight: 700,
  },
  italic: {
    fontStyle: "italic",
  },
  loadingStyle: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    "& span" : {
      marginBottom: 0,
      marginRight: theme.spacing(2),
    }
  }
}));