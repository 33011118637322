import { SETS_ACTIONS } from '../actions/reviewsets-actions';
import { SET_ACTIONS } from '../actions/reviewset-actions';
import { ADMIN_ACTIONS }  from '../actions/admin-actions';
import { REPORT_ACTIONS } from '../actions/report-actions';
import { createReducer } from 'utils/reducer-helper';
import { removeItem, replaceItem } from '../store-helpers';

const INITIAL_STATE = {
  isInitialized       : false,
  error               : null,
  
  isLoadingItems      : false,
  pagination          : null,
  items               : [],

  loadTime            : null,   //the time the last load occurred
  lastFilter          : null,   //the filter from the last load
  isItemChanged       : false,  //means there's a change to the item, so need to refresh the list

  pendingItems         : [], //Used by the Move Document function to choose a reviewset to move the document to
};

function itemsLoading(state, action){
  return {
    ...state,
    error           : null,
    isLoadingItems  : true
  };
}

function itemsLoaded(state, action){
  return {
    ...state,
    isInitialized   : true,
    isLoadingItems  : false,
    error           : null,
    items           : action.response,
    pagination      : action.pagination,
    
    loadTime        : new Date(),
    lastFilter      : action.filter,
    isItemChanged   : false,
  }
}

function pendingItemsLoaded(state, action){
  return {
    ...state,
    isLoadingItems: false,
    error: null,
    pendingItems: action.response,
  };
}

function itemsExported(state, action){
  return {
    ...state,
    isLoadingItems  : false,
    error           : null,
  }
}

function itemDeleted(state, action){
  return {
    ...state,
    items           : removeItem(state.items, i => i.id === action.id),
  };
}

function error(state, action){
  return {
    ...state,
    isLoadingItems    : false,
    //TODO: Add other loading / working items here
    error             : action.response,
    items             : null,
    pagination        : null,
  };
}

function exportError(state, action){
  return {
    ...state,
    isLoadingItems    : false,
    error             : action.error,
  };
}

function itemChanged(state, action){
  //This means that the set item has changed (saved, deleted, approved, etc), so we need to refresh the list
  return {
    ...state,
    isItemChanged   : true,
  }
}

function approvalReportStatusChanged(state, action){
  //Only care when there's a new set approval report available
  if(action.data && action.data.status === "available" && state.items){
    //find the item that was updated
    const setId = action.id;
    const original = state.items.find(rs => rs.id === setId);
    if(original){
      const replacement = {...original, reportLocation: action.data.reportPath };
      const updatedList = replaceItem(state.items, original, replacement);
      return {
        ...state,
        items: updatedList,
      };
    }
  }
  
  return state;
}

//----------------------
// The action map that is turned into the reducer
const setsReducer    = {

  [SETS_ACTIONS.ITEMS_LOADING]    : itemsLoading,
  [SETS_ACTIONS.ITEMS_LOADED]     : itemsLoaded,
  [SETS_ACTIONS.ITEMS_ERROR]      : error,

  [SETS_ACTIONS.ITEMS_EXPORTING]  : itemsLoading,
  [SETS_ACTIONS.ITEMS_EXPORTED]   : itemsExported,
  [SETS_ACTIONS.EXPORT_ERROR]     : exportError,

  [SET_ACTIONS.APPROVED]          : itemChanged,
  [SET_ACTIONS.ITEM_SAVED]        : itemChanged,
  [SET_ACTIONS.ITEM_CREATED]      : itemChanged,

  [ADMIN_ACTIONS.SET_DELETED]     : itemDeleted,

  [SETS_ACTIONS.PENDING_LOADED]    : pendingItemsLoaded,
  
  //Track when there's a reviewset report available
  [REPORT_ACTIONS.REPORT_STATUS_CHECKED] : approvalReportStatusChanged,
};

//The reducer
export default createReducer(INITIAL_STATE, setsReducer);