import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { createSet, saveSet } from 'store/actions/reviewset-actions';
import { CltHidden, ErrorDisplay, WaitButton } from 'features/common';
import { useCurrentPermissions } from 'hooks/general-hooks';
import { useNavigate } from 'react-router-dom';

function ReviewSetEditHeader({set}){
  const serverError         = useSelector(state => state.set.error);
  const isSaving            = useSelector(state => state.set.isSaving);
  const dispatch            = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError]   = useState(null);
  const cancelUrl           = pathname.replace("/add", "").replace("/edit", "");
  const [updates, setUpdates]  = useState(flattenSet(set));
  const permissions = useCurrentPermissions();

  const onChanged  = (e) => {
    const key   = e.currentTarget.id;
    setUpdates({
      ...updates,
      [key]     : e.currentTarget.value,
    });
  }

  const onSave = async () => {
    //make sure there are changes to save
    const isAdd   = Boolean(!set);
    if(!isChanged(updates, set)) navigate(cancelUrl, { replace: true });

    //Validate the data, make sure it is good
    const changes   = unflattenSet(updates, set, params?.engagementId);
    const invalid   = validateSet(changes);
    if(invalid){
      setError(invalid);
      return;
    }

    const result  = isAdd ? 
      await dispatch(createSet(changes)) : 
      await dispatch(saveSet(set?.id, changes));

    if(result.response?.ok){
      const newUrl  = isAdd ? pathname.replace("/add", `/${result.data.id}`) : cancelUrl;
      navigate(newUrl, { replace: true });
    }
    else{
      setError(result.error);
    } 
  }

  //Make sure we're allowed to be here
  if(permissions.isReadOnly){
    return (
      <ErrorDisplay error="You do not have permission to access this view" style={{maxWidth: "50%", marginTop: "5%"}}/>
    );
  }

  return (
    <Grid id="reviewset-editor" container rowGap={1} sx={{ p: 2, mt: 2 }}>

      {(error || serverError) && <ErrorDisplay error={error || serverError} />}

      <Grid item container sx={{mb: 1}}>
        <CltHidden smUp>
          <Grid item xs={8} sm={1} container alignItems="center" justifyContent={{xs: "flex-start", md: "flex-end"}} sx={{color: "text.secondary", px: { xs: 0, md: 1}, fontSize: "0.8rem"}}>Title</Grid>
        </CltHidden>
        <Grid item xs={12} md={8}>
          <TextField id="title" fullWidth size="small" placeholder="Title" variant="outlined" value={updates.title} onChange={onChanged} disabled={isSaving}/>
        </Grid>
        <CltHidden smDown>
          <Grid item sm={4} container justifyContent="flex-end" alignItems="center">
            <WaitButton size="small" variant="outlined" endIcon={<SaveIcon />} onClick={onSave} isWaiting={isSaving}>Save</WaitButton>
            <Button size="small" variant="outlined" color="default" component={Link} to={cancelUrl} endIcon={<CloseIcon/>} disabled={isSaving}>Cancel</Button>          </Grid>
        </CltHidden>
      </Grid>

      <Grid item xs={12} container sx={{mb: 1}}>
        <CltHidden smUp>
          <Grid item xs={8} sm={1} container alignItems="center" justifyContent={{xs: "flex-start", md: "flex-end"}} sx={{color: "text.secondary", px: { xs: 0, md: 1}, fontSize: "0.8rem"}}>Description</Grid>
        </CltHidden>
        <TextField multiline minRows={3} id="description" fullWidth size="small" variant="outlined" placeholder="Description" value={updates.description} onChange={onChanged}  disabled={isSaving}/>
      </Grid>

      <Grid item xs={12} container sx={{mb: { xs: 0, md: 1 }}}>
        <Grid item xs={8} sm={1} container alignItems="center" justifyContent={{xs: "flex-start", md: "flex-end"}} sx={{color: "text.secondary", px: { xs: 0, md: 1}, fontSize: "0.8rem"}}>Lot #</Grid>
        <Grid item xs={12} sm={5} sx={{mb: 1}}>
          <TextField id="lotNumber" fullWidth size="small" variant="outlined" value={updates.lotNumber} onChange={onChanged} disabled={isSaving} />
        </Grid>
        <Grid item xs={8} sm={1} container alignItems="center" justifyContent={{xs: "flex-start", md: "flex-end"}} sx={{color: "text.secondary", px: { xs: 0, md: 1}, fontSize: "0.8rem"}}>Part #</Grid>
        <Grid item xs={12} sm={5} sx={{mb: { xs: 0, md: 1 }}}>
          <TextField id="partNumber" fullWidth size="small" variant="outlined" value={updates.partNumber} onChange={onChanged} disabled={isSaving} />
        </Grid>
      </Grid>

      <Grid item xs={12} container sx={{mb: 1}}>
        <Grid item xs={8} sm={1} container alignItems="center" justifyContent={{xs: "flex-start", md: "flex-end"}} sx={{color: "text.secondary", px: { xs: 0, md: 1}, fontSize: "0.8rem"}}>Product</Grid>
        <Grid item xs={12} sm={5}>
          <TextField id="productName" fullWidth size="small" variant="outlined" value={updates.productName} onChange={onChanged} disabled={isSaving} />
        </Grid>
      </Grid>

      <Grid item xs={12} container sx={{mb: 1}}>
        <Grid item xs={8} sm={1} container alignItems="center" justifyContent={{xs: "flex-start", md: "flex-end"}} sx={{color: "text.secondary", px: { xs: 0, md: 1}, fontSize: "0.8rem"}}>Notes</Grid>
        <Grid item xs={12} sm={11}>
          <TextField multiline minRows={3} id="notes" fullWidth size="small" variant="outlined" value={updates.notes} onChange={onChanged} disabled={isSaving} />
        </Grid>
      </Grid>

      <CltHidden mdUp>
        <Grid item xs={12} container justifyContent="flex-end" alignItems="center" sx={{mb: 1}} >
          <WaitButton color="secondary" variant="outlined" sx={{ml: 0.5}} endIcon={<SaveIcon />} onClick={onSave} isWaiting={isSaving}>Save</WaitButton>
          <Button variant="outlined" component={Link} to={cancelUrl} sx={{ml: 0.5}} endIcon={<CloseIcon/>} disabled={isSaving}>Cancel</Button>
        </Grid>
      </CltHidden>
  
    </Grid>
  );
}

export default ReviewSetEditHeader;

function flattenSet(set){
  return {
    title         : set?.title || "",
    description   : set?.propertyBag?.description || "",
    notes         : set?.notes || "",
    lotNumber     : set?.propertyBag?.lotNumber || "",
    partNumber    : set?.propertyBag?.partNumber || "",
    productName   : set?.propertyBag?.productName || "",
  };
}

function unflattenSet(updates, set, engagementId){
  let output  = {
    title       : updates.title ? updates.title : null,
    notes       : updates.notes ? updates.notes : null,
    propertyBag   : {
      description   : updates.description ? updates.description : null,
      lotNumber     : updates.lotNumber ? updates.lotNumber : null,
      partNumber    : updates.partNumber ? updates.partNumber : null,
      productName   : updates.productName ? updates.productName : null,
    }
  };
  if(!set && engagementId){
    output.engagementId     = parseInt(engagementId)
  }

  return output;
}

function isChanged(updates, set)
{
  const flattened   = flattenSet(set);
  return !_.isEqual(updates, flattened);
}

function validateSet(candidate){
  //At this point, just validate it has a title
  if(!candidate.title || candidate.title.trim().length === 0){
      return { status: "Validation Failure", problem: "ReviewSet Title is required.  Please enter a valid title for this ReviewSet." };
  }

  return null;
}
