import _ from 'lodash';
import { BUILDER_ACTIONS } from '../actions/builder-actions';
import { createReducer, INITIALIZED } from 'utils/reducer-helper';
import { createWorkingChecklist, defaultChecklist } from 'utils/checklist-helpers';

const INITIAL_STATE   = {
  status        : {
    checklists      : {
      ...INITIALIZED,
      isDirty           : false,
    },
    builder    : {...INITIALIZED},
  },

  original    : null,
  working     : null,
  // checklist   : null,
  // sections    : null,
  // items       : null,
};

function statusChanged(state, action){
  const current   = state.status[action.key];
  const updated   = {...current, ...action.value};
  return {
    ...state,
    status  : {
      ...state.status,
      [action.key]  : {
        ...state.status[action.key],
        ...updated
      },
    }
  };
}

function checklistLoaded(state, action){
  let item  = action.response;
  //order the items for simplicity
  const items   = _.orderBy(item.checkListItems, i => i.ordinal);
  item.checkListItems = items;

  const result = {
    ...state,
    original      : item,    //original version for comparison
    working       : createWorkingChecklist(item),    
    status    : {
      ...state.status,
      checklists   : {
        isWorking     : false,
        isInitialized : true,
        error         : null,
        isDirty       : false,
      }
    }    
  };

  return result;
}

//Used when an existing checklist is saved only
function checklistSaved(state, action){
  const item      = action.response;

  //TODO: deal with items that are expanded, etc...
  const expandedItems = _.filter(state.working.checklistItems, i => i.isExpanded);

  const result = {
    ...state,
    original      : item,    //original version for comparison
    working       : createWorkingChecklist(item, expandedItems),    
    status    : {
      ...state.status,
      checklists   : {
        isWorking     : false,
        isInitialized : true,
        error         : null,
        isDirty       : false,
      }
    }    
  };

  return result;
}


function checklistStarted(state, action){
  const newItem   = {...defaultChecklist(action.documentTypeId)};
  // const newItem   = {
  //   id    : -1,
  //   name  : "",
  //   documentTypeId  : action.documentTypeId,
  //   description   : "",
  //   sections  : [],
  //   items     : [],
  // };

  return {
    ...state,
    original    : newItem,
    working     : createWorkingChecklist(newItem),
    status    : {
      ...state.status,
      checklists   : {
        isWorking     : false,
        isInitialized : true,
        error         : null,
        isDirty       : false,
      }
    } 
  }
}

function updateWorking(state, action){
  let result = {
    ...state,
    working   : {
      ...state.working,
      ...action.data,
    }
  };
  
  if(action.checklistItems){
    result.working.checklistItems   = action.checklistItems;
  }

  return result;
}

//----------------------
// The action map that is turned into the reducer
const builderReducer    = {

  [BUILDER_ACTIONS.STATUS_CHANGE]     : statusChanged,
  [BUILDER_ACTIONS.CHECKLIST_LOADED]  : checklistLoaded,
  [BUILDER_ACTIONS.CHECKLIST_STARTED] : checklistStarted,
  [BUILDER_ACTIONS.CHECKLIST_SAVED]   : checklistSaved,
  [BUILDER_ACTIONS.CHECKLIST_CREATED] : checklistLoaded,
  [BUILDER_ACTIONS.CHECKLIST_RELOADED] : checklistSaved,

  [BUILDER_ACTIONS.UPDATE_WORKING]    : updateWorking,
  [BUILDER_ACTIONS.SECTION_UPDATED]   : updateWorking,
  [BUILDER_ACTIONS.SECTION_TOGGLED]   : updateWorking,
  [BUILDER_ACTIONS.ITEM_UPDATED]      : updateWorking,
  [BUILDER_ACTIONS.SECTION_MOVED]     : updateWorking,
  [BUILDER_ACTIONS.ITEM_MOVED]        : updateWorking,
  [BUILDER_ACTIONS.SECTION_INSERTED]  : updateWorking,
  [BUILDER_ACTIONS.ITEM_INSERTED]     : updateWorking,
};

//The reducer
export default createReducer(INITIAL_STATE, builderReducer);