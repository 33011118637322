import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Loading, StatusCard } from 'features/common';
import { loadChecklist } from 'store/actions/builder-actions';
import { selectChecklist, selectStatus, selectWorkingItem } from 'store/selectors/builder-selectors';
import { statusBackgrounds } from 'utils/checklist-helpers';
import { selectWorkingSection } from '../../../store/selectors/builder-selectors';
import { useLocation, useParams } from 'react-router-dom';

const ChecklistPrintView = () => {
  const { classes, cx }     = buildStyles();
  const dispatch    = useDispatch();
  const location    = useLocation();
  const { checklistId: id }   = useParams();
  const status      = useSelector(state => selectStatus(state, "checklists"));
  const checklist   = useSelector(state => selectChecklist(state, id));
  const [isRedirect, setRedirect]   = useState(location.state?.isRedirect);
  
  //effect to load the full version of the checklist
  useEffect(() => {
    if(id > 0 && (!checklist || checklist.id !== id)){
      async function load(){
        return await dispatch(loadChecklist(id));
      }
      load();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  let counter   = 1;

  return (
    <Grid id="checklist-print-view" container justifyContent="center" className={classes.printView}>
      <StatusCard status="info" isVisible={isRedirect} className={classes.redirectCard}>
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography className={classes.redirectText}>You have been taken to the print view because this Checklist is in use.</Typography>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <IconButton
              onClick={() => setRedirect(false)}
              title="Close this message"
              size="large"><CloseIcon fontSize="small"/></IconButton>
          </Grid>
        </Grid>
      </StatusCard>
      <Loading isVisible={status?.isWorking} message="Loading checklist..." className={classes.loading}/>
      <PrintHeader classes={classes} checklist={checklist} />
      {
        _.map(checklist?.sections, section => <PrintSection key={section.id} sectionId={section.id} index={counter++} classes={classes}  cx={cx} />)
      }
    </Grid>
  );
}

export default ChecklistPrintView;

const buildStyles   = makeStyles()(theme => ({
  printView   : {

  },
  loading     : {
    marginTop   : "5%",
  },
  header      : {
    marginBottom    : theme.spacing(2),
  },
  redirectCard  : {
    marginBottom  : theme.spacing(2),
  },
  redirectText  : {
    color         : theme.palette.info.main,
  },
  name  : {
    fontSize    : "1.5rem",
    fontWeight  : "700",
    display     : "block",
  },
  description : {
    fontSize    : "1.25rem",
    fontWeight  : "500",
    display     : "block",
  },
  section     : {
    marginBottom    : theme.spacing(1.5),
  },
  sectionNameGrid   : {
    borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
    marginBottom  : theme.spacing(3),
  },
  sectionName   : {
    fontSize    : "1.5rem",
    fontWeight  : "400",
    
  },
  assertion   : {
    marginBottom    : theme.spacing(2),
    marginLeft      : theme.spacing(1),
  },
  assertionText   : {
    fontSize    : "1.5rem",
    fontWeight  : "300",
  },
  inputGrid   : {
    marginBottom  : theme.spacing(1.5),
  },
  input   : {

  },
  inputChip   : {
    marginRight   : theme.spacing(0.5),
    "&.confirm"  : {
      background    : theme.statuses.confirmed.background,
      border        : `1px solid ${theme.statuses.confirmed.border}`, //`
    },
    "&.reject"  : {
      background    : theme.statuses.rejected.background,
      border        : `1px solid ${theme.statuses.rejected.border}`, //`
    },
    "&.na"  : {
      background    : theme.statuses.na.background,
      border        : `1px solid ${theme.statuses.na.border}`, //`
    },
  },
  inputText   : {
    marginLeft    : theme.spacing(4),
  },
  inputCaption  : {
    fontSize      : 14,
    fontWeight    : 500,
    color         : theme.palette.grey[800],
  },
  textInputLabel  : {
    marginTop   : "-8px",
    fontSize    : 15,
    "&.MuiInputLabel-shrink" : {
      padding       : "2px 8px",
      marginTop     : "-3px",
      background    : "white",
      color         : theme.palette.grey[400],
      fontSize      : 18,
      fontWeight    : 400
  },
    ...statusBackgrounds(theme),
  },
  inputTextField  : {
    background  : theme.palette.grey[200],
    "& input, textarea"   : {
      fontSize    : 15,
    },
  },
  inputOptions  : {
    marginLeft    : theme.spacing(4),
    marginTop     : theme.spacing(1),
  },
  radioFormLabel   : {
    fontSize      : 14,
    marginBottom  : theme.spacing(1),
    borderBottom  : "none",
  },
  radioItem   : {
    "& .MuiFormControlLabel-label" : {
      fontSize    : 14,
    },
    "& .MuiButtonBase-root"   : {
      padding   : `0 ${theme.spacing(1)}`, //`
    }
  },
  comboItems  : {
    marginLeft  : theme.spacing(2),
  },
}));

function PrintHeader({checklist, classes}){
  if(!checklist) return null;

  return (
    <Grid id="checklist-print-header" container className={classes.header}>
      <Grid container justifyContent="space-between">
        <Typography className={classes.name}>{checklist.name}</Typography>
        <Typography className={classes.description}>Press CTRL-P to print</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.description}>{checklist.description}</Typography>
      </Grid>
    </Grid>
  );
}

function PrintSection({sectionId, index, classes, cx}){
  const section     = useSelector(state => selectWorkingSection(state, sectionId));
  if(!section) return null;

  let counter     = 1;
  return (
    <Grid name="checklist-print-section" container className={classes.section}>
      <Grid container className={classes.sectionNameGrid}>
        <Typography className={classes.sectionName}>{index}. {section.sectionName}</Typography>
      </Grid>

      {
        _.map(section.items, item => <PrintAssertion key={item.id} assertionId={item.id} index={index} subIndex={counter++} classes={classes} cx={cx} />)
      }
    </Grid>
  );
}

function PrintAssertion({assertionId, index, subIndex, classes, cx}){
  const assertion     = useSelector(state => selectWorkingItem(state, assertionId));
  if(!assertion) return null;
  
  const hasOtherInputs  = !!assertion.textInput || !!assertion.optInput || !!assertion.cmtInput;
  return (
    <Grid name="checklist-print-assertion" container className={classes.assertion}>
      <Grid item xs={8}>
        <Typography className={classes.assertionText}>{index}.{subIndex} {assertion.assertionText}</Typography>
      </Grid>
      <Grid item xs={4}>
        <PrintBoolInput input={assertion.boolInput} classes={classes} cx={cx} />
      </Grid>
      {hasOtherInputs && 
        <Grid container className={classes.inputGrid}>
          <Grid item xs={8}>
            <PrintTextInput input={assertion.textInput} classes={classes} cx={cx} />
          </Grid>
          <Grid item xs={8}>
            <PrintOptionInput input={assertion.optInput} classes={classes} cx={cx} />
          </Grid>
        </Grid>
      }
    </Grid>
  );
}

function PrintBoolInput({input, classes, cx}){
  if(!input) return null;
  
  const cLabel  = input.props?.confirmLabel || "confirm";
  const rLabel  = input.props?.rejectLabel  || "reject";

  return (
    <Grid name="checklist-print-input" container className={classes.input} justifyContent="flex-end">
      {input.allowNA && <Chip className={cx([classes.inputChip, "na"])} label="N/A"/>}
      <Chip className={cx([classes.inputChip, "confirm"])} label={cLabel}/>
      <Chip className={cx([classes.inputChip, "reject"])} label={rLabel}/>
    </Grid>
  );
}

function PrintTextInput({input, classes, cx}){
  if(!input) return null;
  const multiProps  = input.textMultiLine === true ? {
    multiline   : true,
    rows        : 3,
  } : null;

  return (
    <Grid name="checklist-print-input-text" container className={classes.inputText}>
      <FormControl id={input.id} variant="outlined" className={classes.inputFormControl} fullWidth disabled>
        <InputLabel className={cx(classes.textInputLabel)}>{input.caption}</InputLabel>
        <OutlinedInput type="text" margin="dense" notched={true} className={classes.inputTextField} {...multiProps} title={input.toolTip} fullWidth disabled/>
      </FormControl>    
    </Grid>
  );
}

function PrintOptionInput({input, classes, cx}){
  if(!input) return null;

  return (
    <>
      {input.optionType === 0 && <PrintRadioInput input={input} classes={classes} />}
      {input.optionType === 1 && <PrintChecksInput input={input} classes={classes} />}
      {input.optionType === 2 && <PrintSelectInput input={input} classes={classes} cx={cx} />}
    </>
  ); 
}

function PrintRadioInput({input, classes}){
  if(!input) return null;
  const choices   = JSON.parse(input.propertyBag.choices);

  return (
    <Grid name="checklist-print-input-radio" container className={classes.inputOptions}>
      <Grid container>
        <FormLabel id={"label-" + input.id} className={classes.radioFormLabel}>{input.caption}</FormLabel>
      </Grid>
      <Grid container>
        <FormControl id={input.id} variant="outlined" className={classes.inputFormControl} fullWidth disabled>
          <RadioGroup  id={"input-" + input.id} margin="dense" className={classes.radioGroup} disabled>
            {_.map(choices, c => <FormControlLabel key={c} checked={false} className={classes.radioItem} disabled={true} control={<Radio size="small" />} label={c} />)}
          </RadioGroup> 
        </FormControl>    
      </Grid>
    </Grid>
  );
}

function PrintChecksInput({input, classes}){
  if(!input) return null;
  const choices   = JSON.parse(input.propertyBag.choices);

  return (
    <Grid name="checklist-print-input-checks" container className={classes.inputOptions}>
      <Grid container>
        <FormLabel id={"label-" + input.id} className={classes.radioFormLabel}>{input.caption} <em>(multi-select)</em></FormLabel>
      </Grid>
      <Grid container>
        <FormControl id={input.id} variant="outlined" className={classes.inputFormControl} fullWidth disabled>
          <RadioGroup  id={"input-" + input.id} margin="dense" className={classes.radioGroup} disabled>
            {_.map(choices, c => <FormControlLabel key={c} checked={false} className={classes.radioItem} disabled={true} control={<Checkbox size="small" />} label={c} />)}
          </RadioGroup> 
        </FormControl>    
      </Grid>
    </Grid>
  );
}

function PrintSelectInput({input, classes, cx}){
  if(!input) return null;
  const choices   = JSON.parse(input.propertyBag.choices);

  return (
    <Grid name="checklist-print-input-checks" container className={classes.inputOptions}>
      <Grid container>
        <FormControl variant="outlined" className={classes.inputFormControl} fullWidth disabled>
          <InputLabel className={cx(classes.textInputLabel)}>{input.caption}</InputLabel>
          <Select value="" margin="dense" notched={true} className={classes.inputTextField} title={input.toolTip} fullWidth disabled>
            <MenuItem value="">Empty</MenuItem>
          </Select>
        </FormControl>  
      </Grid>
      <Grid container className={classes.comboItems}>
        <FormControl id={input.id} variant="outlined" className={classes.inputFormControl} fullWidth disabled>
          <RadioGroup  id={"input-" + input.id} margin="dense" className={classes.radioGroup} disabled>
            {_.map(choices, c => <FormControlLabel key={c} checked={false} className={classes.radioItem} disabled={true} label={c} control={<Typography />} />)}
          </RadioGroup> 
        </FormControl>    
      </Grid>
    </Grid>
  );
}