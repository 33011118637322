import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { WaitContainer } from 'features/common';
import { loadDocument } from 'store/actions/document-actions';
import DocumentHeader from './document-header';
import DocumentBody from './document-body';
import { useAppParams, useCurrentDocument, useCurrentReviewSet } from 'hooks';
import ErrorDisplay from '../../common/error-display';
import { NotFound } from '../../common';
import { loadSet } from 'store/actions/reviewset-actions';

function DocumentView(){
  const { classes }         = buildStyles();
  const dispatch        = useDispatch();
  const { reviewsetId, documentId, engagementId } = useAppParams();
  const isLoading       = useSelector(state => state.doc.isLoading);
  const set = useCurrentReviewSet();
  const doc = useCurrentDocument();
  const isSetNeeded = needSet(set, isLoading, reviewsetId);
  const isNeeded = needDocument(doc, isLoading, reviewsetId, documentId);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadDoc(){
      const result = await dispatch(loadDocument(documentId));
      if(!result.ok){
        if(result.response?.data?.status === 404){
          setError(404);
        }
        else{
          setError(result);
        }
      }

      return result;
    }

    async function loadSetAndDoc(){
      const resultSet = await dispatch(loadSet(reviewsetId, engagementId));
      const resultDoc = await dispatch(loadDocument(documentId));
      
      if(!resultSet.ok || !resultDoc.ok){
        if(resultSet.response?.data?.status === 404 || resultDoc.response?.data?.status === 404){
          setError(404);
        }
        else{
          setError(!resultSet.ok ? resultSet : resultDoc);
        }
      }

      return resultDoc;
    }

    if(isSetNeeded && isNeeded && reviewsetId && documentId){
      loadSetAndDoc();
    }
    else if(isNeeded && documentId){
      loadDoc();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, reviewsetId])
  
  return (
      <Grid id="document-view" container spacing={2} justifyContent="center" className={classes.root}>
        <Helmet>
          <title>{doc ? `Document: ${doc.documentKey}` : "working..."}</title>
        </Helmet>

        {error && error !== 404 && <ErrorDisplay error={error} />}
        {error && error === 404 && <NotFound /> }

        <WaitContainer isWaiting={!doc || isNeeded} message="Loading document..." variant="circular">
          {!error && 
            <>
              <DocumentHeader doc={doc} />
              <DocumentBody doc={doc} />
            </>
          }
        </WaitContainer>

      </Grid>
  );
}

export default DocumentView;

const buildStyles   = makeStyles()(theme => ({
  root  : {

  },

}));

function needDocument(doc, isLoading, reviewSetId, documentId){
  //Don't have the doc
  if(!isLoading){
    if(!doc) {
      return true;
    }
    //In addition to doc id, need to validate that there is a current review record, otherwise, need to reload to get latest list of review records
    else if(doc && (doc.id !== documentId || !doc.currentReviewRecord)) {
      return true;
    }
  }
  
  return false;
}

function needSet(set, isLoading, reviewSetId){
  //Don't have the doc
  if(!isLoading){
    if(!set) {
      return true;
    }
    else if(set && (set.id !== reviewSetId)) {
      return true;
    }
  }
  
  return false;
}