import React from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CompareArrows from '@mui/icons-material/CompareArrows';
import SettingsApplications from '@mui/icons-material/SettingsApplications';
import { appActions } from 'store/actions/app-actions';
import Loading from 'features/common/loading';
import ErrorDisplay from 'features/common/error-display';
class TestingView extends React.Component{

  render(){
    const { isWorking, error, lastHeartbeat, settings, classes }   = this.props;

    return (
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item container justifyContent="center" alignItems="center">
          <Grid item container xs={6}>
            <table className={classes.actionsTable}>
              <tbody>
                <tr>
                  <td className="action-col">
                    <IconButton size="small" color="inherit" onClick={this.props.heartbeat} title="Test the application heartbeat">
                      <CompareArrows style={{fontSize: 24}}/>
                    </IconButton>
                  </td>
                  <td>
                    Heartbeat
                  </td>
                  <td className="result-col">
                    {lastHeartbeat}
                  </td>
                </tr>
                <tr>
                  <td>
                    <IconButton size="small" color="inherit" onClick={this.props.getSettings} title="Test the application heartbeat">
                      <SettingsApplications style={{fontSize: 24}}/>
                    </IconButton>
                  </td>
                  <td>
                    Settings
                  </td>
                  <td>
                    {settings ? "Hello " + settings.user.displayName : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>

        <Grid item container className={classes.loadingContainer} justifyContent="center">
          <Loading isVisible={isWorking} message="Working..."/>
        </Grid>

        <ErrorDisplay error={error}/>

      </Grid>
    );
  }

}

const mapState = (state, myProps) => {
  return {
    lastHeartbeat   : state.app.lastHeartbeat,
    settings        : state.app.settings,
    error           : state.app.error,
    isWorking       : state.app.isWorking || state.app.isSettingsLoading,

  };
}

export default connect(mapState, appActions)(TestingView);

