import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { LinearProgress, TextField } from '@mui/material';
import { WaitButton } from 'features/common';
import { loadMoveTargets } from 'store/actions/documents-actions';
import { filter, sortBy } from 'lodash';
import { useQuery } from 'hooks';
import { IReviewSet } from 'app-types';
import { RsvTr, RsvTd, RsvRowContainer, RsvActionsCol } from 'features/common/table';

export interface IDocListItemMovingProps {
  doc: any;
  onMove: (doc: any, targetSetId: number) => void;
  onCancel: VoidFunction;
}

///---- the display label for the move to reviewset options
const LABEL_FUNC    = (opt: IReviewSet) => `${opt.title} (${opt.id})`;

const DocumentListItemMoving = ({doc, onMove, onCancel}: IDocListItemMovingProps) => {
  const { classes, cx }     = buildStyles();
  const dispatch = useDispatch();
  const { reviewsetId }       = useQuery();
  const isMoveWorking = useSelector((state: any) => state.docs.isMoveWorking);  
  const [moveTargets, setMoveTargets] = useState<IReviewSet[] | null>(null);   //TODO: how often will this be used? Should I move this to the redux store and key off Engagement ID?
  const [selectedTarget, setSelectedTarget] = useState<IReviewSet | null>(null);
  const filterOptions   = useMemo(() => { return createFilterOptions({ stringify: LABEL_FUNC }); }, []);

  //Initialize the component with the reviewsets this document can be moved to
  useEffect(() => {
    async function doEffect(){
      const targets: any = await dispatch(loadMoveTargets(doc.id));
      const sets = targets.data as IReviewSet[];
      //Note: the server filters out non-pending items
      const options   = sortBy(filter(sets, rs => rs.id !== reviewsetId), rs => rs.title);  //filter out the current reviewset
      setMoveTargets(options);
    }

    if(!isMoveWorking && moveTargets === null){
      doEffect();
    }
  }, [dispatch, doc, isMoveWorking, moveTargets, reviewsetId]);
  
  const doMove = async () => {
    if(selectedTarget && selectedTarget.id){
      onMove(doc, selectedTarget.id);
    }
  }

  return (
    <RsvTr className={classes.moveCol}>
      <RsvRowContainer>
        <RsvTd colSpan={3} className={classes.moveCol}>
          <Grid container direction="column">
            <Typography className={classes.message}>Select the ReviewSet where you'd like to move document <span className={classes.italic}>{doc.documentKey} - {doc.documentTypeName}</span> and press Continue</Typography>
            <Autocomplete id="setId" className={classes.setSelect}
              disabled={!moveTargets || isMoveWorking}
              value={selectedTarget} onChange={(e, value) => setSelectedTarget(value as IReviewSet)}
              options={moveTargets || []} 
              filterOptions={filterOptions}
              getOptionLabel={LABEL_FUNC}
              renderInput={params => <TextField {...params} label="Choose a ReviewSet" />}
              />
          </Grid>
        </RsvTd>
      </RsvRowContainer>
      <RsvActionsCol colSpan={2} className={cx(classes.moveCol, classes.moveActions)}>
        <Grid container alignItems="center" justifyContent="flex-end">
          {isMoveWorking && <LinearProgress className={classes.moveProgress} />}
          <WaitButton variant="outlined" size="small" color="secondary" className={classes.btn} onClick={doMove} disabled={isMoveWorking} isWaiting={false}>Continue</WaitButton>
          <Button variant="outlined" size="small" color="inherit" className={classes.btn} disabled={isMoveWorking} onClick={onCancel}>Cancel</Button>
        </Grid>
      </RsvActionsCol>
    </RsvTr>
  );
}

export default DocumentListItemMoving;

const buildStyles = makeStyles()((theme: any) => ({
  moveCol   : {
    backgroundColor   : "#fff59d",
    verticalAlign     : "middle !important",   
  },
  moveProgress : {
    minWidth: 125,
    marginRight: theme.spacing(1),
  },
  btn   : {
    padding       : theme.spacing(0.5),
    marginRight   : theme.spacing(0.5),
  },
  moveActions  : {
    textAlign         : "right",
    "& .MuiButton-label"  : {
      fontSize        : "0.75rem",
    }
  },
  primaryRr: {
    fontWeight: 700,
  },
  secondaryRr: {
    fontWeight: 500,
  },
  message: {
    fontSize: "1rem",
  },
  italic: {
    fontStyle: "italic",
  },
  setSelect: {
    width: "70%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  }
}));