import _ from 'lodash';
import { handleResultAsync } from './quanta-api';



export function replaceAt(array, index, value){
    const ret = array.slice(0);
    ret[index] = value;
    return ret;
}

export function replaceItem(array, item, value){
  const index   = _.findIndex(array, item);
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

export function addOrReplace(array, predicate, value){
  const index   = predicate ? _.findIndex(array, i => predicate(i)) : -1;
  if(index >= 0){
    return replaceAt(array, index, value);
  }
  else{
    return [...array, value];
  }
}

export function addOrReplaceItem(array, item, value){
  const index   = item ? _.findIndex(array, item) : -1;
  if(index >= 0){
    return replaceAt(array, index, value);
  }
  else{
    return [...array, value];
  }
}

export function removeItem(array, itemOrPredicate){
  const isFunc  = _.isFunction(itemOrPredicate);
  const index   = isFunc ? _.findIndex(array, itemOrPredicate) : _.indexOf(array, itemOrPredicate);
  if(index >= 0){    
    return array.length === 1 ? [] : [...array.slice(0,index), ...array.slice(index + 1)];
  }
  else{
    return array;
  }
}

//This function will return an ActionWrapper function that can be used to automatically handle the status
// for an operation and reducer
export function getActionWrapper(statusAction, noOpAction){
  const theWrapper = (dispatch) => async (action, statusKey, actionType, otherProps) => {
    try{
      dispatch({type: statusAction, key: statusKey, value: {isWorking: true, error: null}});
  
      const response  = await action();
  
      if(response.ok){
        dispatch({type: statusAction, key: statusKey, value: {isWorking: false, error: null}});
        const reducerResult = await handleResultAsync(dispatch, response, actionType, noOpAction, otherProps);  
        if(reducerResult.ok) return reducerResult;
        else {
          //Something bad happened in the reducer, so let's update the status to indicate there's an error
          await dispatch({type: statusAction, key: statusKey, value: {isWorking: false, error: reducerResult.error}});
          return reducerResult;
        }
      }
      else{
        return await dispatch({type: statusAction, key: statusKey, value: {isWorking: false, error: response}});
      }
    }
    catch(ex){
      return await dispatch({type: statusAction, key: statusKey, value: { isWorking: false, error: ex}});
    }
  };

  return theWrapper;
}

export const NO_OP   = "NO_OP";

