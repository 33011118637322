import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import apiMiddleware from "./api-middleware/api-middleware";

const configureMiddleware = () => {
  const logger  = createLogger({collapsed : true});

  const middleware = [
    thunk,
    apiMiddleware,
    // routerMiddleware(history),
    logger
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const middlewares   = composeWithDevTools(applyMiddleware(...middleware), ...enhancers);
  return middlewares;
}

export default configureMiddleware;