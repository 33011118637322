import React, { useCallback, useState } from "react";
import { toastr } from "react-redux-toastr";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { getQuantaApi } from "utils/action-helpers"
import { TouchApp } from "@mui/icons-material";

const ReportQueuePoke = () => {
  const [isWorking, setWorking] = useState(false);
  const api = getQuantaApi();

  const pokeQueue = useCallback(async () => {
    setWorking(true);
    const path = "/poke";
    const rsp  = await api.ReportQueue.get(path);
    if(rsp.ok){
      toastr.success("Success", "The Report Queue has been poked.");
    }
    else{
      toastr.error("Error", `Failed to poke the Report Queue. ${rsp.status} ${rsp.problem}`);
    }
    setWorking(false);

  }, [api]);

  return (
    <>
      {isWorking && (
        <CircularProgress color="primary" sx={{height: "35px !important", width: "35px !important"}} />
      )}
      {!isWorking && (
        <Tooltip title="Click here to 'Poke' the azure queue, which will begin processing any queued items.">
          <IconButton onClick={pokeQueue}>
            <TouchApp color="primary"/>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default ReportQueuePoke;