import { combineReducers } from 'redux';
import appReducer from './app-reducer';
import settingsReducer from './settings-reducer';
import setsReducer from './reviewsets-reducer';
import setReducer from './reviewset-reducer';
import docsReducer from './documents-reducer';
import docReducer from './document-reducer';
import checkListItemReducer from './checklist-item-reducer';
import reportsReducer from './reports-reducer';
import metricsReducer from './metrics-reducer';
import adminReducer from './admin-reducer';
import builderReducer from './builder-reducer';
import statusSlice from "../api-middleware/status-reducer";

import { reducer as toastrReducer } from 'react-redux-toastr';

export default function configureReducers(preloadedState){

    let reducers = {
        app             : appReducer,
        settings        : settingsReducer,
        sets            : setsReducer,
        set             : setReducer,
        docs            : docsReducer,
        doc             : docReducer,
        clitems         : checkListItemReducer,
        reports         : reportsReducer,
        metrics         : metricsReducer,
        admin           : adminReducer,
        builder         : builderReducer,
        status          : statusSlice,
        toastr          : toastrReducer,
    };

    if(preloadedState){
        reducers = {
            ...reducers,
            ...preloadedState,
        };
    }

    const rootReducer = combineReducers({
        ...reducers,
    });

    return rootReducer;
}