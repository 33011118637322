import React from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AssertionItem } from './';
import { IfBlock } from 'features/common';
import { filterCheckList } from 'store/actions/document-actions';

export default function SectionItem({item, isReadOnly}){
  const { classes, cx }     = buildStyles();
  const dispatch    = useDispatch();
  const filter      = useSelector(state => state.doc.checkListFilter);
  const isCollapsed = !!filter.collapsed[item.id];
  const hasKids     = Boolean(item.kids && item.kids.length > 0);

  const onToggle = (e) => {
    const newVal  = {
      ...filter,
      collapsed   : {
        ...filter.collapsed,
        [item.id]   : !isCollapsed,
      }
    };

    dispatch(filterCheckList(newVal));
  }

  return (
    <Card id={"section-" + item.id} elevation={0} variant="outlined" className={classes.sectionCard}>
      <Grid item container alignItems="center" className={classes.section}>
        <Grid item container className={classes.sectionHeader}>
          <Grid item xs={10}>
            <Typography className={classes.sectionTitle}>{item.key}. {item.sectionName}</Typography>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end" alignContent="flex-start">
            <IfBlock condition={filter.open && hasKids}>
              <IconButton onClick={onToggle} size="small" title="Toggle this section">{isCollapsed ? <DownIcon /> : <UpIcon />}</IconButton>
            </IfBlock>
          </Grid>
        </Grid>
        {hasKids && 
          <Grid item container className={cx(classes.sectionBody, {[classes.sectionBodyCollapsed]: isCollapsed})}>
            {_.map(item.kids, id => <AssertionItem key={id} itemId={id} filter={filter} isReadOnly={isReadOnly}/>)}
          </Grid>
        }
      </Grid>
    </Card>
  );
}

const buildStyles   = makeStyles()(theme => ({
  sectionCard  : {
    width   : "100%",
    // height  : "100%",
    padding : theme.spacing(1),
    marginBottom  : theme.spacing(1),
  },
  sectionHeader : {
    // borderBottom  : `1px solid ${theme.palette.grey[800]}`,
    marginBottom  : theme.spacing(1),
  },
  sectionTitle  : {
    fontSize      : 20,
    fontWeight    : 500,
    paddingBottom   : theme.spacing(1),

  },
  sectionDescription  : {
    fontSize      : 16,
    fontWeight    : 300,
  },
  sectionBody : {
    paddingLeft     : theme.spacing(1),
  },
  sectionBodyCollapsed : {
    padding         : theme.spacing(2),
    marginTop       : "-24px",
  }
}));