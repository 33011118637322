import _, { without } from 'lodash';
import { DOCS_ACTIONS } from '../actions/documents-actions';
import { createReducer } from 'utils/reducer-helper';

const INITIAL_STATE = {
  error               : null,
  // isLoading           : false,
  isWorking           : false,
  items               : null,
  itemsStatus         : {
    isWorking       : false,
    pagination      : null,
    error           : null,
    query           : null,
    isEmpty         : false,
  },

  existingFilter      : null,
  existingItems       : null,
  existingIsWorking   : false,
  existingError       : null,

  isMoveWorking      : false,

  isCreating          : false,
  createError         : null,

  isRemoving          : false,
  removeError         : null,
};

function itemsLoading(state, action){
  return {
    ...state,
    items       : null,
    itemsStatus   : {
      isWorking     : true,
      pagination    : null,
      error         : null,
      isEmpty       : false,
    },
  };
}

function itemsLoaded(state, action){
  const items   = _.isArray(action.response) ? action.response : null;
  if(!items) return error(state, action);

  return {
    ...state,
    items           : action.response,
    itemsStatus     : {
      isWorking       : false,
      error           : null,
      pagination      : action.pagination,
      isEmpty         : Boolean(!action.response || action.response.length === 0),
    },
  }
}

function error(state, action){
  return {
    ...state,
    items             : null,
    itemsStatus       : {
      isWorking         : false,
      pagination        : null,
      error             : action.response,
    },
  };
}

function itemsExporting(state, action){
  return {
    ...state,
    itemsStatus: {
      ...state.itemsStatus,
      isWorking   : true,
      error       : null,
    }
  };
}

function itemsExported(state, action){
  return {
    ...state,
    itemsStatus: {
      ...state.itemsStatus,
      isWorking: false,
      error: null,
    }
  };
}

function exportError(state, action){
  return {
    ...state,
    itemsStatus: {
      ...state.itemsStatus,
      isWorking: false,
      error: action.error,
    }
  };
}

function existingLoading(state, action){
  return {
    ...state,
    existingIsWorking   : true,
    existingError       : null,
  };
}

function existingLoaded(state, action){
  return {
    ...state,
    existingIsWorking   : false,
    existingError       : null,
    existingItems       : action.response,
    existingFilter      : action.filter,
  };
}


function existingError(state, action){
  return {
    ...state,
    existingIsWorking : false,
    existingError     : action.response,
    existingItems     : null,
    existingFilter    : null,
  };
}

function creating(state, action){
  return {
    ...state,
    createError       : null,
    isCreating        : true,
  };
}

function created(state, action){
  const existingItems = state.existingItems ? [...state.existingItems] : null;
  if(existingItems){
    let existing = existingItems.find(ex => ex.id === action.doc.id);
    if(!existing){
      existing = {...action.doc}; //create a copy
      existingItems.push(existing);
    }

    existing.reviewRecords = action.reviewRecords;
  }
  const newItems  = [action.doc, ...state.items];
  
  return {
    ...state,
    isCreating        : false,
    createError       : null,
    items             : newItems,           //Add the new item
    existingItems     : existingItems,      //update the existing items array
    itemsStatus        : {
      ...state.itemsStatus,
      isEmpty         : false,
    }
  };
}

function createError(state, action){
  return {
    ...state,
    isCreating        : false,
    createError       : action.response,
  }
}

function clearCreateError(state, action){
  return {
    ...state,
    createError       : null,
  };
}

function removing(state, action){
  return {
    ...state,
    isRemoving        : true,
  };
}

function removed(state, action){
  
  const removedItem   = state.items.find(i => i.reviewRecordId === action.reviewRecordId);
  const newItems      = without(state.items, removedItem);

  //modify the existingItems array, if necessary. Need to modify the document to remove the deleted reviewRecord
  const existingItems = state.existingItems ? [...state.existingItems] : null;
  if(existingItems){
    let existingDoc = existingItems.find(ex => ex.id === action.docId);
    if(existingDoc){
      const toRemoveIndex = existingDoc.reviewRecords.findIndex(rr => rr.id === action.reviewRecordId);
      if(toRemoveIndex >= 0){
        const toRemove = existingDoc.reviewRecords[toRemoveIndex];
        const replacement = {
          ...existingDoc,
          reviewRecords: without(existingDoc.reviewRecords, toRemove),
        };
        existingItems.splice(toRemoveIndex, 1, replacement);
      }      
    }
  }

  return {
    ...state,
    isRemoving        : false,
    removeError       : null,
    items             : newItems,           //remove the item
    itemsStatus        : {
      ...state.itemsStatus,
      isEmpty         : Boolean(newItems.length === 0),
    },
    existingItems,
  };
}

function removeError(state, action){
  return {
    ...state,
    isRemoving        : false,
    removeError       : action.response,
  }
}

function toggleMoveTargets(state, action){
  return {
    ...state,
    isMoveWorking: !state.isMoveWorking,
  };
}

function docMoving(state, action){
  return {
    ...state,
    isMoveWorking: true,
  };
}
function docMoved(state, action){
  const movedItem   = _.find(state.items, i => i.reviewRecordId === action.reviewRecordId);
  const newItems      = _.without(state.items, movedItem);

  return {
    ...state,
    isMoveWorking: false,
    moveError: null,
    items: newItems,           //remove the item
    itemsStatus: {
      ...state.itemsStatus,
      isEmpty         : Boolean(newItems.length === 0),
    }
  };
}
function docMoveError(state, action){
  return {
    ...state,
    isMoveWorking: false,
    moveError: action.response,
  }
}

//----------------------
// The action map that is turned into the reducer
const docsReducer    = {

  [DOCS_ACTIONS.ITEMS_LOADING]     : itemsLoading,
  [DOCS_ACTIONS.ITEMS_LOADED]      : itemsLoaded,
  [DOCS_ACTIONS.ITEMS_ERROR]       : error,

  [DOCS_ACTIONS.ITEMS_EXPORTING]     : itemsExporting,
  [DOCS_ACTIONS.ITEMS_EXPORTED]      : itemsExported,
  [DOCS_ACTIONS.EXPORT_ERROR]       : exportError,

  [DOCS_ACTIONS.EXIST_LOADING]    : existingLoading,
  [DOCS_ACTIONS.EXIST_LOADED]     : existingLoaded,
  [DOCS_ACTIONS.EXIST_ERROR]      : existingError,

  [DOCS_ACTIONS.CREATING]         : creating,
  [DOCS_ACTIONS.CREATED]          : created,
  [DOCS_ACTIONS.CREATE_ERROR]     : createError,
  [DOCS_ACTIONS.CREAT_ERROR_CLEAR]: clearCreateError,

  [DOCS_ACTIONS.REMOVING]         : removing,
  [DOCS_ACTIONS.REMOVED]          : removed,
  [DOCS_ACTIONS.REMOVE_ERROR]     : removeError,

  [DOCS_ACTIONS.MOVE_TARGETS_LOADING] : toggleMoveTargets,
  [DOCS_ACTIONS.MOVE_TARGETS_LOADED]  : toggleMoveTargets,

  [DOCS_ACTIONS.ITEM_MOVING]: docMoving,
  [DOCS_ACTIONS.ITEM_MOVED]: docMoved,
  [DOCS_ACTIONS.ITEM_MOVE_ERROR]: docMoveError,
};

//The reducer
export default createReducer(INITIAL_STATE, docsReducer);