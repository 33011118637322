import _ from 'lodash';
import Apis from '../quanta-api';
import { getActionWrapper, replaceItem } from '../store-helpers';
import { isSection, defaultAssertion, defaultSection } from 'utils/checklist-helpers';
import { INITIALIZED } from 'utils/reducer-helper';

const NO_OP   = "NO_OP";
export const BUILDER_ACTIONS  = {
  STATUS_CHANGE     : "BUILDER_STATUS_CHANGE",
  CHECKLIST_LOADED  : "CHECKLIST_LOADED",  
  CHECKLIST_STARTED : "CHECKLIST_STARTED",
  CHECKLIST_SAVED   : "CHECKLIST_SAVED",
  CHECKLIST_CREATED : "CHECKLIST_CREATED",
  CHECKLIST_RELOADED: "CHECKLIST_RELOADED",

  UPDATE_WORKING    : "WORKING_UPDATED",
  SECTION_UPDATED   : "WORKING_SECTION_UPDATED",
  SECTION_TOGGLED   : "SECTION_TOGGLED",
  ITEM_UPDATED      : "WORKING_ITEM_UPDATED",
  SECTION_MOVED     : "WORKING_SECTION_MOVED",
  ITEM_MOVED        : "WORKING_ITEM_MOVED",
  SECTION_INSERTED  : "WORKING_SECTION_INSERTED",
  ITEM_INSERTED     : "WORKING_ITEM_INSERTED",
};

//#region Status handling functionality
const actionWrapper   = getActionWrapper(BUILDER_ACTIONS.STATUS_CHANGE, NO_OP);

export const clearStatus = (statusKey) => async(dispatch, getState) => {
  return dispatch({type: BUILDER_ACTIONS.STATUS_CHANGE, key: statusKey, value: {...INITIALIZED}});
}
//#endregion

export const loadChecklist = (id) => async(dispatch, getState) => {
  return await actionWrapper(dispatch)(async() => {
    return await Apis.Checklists.get(`/${id}`);  //`
  }, "checklists", BUILDER_ACTIONS.CHECKLIST_LOADED);
}

export const startChecklist = (docTypeId) => async(dispatch, getState) => {
  return dispatch({
    type           : BUILDER_ACTIONS.CHECKLIST_STARTED,
    documentTypeId : docTypeId,
  });
}

export const updateWorking = (id, changes) => async(dispatch, getState) => {
  return dispatch({
    type    : BUILDER_ACTIONS.UPDATE_WORKING,
    data    : changes,
  });
}

export const updateSection = (sectionId, changes) => async(dispatch, getState) => {
  const items  = getState().builder.working.checklistItems;
  const original  = _.find(items, s => s.id === sectionId);
  const update    = {...original, ...changes};
  
  const edits   = {
    checklistItems    : replaceItem(items, original, update),
  };

  return dispatch({
    type    : BUILDER_ACTIONS.SECTION_UPDATED,
    ...edits,
    // data    : edits,
  });
}

export const toggleSection = (sectionId, isExpanded) => async(dispatch, getState) => {
  const items     = getState().builder.working.checklistItems;
  const original  = _.find(items, i => i.id === sectionId);
  if(original.isExpanded === isExpanded) return null;   //nothing to do here
  const update    = {...original, isExpanded: isExpanded};

  return dispatch({
    type    : BUILDER_ACTIONS.SECTION_TOGGLED,
    data    : {
      checklistItems  : replaceItem(items, original, update),
    },
  });
}

export const updateItem = (itemId, changes) => async(dispatch, getState) => {
  const items  = getState().builder.working.checklistItems;
  const original  = _.find(items, s => s.id === itemId);
  const update    = {...original, ...changes};
  
  const edits   = {
    checklistItems   : replaceItem(items, original, update),
  };

  return dispatch({
    type    : BUILDER_ACTIONS.ITEM_UPDATED,
    ...edits,
    // data    : edits,
  });
}

export const moveSection = (sectionId, offset) => async(dispatch, getState) => {
  const items     = getState().builder.working.checklistItems;
  const changes   = doMove(items, sectionId, offset);
  if(!changes) return null;
  
  return dispatch({
    type    : BUILDER_ACTIONS.SECTION_MOVED,
    checklistItems  : changes,
    // data    : { checklistItems : changes },
  });
}

export const moveItem = (itemId, offset) => async(dispatch, getState) => {
  const items   = getState().builder.working.checklistItems;
  const changes = doMove(items, itemId, offset);
  if(!changes) return null;

  return dispatch({
    type    : BUILDER_ACTIONS.ITEM_MOVED,
    checklistItems  : changes,
    // data    : { checklistItems : changes },
  });  
}

export const insertSection = (sectionId, offset) => async(dispatch, getState) => {
  const working   = getState().builder.working;
  const items     = working.checklistItems;
  const newSec    = {...defaultSection(null)};    //id will be set by the doInsert method
  // let newSection  = {
  //   discriminator       : 'Section',
  //   sectionName         : null,
  //   sectionDescription  : null,
  //   parentId            : null,
  //   checkListInputs     : [],
  //   isExpanded          : true,
  // };

  const changes   = doInsert(items, newSec, sectionId, offset);
  
  return dispatch({
    type    : BUILDER_ACTIONS.SECTION_INSERTED,
    checklistItems  : changes,
  });
}

export const insertItem = (parentId, refId, offset) => async(dispatch, getState) => {
  const working   = getState().builder.working;
  const items     = working.checklistItems;
  const newItem   = {...defaultAssertion(parentId, null)};    //id will be set by the doInsert method
  // let newItem  = {
  //   discriminator       : "Assertion",
  //   assertionText       : null,
  //   parentId            : parentId,
  //   checkListInputs     : [
  //     {
  //       discriminator           : 'Bool',
  //       ordinal                 : 0,
  //       booleanAllowFalse       : true,
  //       allowNA                 : false,
  //       toolTip                 : null
  //   }
  //   ],
  // };

  const changes   = doInsert(items, newItem, refId, offset);
  
  return dispatch({
    type    : BUILDER_ACTIONS.ITEM_INSERTED,
    checklistItems  : changes,
  });
}

export const saveCheckList = (id, item) => async(dispatch, getState) => {

  return await actionWrapper(dispatch)(async() => {
    const verb    = id > 0 ? "put" : "post";
    const url     = id > 0 ? `/${id}` : "";

    const result  = await Apis.Checklists[verb](url, item);

    return result;

  }, "checklists", BUILDER_ACTIONS.CHECKLIST_SAVED);

}

export const reloadChecklist = () => async(dispatch, getState) => {
  //This is called when user tries to save when the only changes are "ghosts" - items that were added and removed in the same 
  // session, and therefore don't need to be sent to the server.  This action will just reset the current checklist so that
  // it appears as if those changes have been committed.
  const original  = getState().builder.original;
  //Dispatch reloaded, which will call the "saved" reducer function, so pass the original item to the response prop.
  return dispatch({
    type  : BUILDER_ACTIONS.CHECKLIST_RELOADED,
    response  : original,
  });
}

//#region Helper Functions

function doMove(items, idToMove, offset){
  if(!items || !idToMove) return null;

  const item    = _.find(items, i => i.id === idToMove);
  const myIndex = _.findIndex(items, item);

  if(isSection(item)){
    const sections  = _.orderBy(_.filter(items, isSection), s => s.ordinal);
    const mySectionIndex   = _.findIndex(sections, item);

    //Make sure this move is valid
    if(item && ((offset > 0 && mySectionIndex < sections.length - 1) || (offset < 0 && mySectionIndex > 0))){
      const otherSection  = sections[mySectionIndex + offset];
      const otherIndex    = _.findIndex(items, otherSection);

      const them          = [otherSection, ..._.filter(items, i => i.parentId === otherSection.id)];
      const themCount     = them.length;
      const themLastIndex = otherIndex + themCount -1;

      const us            = [item, ..._.filter(items, i => i.parentId === idToMove)];
      const usCount       = us.length;
      const usLastIndex   = myIndex + usCount -1;

      if(offset === -1){    //up
        const before    = items.slice(0, otherIndex);
        const after     = items.slice(usLastIndex + 1);
        const mes       = _.map(us, itm => { return {...itm, ordinal: itm.ordinal - themCount}});
        const theys     = _.map(them, itm => {return {...itm, ordinal: itm.ordinal + usCount}});
        return [...before, ...mes, ...theys, ...after];
      }
      else{
        const before    = items.slice(0, myIndex);
        const after     = items.slice(themLastIndex + 1);
        const mes       = _.map(us, itm => { return {...itm, ordinal: itm.ordinal + themCount}});
        const theys     = _.map(them, itm => {return {...itm, ordinal: itm.ordinal - usCount}});
        return [...before, ...theys, ...mes, ...after];
      }
    }
  }
  else{
    const otherIndex  = myIndex + offset;
    let displaced     = items[otherIndex];
    if(!displaced || isSection(displaced)){
        return null;   //can't move outside our own section right now...
    }
    else{
      const currOrdinal   = item.ordinal;
      const otherIndex    = _.findIndex(items, displaced);
      const me            = {...item, ordinal: displaced.ordinal};
      const other         = {...displaced, ordinal: currOrdinal};

        if(offset === 1){ //down
            const before    = items.slice(0, myIndex);
            const after     = items.slice(otherIndex + 1);
            return [...before, other, me, ...after];

        }
        else{ //up
            const before    = items.slice(0, otherIndex);
            const after     = items.slice(myIndex + 1);
            return [...before, me, other, ...after];
        }
    }
  }
}

function doInsert(items, item, refId, offset){
  const minIdItem   = _.minBy(items, i => i.id);
  const minId       = minIdItem.id;
  const nextId      = (minId >= 0) ? -1 : minId - 1;
  let toInsert      = {...item, id: nextId};

  if(!refId){
    if(isSection(item)){
      //Add to the bottom of the list
      const lastItem  = _.maxBy(items, i => i.ordinal);
      toInsert        = {...toInsert, ordinal:  lastItem.ordinal + 1};
      // insertIndex     = items.length;
      return [...items, toInsert];
    }
    else{
      if(item.parentId !== null && item.parentId !== undefined){
        //Add to the bottom of the section...
        const section     = _.find(items, i => i.id === item.parentId);
        const secItems    = _.orderBy(_.filter(items, i => i.parentId === item.parentId), ["ordinal"]);
        const otherItem   = (secItems && secItems.length > 0) ? _.last(secItems) : section;
        const otherIndex  = _.findIndex(items, otherItem);
        toInsert          = {...toInsert, ordinal: otherItem.ordinal + 1};
        const before  = items.slice(0, otherIndex + 1);
        const after   = _.map(items.slice(otherIndex + 1), itm => { return {...itm, ordinal: itm.ordinal + 1}});
        return [...before, toInsert, ...after];
  
      }
      else{
        return null;    //invalid, need to insert an assertion after a current insertion
      }
    }
  }
  else{
    if(isSection(item)){
      const otherItem   = getSectionToInsertBefore(items, item, refId, offset);      
      if(otherItem === null){ //get the next section, and insert it before that one...        
        //We're adding after the last section, so just add it to the end of the list
        const ordinal   = _.maxBy(items, i => i.ordinal).ordinal;
        toInsert        = {...toInsert, ordinal: ordinal + 1};
        return [...items, toInsert];
      }
    
      const otherIndex  = _.findIndex(items, otherItem);
      const before      = items.slice(0, otherIndex);
      toInsert          = {...toInsert, ordinal: otherItem.ordinal};
      const after       = _.map(items.slice(otherIndex), itm => {return {...itm, ordinal: itm.ordinal + 1}});
      return [...before, toInsert, ...after];
    }
    else{
      const otherItem   = getAssertionToInsertAfter(items, item, refId, offset);
      const otherIndex  = _.findIndex(items, otherItem);
      toInsert          = {...toInsert, ordinal: otherItem.ordinal + 1};
      const before  = items.slice(0, otherIndex + 1);
      const after   = _.map(items.slice(otherIndex + 1), itm => { return {...itm, ordinal: itm.ordinal + 1}});
      return [...before, toInsert, ...after];
    }
  }
}

//---
// gets the item that we're going to insert the new item BEFORE
function getSectionToInsertBefore(items, item, refId, offset){
  let otherItem   = _.find(items, i => i.id === refId);
  if(isSection(item)){  // Insert Section    
    if(isSection(otherItem)){
      if(offset === -1){
        // Insert before a Section
        return otherItem;
      }
      else{
        // Insert after a section
        // get the next section, so we can insert before it
        const sections    = _.filter(items, isSection);
        const otherIndex  = _.findIndex(sections, otherItem);
        return otherIndex >= sections.length - 1 ? null : sections[otherIndex + 1];
      }
    }
    else{
      // Insert before an Item
      // Insert after an item
    }    
  }
}

// NOTE: if returning a section, means insert at the beginning of the section
function getAssertionToInsertAfter(items, item, refId, offset){
  let otherItem   = _.find(items, i => i.id === refId);
  
  if(!isSection(item)){ //Insert Assertion
    const secItems  = _.filter(items, i => i.parentId === item.parentId);        
    const section   = _.find(items, i => i.id === item.parentId);
    if(isSection(otherItem)){
      if(offset === 1){
        //insert assertion at the end of the section, so return last item
        return secItems[secItems.length - 1]; 
      }
      else{
        //insert assertion at the beginning of the section
        return section;
      }
    }
    else{ //ref item is an assertion
      if(offset === 1){
        //Insert after Assertion
        return otherItem;
      }
      else{
        //before the assertion - get the previous item in this section (if there is one)
        if(secItems.length === 0) return section;  //just add it at the beginning of this section
        const secIndex  = _.findIndex(secItems, otherItem);
        if(secIndex === 0) return section;   //insert it at the beginning
        return secItems[secIndex - 1];
      }
    }
  }
}

//#endregion