import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { toastr } from 'react-redux-toastr';
import { selectStatusByKey } from 'store/selectors/admin-selectors';
import { loadSets } from 'store/actions/reviewsets-actions';
import { adminDialogClasses } from 'features/common/dialog-classes';
import { ErrorDisplay, IfBlock, WaitButton, } from 'features/common';
import { useInputHandler } from 'hooks/general-hooks';
import { useAllEngagements } from 'hooks/admin-hooks';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { queueReviewSetReports } from 'store/actions/admin-actions';
import { normalizeError } from 'utils/general-helpers-ts';

const QueueReportsTask = () => {
  const { classes }   = buildStyles();
  const dispatch  = useDispatch();
  const status        = useSelector(state => selectStatusByKey(state, "queue-reports"));
  const rsWorking     = useSelector(state => state.sets.isLoadingItems);
  const reviewSets    = useSelector(state => state.sets.items);
  const [item, onInputChange, setItem]  = useInputHandler({engId: ""});
  const engagements   = useAllEngagements();
  const [error, setError] = useState(null);
  const isWorking     = status.isWorking || rsWorking; // || isChecking;

  const candidates = useMemo(() => {
    if(reviewSets){
      return reviewSets.filter(set => !set.reportLocation && set.statusCode === 100);
    }
    
    return [];
  }, [reviewSets]);

  //Reset things, and load the ReviewSets if they choose a new engagement
  useEffect(() => {
    if(item.engId){
      //clear things out
      setError(null);
      setItem({engId: item.engId});
      dispatch(loadSets({engagementId: item.engId}, 1, 999));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.engId]);

  //Manual step to verify that this ReviewSet can be deleted.
  async function onQueue(){
    setError(null);
    const result  = await dispatch(queueReviewSetReports(item.engId));
    if(!result.ok){
      const error   = normalizeError(result, "Failed to Queue Reports", "The operation failed, please see below for details.");
      setError(error);
    }
    else{
      toastr.success("Reports successfully queued");
      setItem({engId: item.engId});
    }
  }
  
  return (
    <Grid id="data-view" container justifyContent="center" className={classes.taskRoot}>

        <Grid item container>
          <Grid item xs={6} className={classes.formRow}>
            <FormControl className={classes.formControl} label="Engagement" fullWidth>
                <InputLabel id="label-engid" className={classes.inputLabel}>Select an Engagement</InputLabel>
                <Select name="engId" labelId="label-engid" label="Engagement" value={item.engId} onChange={onInputChange} disabled={isWorking} fullWidth className={classes.selectContainer} classes={{select: classes.select}}  size="small">
                  <MenuItem value="" disabled className={classes.option}><em>Select Engagement</em></MenuItem>
                  {engagements && _.map(engagements, option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.id}. {option.name}</MenuItem>)}              
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <Typography className={classes.instructions}>Only assigned Engagements will show here.</Typography>
          </Grid>
        </Grid>

        <Grid item container>
          <IfBlock condition={rsWorking}>
            <CircularProgress className={classes.progress}/>
          </IfBlock>
          
          <IfBlock condition={item.engId && !rsWorking}>
            <Grid item xs={12} className={classes.formRow}>
              <Typography className={classes.instructions}>Only ReviewSets that are Approved, and do not currently have a ReportLocation will be rendered. The following {candidates.length} ReviewSets will be queued.</Typography>
              <TableContainer component={Paper}>
                <Table variant="">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidates && candidates.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={3}>The selected Engagement does not have any candidate ReviewSets. Please select a different Engagement.</TableCell>
                      </TableRow>
                    )}
                    {candidates && candidates.length >= 250 && (
                      <TableRow>
                        <TableCell colSpan={3}>This Engagement has too many candidate ReviewSets. Please contact the System Administrator for assistance with this Engagement.</TableCell>
                      </TableRow>
                    )}
                    {candidates && candidates.length > 0 && candidates.length < 250 && _.map(candidates, set => (
                      <TableRow key={set.id}>
                        <TableCell>{set.id}</TableCell>
                        <TableCell>{set.title}</TableCell>
                        <TableCell>{set.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </IfBlock>
        </Grid>

        <IfBlock condition={candidates?.length > 0}>

          <Grid item container className={classes.confirmRow}>
            {error && (
              <Grid item container>
                <ErrorDisplay error={error} />
              </Grid>
            )}
            <Grid item container alignItems="center" justifyContent="flex-end">
              <WaitButton variant="outlined" color="primary" isWaiting={status.isWaiting} disabled={isWorking} onClick={onQueue}>Queue Reports</WaitButton>
            </Grid>
          </Grid>

        </IfBlock>

    </Grid>
  );
}

export default QueueReportsTask;

const buildStyles   = makeStyles()(theme => ({
  ...adminDialogClasses(theme),
  waitRoot  : {
    padding     : theme.spacing(1),
  },  
  instructions  : {
    fontSize    : 14,
    fontWeight  : "300",
    padding     : theme.spacing(2),
  },
  progress      : {
    padding     : theme.spacing(1),
    fontSize    : 24,
  },
  previewCard   : {
    width     : "100%",
    // padding   : theme.spacing(2),
    background    : `${theme.palette.info.light}33`,
    borderColor   : theme.palette.info.dark,
    marginBottom  : theme.spacing(1),
  },
  previewLabel  : {
    color     : theme.palette.info.dark,
    fontSize  : 15,
    fontWeight: "400",
  },
  confirmRow  : {
    marginTop     : theme.spacing(2),
  }
}));