import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { CltHidden, Loading } from 'features/common';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { onlyMobile } from 'utils/sx-helpers';

function CltTbody({colSpan, isWorking, isEmpty, workingText, emptyText, noWorkingRow, children}){
  const { classes } = buildStyles();
  const myIsEmpty   = Boolean(!isWorking && (isEmpty || children === null || children?.length === 0));

    return (
      <>
        {/* For Mobile */}
        <CltHidden smUp>
          <Grid container direction="column" spacing={2} sx={onlyMobile()}>
            {!noWorkingRow && isWorking &&
              <Grid container justifyContent="center" alignItems="center" className={classes.paddedItem}>
                <Loading isVisible={isWorking} message={workingText} />
              </Grid>
            }
            {myIsEmpty && 
              <Grid container justifyContent="center" alignItems="center" className={classes.paddedItem}>
                <span className="italic font-italic">{emptyText}</span>
              </Grid>
            }
            {children}
          </Grid>
        </CltHidden>

        {/* For Desktop */}
        <CltHidden smDown>
          <TableBody className={classes.tableBody} sx={stripedTable}>
            {!noWorkingRow && <WorkingRow colSpan={colSpan} isWorking={isWorking} workingText={workingText}/>}
            <EmptyRow colSpan={colSpan} isEmpty={myIsEmpty} emptyText={emptyText}/>
            {children}
          </TableBody>
        </CltHidden>
      </>
    )
}

CltTbody.propTypes  = {
  colSpan       : PropTypes.number,  
  isWorking     : PropTypes.bool,
  isEmpty       : PropTypes.bool,
  workingText   : PropTypes.string,
  emptyText     : PropTypes.string,
};

CltTbody.defaultProps   = {
  colSpan       : 1,
  isWorking     : false,
  isEmpty       : false,
  emptyText     : "There are no items",
  workingText   : "Loading...",
};

const buildStyles   = makeStyles()(theme => ({
  tableBody : {
    "& #empty-row"   : {
      "& td"   : {
        padding     : theme.spacing(3),
        fontSize    : "1.15rem",
      }
    },
    "& #working-row"   : {
      "& td"   : {
        padding     : theme.spacing(3),
        fontSize    : "1.15rem",
      }
    },
  },
  paddedItem: {
    padding: theme.spacing(3),
  }
}));

export default CltTbody;

function EmptyRow({isEmpty, colSpan, emptyText}){
  if(!isEmpty) return null;
  return (
    <TableRow id="empty-row">
      <TableCell colSpan={colSpan}>
        <Grid container spacing={3} justifyItems="center" sx={{pt: 2, px: 2}}>
          <Typography variant="body1" sx={{fontStyle: "italic"}}>{emptyText}</Typography>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

function WorkingRow({isWorking, colSpan, workingText}){
  if(!isWorking) return null;
  return (
    <TableRow id="working-row">
      <TableCell colSpan={colSpan}>
        <Grid container spacing={3} justifyItems="center" sx={{paddingTop: 2}}>
          <Loading isVisible={isWorking} message={workingText} />
        </Grid>
      </TableCell>
    </TableRow>
  );
}

const stripedTable = {
  "& tr:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  // hide last border
  '& tr:last-child td, & tr:last-child th': {
    border: 0,
  },
}