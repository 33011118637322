import Apis, { handleResult } from '../quanta-api';
import { msalLogout, buildMsalResult } from 'utils/auth/auth-utils';

export const APP_ACTIONS  = {
  INITIALIZING        : "APP_INITIALIZING",
  INITIALIZED         : "APP_INITIALIZED",
  INITIALIZE_FAILED   : "APP_INITIALIZE_FAILED",

  LOGGING_IN          : "APP_LOGGING_IN",
  LOGGED_IN           : "APP_LOGGED_IN",
  LOGIN_ERROR         : "APP_LOGIN_ERROR",
  TOKEN_FAILED        : "APP_TOKEN_FAILED",

  LOGGING_OUT         : "APP_LOGGING_OUT",
  LOGGED_OUT          : "APP_LOGGED_OUT",

  SETTINGS_LOADING    : "APP_SETTINGS_LOADING",
  SETTINGS_LOADED     : "APP_SETTINGS_LOADED",

  WORKING             : "APP_WORKING",
  ERROR               : "APP_ERROR",

  HEARTBEAT           : "APP_HEARTBEAT",
};

export const initializeApp  = (serverConfig) => async(dispatch, getState) => {
  const appState  = getState().app;
  if(appState.isInitialized) return null;
  dispatch({type: APP_ACTIONS.INITIALIZED, serverConfig});
}

export const authChanged = (account) => async (dispatch, getState) => {
  const isStateAuthed = getState().app?.isAuthenticated;

  if(!isStateAuthed && account){
    const response = buildMsalResult(account);
    const result = await dispatch({type: APP_ACTIONS.LOGGED_IN, response});
    
    if(result.response.ok && result.response.isAuthenticated){
      return loadSettings(dispatch);
    }
    
    return result;
  }
  else if(isStateAuthed){
    await dispatch({type: APP_ACTIONS.LOGGED_OUT});
  }
}

export const logout = () => async(dispatch, getState) => {
  const appState  = getState().app;
  if(!appState.isAuthenticated) return null;

  dispatch({type: APP_ACTIONS.LOGGING_OUT, status: "Logging Out..."});

  msalLogout();
}

//---------------
// Loads the settings for the app.  Called by the Init or the OnAuthChanged methods
const loadSettings = async (dispatch) => {
  dispatch({type: APP_ACTIONS.SETTINGS_LOADING});
  try{
    const settings  = await Apis.Settings.get();
    const environment = await Apis.Settings.get("/environment");

    const result = {
      ok: true,
      settings: settings.data,
      environment: environment.data,
    };
    
    handleResult(dispatch, result, APP_ACTIONS.SETTINGS_LOADED, APP_ACTIONS.ERROR);
    return settings.data;
  }
  catch(ex){
    return dispatch({type: APP_ACTIONS.ERROR, response: {ok: false, error: ex}});
  }

  
}


const heartbeat = () => async(dispatch, getState) => {

  dispatch({type: APP_ACTIONS.WORKING});
  const hb    = await Apis.Heartbeat.get();

  if(hb.ok){
    dispatch({type: APP_ACTIONS.HEARTBEAT, response: hb});
  }
  else{
    dispatch({type: APP_ACTIONS.ERROR, response: hb});
  }
}

//================================
// Exporting the Actions Object
export const appActions = {
  initializeApp : initializeApp,
  logout        : logout,
  heartbeat     : heartbeat,
}