import Apis, { downloadFile, handleResult, paginationHeader } from '../quanta-api';
import { shallowEquals } from 'utils/general-helpers';
import { differenceInMinutes } from 'date-fns';

const ITEMS_LIFETIME   = 5;    //Num of Minutes until an item becomes stale

export const SETS_ACTIONS = {
  ITEMS_LOADING     : "SETS_LOADING",
  ITEMS_LOADED      : "SETS_LOADED",
  ITEMS_ERROR       : "SETS_ERROR",
  ITEMS_EXPORTING   : "SETS_EXPORTING",
  ITEMS_EXPORTED    : "SETS_EXPORTED",
  EXPORT_ERROR      : "SETS_EXPORT_ERROR",
  PENDING_LOADED    : "SETS_PENDING_LOADED",
};

//====
// Will load the list of sets (if necessary) based on the current page and pagination information and store the results in the reducer
export const loadSets = (filter, pageNumber = 1, pageSize = 25, forceRefresh = false) => async(dispatch, getState) => {
  const state   = getState().sets;
  if(!forceRefresh && !state.isItemChanged && haveItems(filter, pageNumber, pageSize, state)){
    return state.items;
  }
  
  dispatch({type: SETS_ACTIONS.ITEMS_LOADING});
  
  const headers   = paginationHeader(pageNumber, pageSize);
  const items     = await Apis.Reviewsets.get("", filter, headers);

  //Pass through the filter to help determine if/when the data needs refreshing
  return handleResult(dispatch, items, SETS_ACTIONS.ITEMS_LOADED, SETS_ACTIONS.ITEMS_ERROR, { filter: filter });  
};

//====
// Loads all the pending reviewset for a specific engagement. Used by the Move Document feature to provide a list of target reviewsets
export const loadAllPendingSets = (engId) => async(dispatch, getState) => {
  dispatch({type: SETS_ACTIONS.ITEMS_LOADING});
  
  const filter = {
    engagementId: engId,
    filter: "status:pending",
    search: null,
    sort: null,
  };

  const headers   = paginationHeader(1, 9999);
  const items     = await Apis.Reviewsets.get("", filter, headers);

  return handleResult(dispatch, items, SETS_ACTIONS.PENDING_LOADED, SETS_ACTIONS.ITEMS_ERROR);  
};

//====
// Will get the list of sets based on the current filter, and download them as a csv.
export const exportSets = (filter = null) => async(dispatch, getState) => {

  dispatch({type: SETS_ACTIONS.ITEMS_EXPORTING});
  const headers   = paginationHeader(1, 10000);  //limit export to 10,000 items (need to pass in some pagination number, otherwise server defaults it to 200)
  const result = await Apis.Reviewsets.get("export", { ...filter, responseType: "arraybuffer"}, headers);

  //Pass through the filter to help determine if/when the data needs refreshing
  if(result.ok){
    const isOk  = await downloadFile(result, "text/csv");
    if(!isOk){
      console.log("Export failed.");
    }
    dispatch({type: SETS_ACTIONS.ITEMS_EXPORTED, data: result});
  }
  else{
    dispatch({type: SETS_ACTIONS.EXPORT_ERROR, error: result});
  }
}

export const setActions = {
  loadSets    : loadSets,
  exportSets  : exportSets,
}


//================
// HELPER METHODS

//--------------
// Check to see if I already have the item and it's fresh
function haveItems(filter, pageNumber, pageSize, state){
  const { items, loadTime, lastFilter, pagination }  = state;
  if(items && loadTime && lastFilter){
    if(pageNumber === pagination.CurrentPage && pageSize === pagination.ItemsPerPage){
      if(shallowEquals(filter, lastFilter)){
        if(differenceInMinutes(new Date(), loadTime) <= ITEMS_LIFETIME){
          return true;
        }
      }
    }
  }

  return false;
}