import { METRICS_ACTIONS as ACTIONS } from '../actions/metrics-actions';
import { createReducer } from 'utils/reducer-helper';

const INIT_STATE  = {
  engagement      : null,
  engStatus    : {
    isWorking     : true,
    error         : null,
    loadedAt      : null,
    lastId        : null,
  },

  recents         : null,
  recentsStatus   : {
    isWorking     : true,
    error         : null,
    loadedAt      : null,
  },

  rsCounts      : null,
  docCounts     : null,
  
  status  : {
    rsCounts  : {
      isInitialized : false,
      isWorking     : false,
      error         : null,
    },
    docCounts  : {
      isInitialized : false,
      isWorking     : false,
      error         : null,
    },
  }
};

function statusChanged(state, action){
  const current   = state.status[action.key];
  const updated   = {...current, ...action.value};
  return {
    ...state,
    status  : {
      ...state.status,
      [action.key]  : {
        ...state.status[action.key],
        ...updated
      },
    }
  };
}

//=======================
//#region Engagement Reducers
function loadingEng(state, action){
  return {
    ...state,
    engagement      : null,
    engStatus   : {
      isWorking     : true,
      error         : null,
      loadedAt      : null,
      lastId        : null,
    }
  };
}

function loadedEng(state, action){
  return {
    ...state,
    engagement      : action.response,
    engStatus   : {
      isWorking     : false,
      error         : null,
      loadedAt      : new Date(),
      lastId        : action.id,
    }
  };
}

function loadFailedEng(state, action){
  return {
    ...state,
    engagement      : null,
    engStatus   : {
      isWorking     : false,
      error         : action.response,
      loadedAt      : null,
      lastId        : null,
    }
  };
}
//#endregion

//=======================
//#region User Recents Reducers
function loadingRecents(state, action){
  return {
    ...state,
    recents     : null,
    recentsStatus   : {
      isWorking     : true,
      error         : null,
      loadedAt      : null,
    }
  };
}

function loadedRecents(state, action){
  return {
    ...state,
    recents     : action.response,
    recentsStatus   : {
      isWorking     : false,
      error         : null,
      loadedAt      : new Date(),
    }
  };
}

function failedRecents(state, action){
  return {
    ...state,
    recents      : null,
    recentsStatus   : {
      isWorking     : false,
      error         : action.response,
      loadedAt      : null,
    }
  };
}
//#endregion


//=======================
//#region Engagement Dashboard items

function rsCountsLoaded(state, action){
  return {
    ...state,
    rsCounts  : action.response,
  };
}

function docCountsLoaded(state, action){
  return {
    ...state,
    docCounts  : action.response,
  };
}

//#endregion

//----------------------
// The action map that is turned into the reducer
const metricsReducer    = {
  [ACTIONS.STATUS_CHANGE]    : statusChanged,
  

  [ACTIONS.LOADING_ENG]     : loadingEng,
  [ACTIONS.LOADED_ENG]      : loadedEng,
  [ACTIONS.FAILED_ENG]      : loadFailedEng,
  [ACTIONS.LOADING_USER_RECENTS]  : loadingRecents,
  [ACTIONS.LOADED_USER_RECENTS]   : loadedRecents,
  [ACTIONS.FAILED_USER_RECENTS]   : failedRecents,

  [ACTIONS.RS_COUNTS_LOADED]    : rsCountsLoaded,
  [ACTIONS.DOC_COUNTS_LOADED]    : docCountsLoaded,
};

//The reducer
export default createReducer(INIT_STATE, metricsReducer);