import Apis, { handleResult } from '../quanta-api';
import { APPROVE_TYPES } from 'utils/checklist-helpers';
import { differenceInMinutes } from 'date-fns';

const ITEM_LIFETIME   = 5;    //Num of Minutes until an item becomes stale

export const DOC_ACTIONS = {
  ITEM_LOADING      : "DOC_LOADING",
  ITEM_LOADED       : "DOC_LOADED",
  ITEM_ERROR        : "DOC_ERROR",

  INPUT_RESPONDING  : "DOC_INPUT_RESPONDING",
  INPUT_RESPONDED   : "DOC_INPUT_RESPONDED",
  INPUT_ERROR       : "DOC_INPUT_ERROR",

  CHECKLIST_SELECTED: "DOC_CHECKLIST_SELECTED",
  FILTER_CHECKLIST  : "DOC_FILTER_CHECKLIST",

  CHECKLIST_APPROVING   : "CHECKLIST_APPROVING",
  CHECKLIST_APPROVED    : "CHECKLIST_APPROVED",
  CHECKLIST_APPROVE_FAILED  : "CHECKLIST_APPROVE_FAILED",
  CHECKLIST_APPROVE_CLEAR : "CHECKLIST_APPROVE_CLEAR",

  ITEM_LOADING_RRS : "DOC_LOADING_RRS",
  ITEM_LOADED_RRS: "DOC_LOADED_RRS",
};

export const loadDocument = (docId, forceRefresh = false) => async (dispatch, getState) => {
  //Determine if we can use the currently loaded item
  const state   = getState().doc;
  if(forceRefresh || haveItem(docId, state)){
    return state.item;
  }

  dispatch({type: DOC_ACTIONS.ITEM_LOADING});
  const item     = await Apis.Documents.get(`/${docId}`);  
  return handleResult(dispatch, item, DOC_ACTIONS.ITEM_LOADED, DOC_ACTIONS.ITEM_ERROR);
}

//-----
// Creates or updates a CheckListItem Response (to an input)
export const respondToItem = (response) => async(dispatch, getState) => {

  dispatch({type: DOC_ACTIONS.INPUT_RESPONDING, props: response});

  let result  = null;
  if(response.isDelete && response.id){
    result  = await Apis.CheckListResponses.delete(`/${response.id}`);
  }
  else if(response.isUpdate && response.id){
    result  = await Apis.CheckListResponses.put(`/${response.id}`, response);
  }
  else if(!response.id){
    result  = await Apis.CheckListResponses.post("", response);
  }

  return handleResult(dispatch, result, DOC_ACTIONS.INPUT_RESPONDED, DOC_ACTIONS.INPUT_ERROR, {props: response});
}

export const approveCheckList = (model) => async (dispatch, getState) => {
  const endpoint  = model.status === APPROVE_TYPES.unApprove ? "disapprove" : "approve"; 
  const url       = `/${model.reviewRecordId}/checklists/${model.checkListId}/${endpoint}`;

  dispatch({type: DOC_ACTIONS.CHECKLIST_APPROVING, props: model});
  const result    = await Apis.ReviewRecords.put(url, model);
  return handleResult(dispatch, result, DOC_ACTIONS.CHECKLIST_APPROVED, DOC_ACTIONS.CHECKLIST_APPROVE_FAILED, { props: model });
}

//-----
// Flags the currently selected checklist
export const checkListSelected = (index) => async(dispatch, getState) => {
  return dispatch({type: DOC_ACTIONS.CHECKLIST_SELECTED, index: index});
}

//-----
// Filters the checklist items for the current checklist based on the filter props
export const filterCheckList = (filterProps) => async(dispatch, getState) => {
  return dispatch({type: DOC_ACTIONS.FILTER_CHECKLIST, props: filterProps});
}

//----
// Clears out the approval structure for checklists.  Used when closing the dialog to remove info from previous visit.
export const clearChecklistApproval = () => async(dispatch) => {
  return dispatch({type: DOC_ACTIONS.CHECKLIST_APPROVE_CLEAR});
}

//== NOTE: this action is not dispatched as it is a transient request, and
// the results should not be stored in the repository.
export const loadDocReviewRecords = async (docId) => {
  const result = await Apis.Documents.get(`/${docId}/review-records`);
  if(result.ok){
    return {
      ok: true,
      data: result.data
    }
  }  
  else{
    return result;
  }
}

//================
// HELPER METHODS

//--------------
// Check to see if I already have the item and it's fresh
function haveItem(docId, state){
  const { item, loadTime }  = state;
  if(item && loadTime && item.id === docId){
    if(differenceInMinutes(new Date(), loadTime) <= ITEM_LIFETIME){
      return true;
    }
  }

  return false;
}