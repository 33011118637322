import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import StatusCard from './status-card';

export interface IWatiContainerProps {
  isWaiting?: boolean;
  message?: string;
  isEmpty?: boolean;
  emptyMessage?: string;
  className?: string;
  variant?: string;
  color?: "primary" | "secondary";
  children: ReactNode;
}

const WaitContainer = ({isWaiting, message, isEmpty, emptyMessage, className = "", variant, color, children, ...otherProps}: IWatiContainerProps) => {
  
  //TODO: multi-prop waiting
  // const props   = getProps(isWaiting, message);
  const { classes, cx } = buildStyles();
  const waitMessage   = message === undefined ? "Loading..." : message;
  const progress      = variant === "circular" ? 
    <CircularWaiter color={color} classes={classes} cx={cx} className={className} message={waitMessage}/> : 
    <LinearWaiter color={color} classes={classes} cx={cx} className={className} message={waitMessage}/>;
  

  return (
    <React.Fragment>
      {isWaiting && progress }
      {(!isWaiting && isEmpty && emptyMessage) && 
        <StatusCard status="info" isVisible={true} className={cx(classes.empty, classes.card, className)} {...otherProps}>
          <span>{emptyMessage}</span>
        </StatusCard>
      }
      {(!isWaiting && (!isEmpty || !emptyMessage)) && 
        <React.Fragment>
          {children}
        </React.Fragment>
      }   
    </React.Fragment>
  );
}

const buildStyles = makeStyles()((theme: any) => ({
  root   : {
    textAlign   : "center",
    padding     : theme.spacing(2),
  },
  message   : {
    fontSize    : "1.25rem",
  },
  empty     : {
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
  },
  card    : {
    width     : "100%",
  },
  circular  : {
    display         : "flex",
    flexDirection   : "column",
    justifyContent  : "center",
    alignItems      : "center",
    padding         : theme.spacing(4),
  },
  circularProgress  : {
    marginBottom     : theme.spacing(1),
  },
  linear  : {

  },
  linearProgress  : {
    minWidth    : "100px",
    marginTop   : theme.spacing(1),
  }
}));

export default WaitContainer;

WaitContainer.propTypes = {
  isWaiting   : PropTypes.bool.isRequired,
  message     : PropTypes.string,
  className   : PropTypes.string,
  variant     : PropTypes.string,
  color       : PropTypes.string,
};

WaitContainer.defaultProps = {
  variation   : "circular",
  color       : "primary",
};

export interface IWaiter {
  color?: "primary" | "secondary";
  classes: any;
  cx: any;
  className: string;
  message: string;
}

const LinearWaiter = ({color, classes, cx, className, message}: IWaiter) => (
  <div className={cx("waiting waiting-linear", classes.linear, classes.root, className)}>
    <em className={classes.message}>{message}</em>
    <LinearProgress color={color} className={classes.linearProgress}/> 
  </div>
);

const CircularWaiter = ({color, classes, cx, className, message}: IWaiter) => (
  <div className={cx("waiting waiting-circular", classes.circular, classes.root, className)}>
    <CircularProgress color={color} className={classes.circularProgress}/>  
    <em className={classes.message}>{message}</em>
  </div>
);

//TODO: Implement the multi-prop waiting
// function getProps(isWaiting, message){
//   if(_.isArray(isWaiting)){
//     const idx   = _.findIndex(isWaiting, w => w === true);
//     return {
//         isWaiting   : idx >= 0,
//         message     : _.isArray(message) ? message[idx] : message || "Loading...",
//     };
//   }
//   else{
//     return {
//       isWaiting   : isWaiting,
//       message     : _.isArray(message) ? message[0] : message || "Loading...",
//     };
//   }
// }