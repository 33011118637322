import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent } from '@mui/material';
import { toastr } from 'react-redux-toastr';
import { useSetting, useCurrentReviewSet, useCurrentPermissions, useQuery } from 'hooks';
import { ListToolbar, ListFooter, CltTable, ErrorDisplay } from 'features/common';
import DocumentListItem from '../reviewsets/reviewset/document-list-item';
import { loadDocsForEngagement, loadDocsForSet, moveDocument, removeDocument, exportSetDocs, exportEngagementDocs } from 'store/actions/documents-actions';
import { selectDocuments } from 'store/selectors/document-selectors';
import { updateUrlSearch, shallowEquals, findById } from 'utils/general-helpers';
import AddDocumentDialog from '../reviewsets/reviewset/add-document-dialog';

const DEFAULT_PROPS  = {
  pageNumber      : 1,
  pageSize        : 25,
  error           : null,
  last            : null,
};

function DocumentsList({listKey}){
  const navigate = useNavigate();
  const query       = useQuery();
  const set         = useCurrentReviewSet();
  const docs        = useSelector(selectDocuments);
  const status      = useSelector(state => state.docs.itemsStatus);
  const dispatch    = useDispatch();
  const settingsKey = listKey || `${query.reviewsetId ? "sets" : "engs"}-doc-list-pagesize`; //`
  const [pageSize, setPageSize]   = useSetting(settingsKey, 25);
  const [props, setProps]         = useState({...DEFAULT_PROPS, pageSize : pageSize});  
  const [isAdding, setAdding]     = useState(false);
  const permissions = useCurrentPermissions();

  //TODO: Consider making this a custom hook to simplify this component...
  useEffect(() => {
    
    if(query.search?.indexOf("id:") === 0 && docs && docs.length > 0){
      const doc           = findById(docs, query.search?.substring(3));
      const previousUrl   = updateUrlSearch("search", null);  //remove the search from the url

      if(doc){
        navigate(previousUrl, { replace: true });   //NOTE: This is so that we can use the back button, otherwise, we get caught in a loop (it returns to ?search=id:xx, which triggers the nav back to the doc)
        // history.push(`${doc.url}${doc.id}`); //`
        navigate(`${previousUrl}/documents/${doc.id}`); //`
        return;
      }
      
      //if we reached here, the doc id wasn't found in the list
      setProps({...props, error: "The id you entered was either invalid or not available in the current list of documents."});
      return;
    }
    else{
      //Check to see if the props changed from last time
      const isChanged   = needsRefresh(props, query);
      if(isChanged){
        loadDocs(dispatch, query, props);
        setProps({...props, error: null, last : { query: query, pageNumber: props.pageNumber, pageSize: props.pageSize }});
      }
      else if(props.error && !query.search){
        setProps({...props, error: null});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.sort, query.search, query.filter, props.pageNumber, props.pageSize]);

  //Whether this list is for a reviewset or for an engagement
  const canAdd      = query.reviewsetId && set && set.isPending && !permissions.isReadOnly;
  const canMove     = query.reviewsetId && set && set.isPending && permissions.isAdmin;
  const isEngagement= !Boolean(query.reviewsetId);
  const title       = getTitle(query);
  const emptyText   = status.isEmpty ? `There are no Documents${query.hasFilter ? " matching your current filter / search criteria" : ""}` : null;
  const baseUrl     = getBaseUrl(query); //`
  // const colSpan     = (canAdd || isEngagement) ? 5 : 4;

  const onSort  = (sortStr) => {
    const newUrl  = updateUrlSearch("sort", sortStr);
    navigate(newUrl, { replace: true });
  };

  const onChangePage  = (pageNum, pageSize) => { 
    
    if(pageSize && pageSize !== props.pageSize){
      setPageSize(pageSize);
      // writeItem(settingsKey, { pageSize: pageSize}); //update list settings with the page size
    }

    const changes   = {
      pageNumber  : pageNum || props.pageNumber,
      pageSize    : pageSize || props.pageSize,
    }
    setProps({...props, ...changes});     
  };

  const onToggleAdd   = () => {
    setAdding(!isAdding);
  }

  const onDeleteItem  = async (doc) => {
    const result = await dispatch(removeDocument(doc.reviewRecordId, doc.id));
    const response = result.response;
    if(!response.ok){
      setProps({...props, error: response});
    }
  }

  const onMoveItem = async (doc, targetSetId) => {
      const rrId = doc.reviewRecordId;
  
      if(rrId && targetSetId){
        const result = await dispatch(moveDocument(doc.id, rrId, targetSetId));
        if(!result.ok){
          const errs = result.response?.data?.errors; 
          if(errs && errs[0].message?.indexOf("Cannot insert duplicate key") >= 0){
            setProps({...props, error: "The document you are attempting to move already exists on the ReviewSet you selected. Please select a different ReviewSet."});
          }
          else{
            setProps({...props, error: result.response});
          }
        }
        else{
          setProps({...props, error: null});
          toastr.success("The document was successfully moved");
        }
      }
  }

  const onCloseError = () => {
    setProps({...props, error: null});
  }

  const onExport = async () => {
    if(Boolean(query.reviewsetId)){
      return await dispatch(exportSetDocs(query.reviewsetId, query));
    }
    else{
      return await dispatch(exportEngagementDocs(query.engagementId, query));
    }
  }

  return (
    <Card id="documents-list" sx={{width: "100%"}}>
      <CardContent>
        
        <ListToolbar 
          title={title} 
          baseUrl={baseUrl} 
          newCallback={canAdd ? onToggleAdd : null} 
          isWorking={status.isLoading} 
          pagination={status.pagination} 
          onChangePage={onChangePage} 
          searchPlaceholder="Search by Key..." 
          onExport={onExport} 
          idUrlModifier="documents"/>
        
        <ErrorDisplay error={props.error} onClose={onCloseError} />
        
        <Grid container>
          <CltTable 
            cols={getColumns(isEngagement, canAdd)}
            onSort={onSort}
            isWorking={status.isWorking}
            isEmpty={status.isEmpty}
            emptyText={emptyText}>
            { docs?.map(doc => 
                <DocumentListItem 
                  key={doc.id} 
                  doc={doc} 
                  targetUrl={getDocUrl(doc, query)} 
                  onDelete={canAdd ? onDeleteItem : null} 
                  onMove={canMove ? onMoveItem : null}  
                  isForEngagement={isEngagement ? query.engagementId : undefined}
                />)
            }
          </CltTable>          
        </Grid>

        <ListFooter pagination={status.pagination} isWorking={status.isLoading} onChangePage={onChangePage}/>

        {canAdd && <AddDocumentDialog isOpen={isAdding} onClose={onToggleAdd} />}

      </CardContent>
    </Card>
  );
}

export default DocumentsList;

const getColumns = (isEngagement, canAdd) => {
  if(isEngagement) return [...BASE_COLS, rsCol];
  else if(canAdd) return [...BASE_COLS, actionsCol];
  else return [...BASE_COLS];
}

const rsCol = { id: 40, label: "ReviewSet(s)" };
const actionsCol = {id: 50, label: "Actions" };
const BASE_COLS   = [
  {
    id        : 0,
    sortKey   : "key",
    label     : "Key",
    style     : {minWidth: "45px"},
    defaultDir: "asc",
  },
  {
    id        : 10,
    sortKey   : "type",
    label     : "Document Type",
    style     : {minWidth: "150px"},    
  },
  {
    id        : 20,
    sortKey   : "status",
    label     : "Status",
  },
  {
    id        : 30,
    sortKey   : "date",
    label     : "Last Modified",
    style     : {minWidth: "150px"},
  }
];

function getTitle(query){
  let prefix    = "";
  if(query?.filter === "owner:me") prefix = "My ";
  else if(query?.filter === "status:pending") prefix = "Pending ";
  else if(query?.filter === "status:approved") prefix = "Approved ";

  return `${prefix}Documents`; //`
}

async function loadDocs(dispatch, query, props){
  if(query.reviewsetId){
    await dispatch(loadDocsForSet(query.reviewsetId, query, props.pageNumber, props.pageSize));
  }
  else{
    await dispatch(loadDocsForEngagement(query.engagementId, query, props.pageNumber, props.pageSize));
  }
}

function needsRefresh(props, query){
  if(props.last){
    return (
      props.pageNumber  !== props.last.pageNumber || 
      props.pageSize !== props.last.pageSize || 
      !shallowEquals(props.last.query, query)
    );      
  }  
  return true;
}

function getDocUrl(doc, query){
  return `/engagements/${query.engagementId}/reviewsets/${doc.primaryReviewSetId || query.reviewsetId}/documents/`; //`
}

function getBaseUrl(query){
  if(query.reviewsetId){
    return `/engagements/${query.engagementId}/reviewsets/${query.reviewsetId}`; //`
  }
  else{
    return `/engagements/${query.engagementId}/documents`; //`
  }
}