import _, { pick } from 'lodash';
import { getDocumentStatus } from 'utils/document-helpers';
import { prune } from 'utils/general-helpers-ts';
import Apis, { downloadFile, handleResult, paginationHeader } from '../quanta-api';

// const ITEM_LIFETIME   = 5;    //Num of Minutes until an item becomes stale

export const DOCS_ACTIONS = {
  ITEMS_LOADING     : "DOCS_LOADING",
  ITEMS_LOADED      : "DOCS_LOADED",
  ITEMS_ERROR       : "DOCS_ERROR",

  ITEMS_EXPORTING   : "DOCS_EXPORTING",
  ITEMS_EXPORTED    : "DOCS_EXPORTED",
  EXPORT_ERROR      : "DOCS_EXPORT_ERROR",

  EXIST_LOADING     : "DOCS_EXIST_LOADING",
  EXIST_LOADED      : "DOCS_EXIST_LOADED",
  EXIST_ERROR       : "DOCS_EXIST_ERROR",

  CREATING          : "DOCS_CREATING",
  CREATED           : "DOCS_CREATED",
  CREATE_ERROR      : "DOCS_CREATE_ERROR",
  CREAT_ERROR_CLEAR : "DOCS_CREATE_ERROR_CLEAR",

  REMOVING          : "DOCS_REMOVING",
  REMOVED           : "DOCS_REMOVED",
  REMOVE_ERROR      : "DOCS_REMOVE_ERROR",

  MOVE_TARGETS_LOADING: "DOCS_MOVE_TARGETS_LOADING",
  MOVE_TARGETS_LOADED: "DOCS_MOVE_TARGETS_LOADED",
  ITEM_MOVING : "DOCS_MOVING",
  ITEM_MOVED: "DOCS_MOVED",
  ITEM_MOVE_ERROR: "DOCS_MOVE_ERROR",
};


const getSearchProps = (queryObj) => {
  //Only a couple of the props are needed from the 'filter' string that's passed in here
  const sProps = prune(pick(queryObj, ["search", "filter", "sort"]));
  return sProps;
}

//-----
// Gets the list of document for a specific engagement
export const loadDocsForEngagement = (engId, filter, pageNumber = 1, pageSize = 25) => async(dispatch, getState) => {
  dispatch({type: DOCS_ACTIONS.ITEMS_LOADING});
  const headers   = paginationHeader(pageNumber, pageSize);
  const searchProps = getSearchProps(filter);
  const engFilter = {...searchProps, engagementId : engId }; //Add the engagementId to the filter
  const items     = await Apis.Documents.get("", engFilter, headers);
  
  return handleResult(dispatch, items, DOCS_ACTIONS.ITEMS_LOADED, DOCS_ACTIONS.ITEMS_ERROR); 
}

//-----
// Gets the list of document for a specific reviewset
export const loadDocsForSet = (setId, filter, pageNumber = 1, pageSize = 25) => async(dispatch, getState) => {

  dispatch({type: DOCS_ACTIONS.ITEMS_LOADING});
  const headers   = paginationHeader(pageNumber, pageSize);
  const searchProps = getSearchProps(filter);
  const items     = await Apis.Documents.get(`/forset/${setId}`, searchProps, headers);
  
  return handleResult(dispatch, items, DOCS_ACTIONS.ITEMS_LOADED, DOCS_ACTIONS.ITEMS_ERROR); 
}

export const exportSetDocs = (setId, filter) => async(dispatch) => {

  dispatch({type: DOCS_ACTIONS.ITEMS_EXPORTING});
  const headers   = paginationHeader(1, 10000); //need something for page size, so limit to 10,000 rows?
  const searchProps = getSearchProps(filter);
  const result    = await Apis.Documents.get(`/export/forset/${setId}`, {...searchProps, responseType: "arraybuffer"}, headers);
  if(result.ok){
    const isOk  = await downloadFile(result, "text/csv");
    if(!isOk){
      console.log("Export failed.");
    }
    dispatch({type: DOCS_ACTIONS.ITEMS_EXPORTED, data: result});
  }
  else{
    dispatch({type: DOCS_ACTIONS.EXPORT_ERROR, error: result});
  }
}

export const exportEngagementDocs = (engId, filter) => async(dispatch) => {

  dispatch({type: DOCS_ACTIONS.ITEMS_EXPORTING});
  const headers   = paginationHeader(1, 10000); //need something for page size, so limit to 10,000 rows?
  const searchProps = getSearchProps(filter);
  const engFilter = {...searchProps, engagementId : engId, responseType: "arraybuffer" }; //Add the engagementId to the filter
  const result    = await Apis.Documents.get(`/export/forengagement/${engId}`, engFilter, headers);
  if(result.ok){
    const isOk  = await downloadFile(result, "text/csv");
    if(!isOk){
      console.log("Export failed.");
    }
    dispatch({type: DOCS_ACTIONS.ITEMS_EXPORTED, data: result});
  }
  else{
    dispatch({type: DOCS_ACTIONS.EXPORT_ERROR, error: result});
  }
}

export const loadExistingDocs = (filter) => async(dispatch, getState) => {
  dispatch({type: DOCS_ACTIONS.EXIST_LOADING});
  const items   = await Apis.Documents.get(`/existing`, filter);
  return handleResult(dispatch, items, DOCS_ACTIONS.EXIST_LOADED, DOCS_ACTIONS.EXIST_ERROR, {filter: filter});
}

//===
// Loads ReviewSets that can be a destination for moving the provided document.
//NOTE: does not put list in redux state, just returns it locally.
export const loadMoveTargets = (docId) => async (dispatch) => {
  dispatch({type: DOCS_ACTIONS.MOVE_TARGETS_LOADING});
  const targets = await Apis.Documents.get(`${docId}/move-targets`);
  dispatch({type: DOCS_ACTIONS.MOVE_TARGETS_LOADED});
  return targets;  
}

//===
// Moves a document to a different ReviewSet.
export const moveDocument = (docId, reviewRecordId, targetReviewSetId) => async (dispatch, getState) => {
  dispatch({type: DOCS_ACTIONS.ITEM_MOVING});
  const result = await Apis.ReviewRecords.put(`${reviewRecordId}/move-to/reviewset/${targetReviewSetId}`);
  return handleResult(dispatch, result, DOCS_ACTIONS.ITEM_MOVED, DOCS_ACTIONS.ITEM_MOVE_ERROR, {reviewRecordId, docId});
}

export const createDocument = (doc, set) => async(dispatch, getState) => {
  dispatch({type: DOCS_ACTIONS.CREATING});

  let data  = {};

  //Determine if we need to create the document, or we're just attaching an existing one
  if(!doc.docId){
    //New doc
    data  = {
      documentKey     : doc.docKey,
      documentTypeId  : doc.docTypeId,
      siteId          : set.siteId,
    };

    //Create the document
    const result  = await Apis.Documents.post("", data);
    if(!result.ok){
      //error creating the document
      await dispatch({type: DOCS_ACTIONS.CREATE_ERROR, response: result});
      return result;
    }
    
    doc.docId     = result.data.id;   //Get the Document Id from the result...
    // data.doc        = result.data;
  }

  //Create the ReviewRecord
  const rvRecord  = {
    reviewSetId     : set.id,
    documentId      : doc.docId,
  };

  //Create the ReviewRecord linking the document to this ReviewSets
  const rrResult      = await Apis.ReviewRecords.post("", rvRecord);
  if(!rrResult.ok){
    await dispatch({type: DOCS_ACTIONS.CREATE_ERROR, response: rrResult});
    //TODO: This creates an orphan document... not sure what to do about this.
    return rrResult;
  }

  const rawDoc = rrResult.data;
  const docStatus = getDocumentStatus(rrResult.data);
  const newDoc = {
    ...pick(rawDoc, ["documentKey", "documentTypeId", "documentTypeName", "id", "lastModifiedBy", "lastModifiedOn", "primaryReviewRecordId", "reviewRecordId"]),
    ...pick(docStatus, ["status", "statusCode"])
  };

  //TODO: do I need to get the status description?
  //Need to get the DocumentType Name from the settings
  const docTypes   = getState().settings.documentTypes; 
  newDoc.documentTypeName   = _.find(docTypes, dt => dt.id === newDoc.documentTypeId).name;
  
  //Need review record information on this document
  const reviewRecords = rawDoc.reviewRecords;
  const setReviewRecord = reviewRecords.find(rr => rr.reviewSetId === rvRecord.reviewSetId);
  newDoc.reviewRecordId = setReviewRecord.id;
  if(newDoc.reviewRecordId === newDoc.primaryReviewRecordId) newDoc.primaryReviewRecordId = null; //fix it up so we know this is the primary
  
  await dispatch({type: DOCS_ACTIONS.CREATED, response: data, doc: newDoc, reviewRecords});
  return rrResult;
}

export const clearCreateError = () => async(dispatch, getState) => {
  await dispatch({type: DOCS_ACTIONS.CREAT_ERROR_CLEAR});
}

export const removeDocument = (reviewRecordId, documentId) => async (dispatch, getState) => {

  dispatch({type: DOCS_ACTIONS.REMOVING});
  //Just send a delete request to the reviewrecord
  const result  = await Apis.ReviewRecords.delete(`/${reviewRecordId}`);
  return handleResult(dispatch, result, DOCS_ACTIONS.REMOVED, DOCS_ACTIONS.REMOVE_ERROR, {reviewRecordId: reviewRecordId, docId: documentId});

}