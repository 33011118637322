import { statusKeys } from './../api-middleware';
import { IApiAction } from "app-types";
import { getQuantaApi } from "utils/action-helpers";

const ADMIN_STATUS_CHANGE = "ADMIN_STATUS_CHANGE";
export const ADMIN_REPORT_ACTIONS = {
  REPORTQUEUE_LOADED: "ADMIN_REPORTQUEUE_LOADED",
  REPORTQUEUE_DELETED: "ADMIN_REPORTQUEUE_DELETED",
  REPORTQUEUE_RETRIED: "ADMIN_REPORTQUEUE_RETRIED",
};

// export type StatusActionFactory = (isWorking: boolean, error?: INormalizedError | null | undefined) => IStatusAction;
// const statusAction : StatusActionFactory = (isWorking = false, error: INormalizedError | null = null) => ({ type: ADMIN_STATUS_CHANGE, key: statusKeys.reportQueue, value: { isWorking, error }});

// const actionStatusWrapper   = getActionWrapper(ADMIN_STATUS_CHANGE, NO_OP);
// const dispatchWithStatus = dispatchWithStatusFactory(statusKeys.reportQueue, ADMIN_STATUS_CHANGE, NO_OP);
const Apis = getQuantaApi();

export const createLoadReportQueueAction = (filter: any, pageNumber = 1, pageSize = 25): IApiAction => ({
  type: ADMIN_REPORT_ACTIONS.REPORTQUEUE_LOADED,
  api: {
    endpoint: Apis.ReportQueue,
    verb: "get",
    path: "/list",
    params: filter,
    status: {
      statusType: ADMIN_STATUS_CHANGE,
      statusKey: statusKeys.reportQueue,
    },
    pagination: {
      pageNumber,
      pageSize,
    }
  }
});

export const createRetryReportQueueAction = (rowId: number): IApiAction => ({
  type: ADMIN_REPORT_ACTIONS.REPORTQUEUE_RETRIED,
  api: {
    endpoint: Apis.ReportQueue,
    verb: "put",
    path: `${rowId}/retry`,
    status: {
      statusType: ADMIN_STATUS_CHANGE,
      statusKey: statusKeys.reportQueue,
    },
    
  }
});

export const createDeleteReportQueueAction = (rowId: number) : IApiAction => ({
  type: ADMIN_REPORT_ACTIONS.REPORTQUEUE_DELETED,
  api: {
    endpoint: Apis.ReportQueue,
    verb: "delete",
    path: `${rowId}`,
    status: {
      statusType: ADMIN_STATUS_CHANGE,
      statusKey: statusKeys.reportQueue,
    },      
  },
  //other state to pass through to the reducer
  id: rowId
});