import { REPORT_ACTIONS } from '../actions/report-actions';
import { createReducer } from 'utils/reducer-helper';

const INITIAL_STATE   = {
  status            : {
    isLoading       : false,
    error           : null,
    time            : null,
  },

  setData           : null,
  clData            : null,
  
  downloads       : {
    isWorking     : false,
    error         : null,
    report        : null,
    config        : null,
  }
};

function loading(state, action){
  return {
    ...state,
    status  : {
      isLoading   : true,
      error       : null,
      time        : null,
    }
  };
}

function setLoaded(state, action){
  return {
    ...state,
    setData       : action.data,
    status  : {
      isLoading   : false,
      error       : null,
      time        : new Date().toLocaleString(),
    }
  };
}

function clLoaded(state, action){
  return {
    ...state,
    clData        : action.data,
    status  : {
      isLoading   : false,
      error       : null,
      time        : new Date().toLocaleString(),
    }
  };
}

function error(state, action){
  var err = action.data?.data ?? action.data ?? {};

  return {
    ...state,
    setData       : null,
    status     : {
      ...state.status,
      isLoading     : false,
      error         : err,
      time          : null,
    },
  };
}

function startDownload(state, action){
  return {
    ...state,
    downloads   : {
      isWorking     : true,
      error         : null,
      report        : null,
      config        : action.config,
    },
  };
}

function finishDownload(state, action){
  return {
    ...state,
    downloads   : {
      isWorking     : false,
      error         : null,
      report        : action.data,
      config        : action.report,
    },
  };
}

function downloadError(state, action){
  return {
    ...state,
    downloads   : {
      isWorking     : false,
      error         : action.error,      
      config        : action.report,
    },
  };
}


//----------------------
// The action map that is turned into the reducer
const reportsReducer    = {

  [REPORT_ACTIONS.LOADING]          : loading,
  [REPORT_ACTIONS.SET_DATA_LOADED]  : setLoaded,
  [REPORT_ACTIONS.CL_DATA_LOADED]   : clLoaded,
  [REPORT_ACTIONS.ERROR]            : error,

  [REPORT_ACTIONS.DOWNLOADING]  : startDownload,
  [REPORT_ACTIONS.DOWNLOADED]   : finishDownload,
  [REPORT_ACTIONS.DOWNLOAD_ERROR]   : downloadError,

};

//The reducer
export default createReducer(INITIAL_STATE, reportsReducer);