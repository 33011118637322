import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SchoolIcon from '@mui/icons-material/School';
import NotIcon from '@mui/icons-material/NotInterested';
import AssignmentsIcon from '@mui/icons-material/AssignmentInd';
import AssignedIcon from '@mui/icons-material/Person';
import ApproveIcon from '@mui/icons-material/AssignmentTurnedIn';
import ReaderIcon from '@mui/icons-material/Visibility';
import { WaitOverlay } from 'features/common';
import { loadUserAssignments } from 'store/actions/admin-actions';
import { selectUserAssignments, selectStatusByKey } from 'store/selectors/admin-selectors';
import { addOrReplace, removeItem } from 'store/store-helpers';
import { useAllEngagements } from 'hooks/admin-hooks';

const UserEngagements = ({user, onChange}) => {
  const { classes }     = buildStyles();
  const dispatch    = useDispatch();
  const engagements = useAllEngagements();
  const assignments = useSelector(state => selectUserAssignments(state, user?.id));
  const status      = useSelector(state => selectStatusByKey(state, "users"));
  const [items, setItems]       = useState([]);
  const [changes, setChanges]   = useState([]);
  const [view, setView]         = useState("assigned");

  useEffect(() => {
    if(!assignments && user && user.id >= 0){
      dispatch(loadUserAssignments(user.id));
    }
  }, [user, assignments, dispatch]);

  useEffect(() => {
    if(user && engagements){
      
      const all  = _.map(engagements, eng => {
        const role  = findUserAssignment(user, assignments, eng.id)?.role || null;
        return {...eng, role: role };
      });
      
      const filtered  = (view === "all") ? all : _.filter(all, a => a.role !== null);
      const ordered   = _.orderBy(filtered, e => e.name);

      //Incorporate the in-process changes
      const ready = ordered.map(o => {
        const change = changes.find(c => c.engagementId === o.id);
        if(change){
          return {...o, role: change.role};
        }
        return o;
      });
      
      setItems(ready);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, view, engagements, assignments]);

  function onCheckChanged(e, eng){
    //TODO: track the changes...
    const newRole     = e.target.value === "not" ? null : e.target.value;
    const engAssign   = _.find(items, asg => asg.id === eng.id);    
    const updates     = {...engAssign, role: newRole};
    const updated     = addOrReplace(items, asg => asg.id === eng.id, updates);

    const assignment  = findUserAssignment(user, assignments, eng.id);
    const change      = _.find(changes, change => change.engagementId === eng.id);
    if((!assignment && newRole === null) || (assignment && assignment.role === newRole)){
      //this is the same as the original assignment, so make sure we don't have a change...
      if(change){
        const newChanges  = removeItem(changes, change);
        setChanges(newChanges);
        onChange(newChanges);
      }
    }
    else{
      //something changed, so need to add or update...
      const id = assignment ? assignment.id : -1;
      const newChange   = {id, engagementId: eng.id, role: newRole };
      const newChanges  = addOrReplace(changes, c => c.engagementId === eng.id, newChange);
      setChanges(newChanges);
      onChange(newChanges);
    }

    setItems(updated);
  }

  return (
    <Grid id="user-engagements" container justifyContent="center">
      <WaitOverlay isWaiting={status.isWorking} message="Working...">
        
        <Grid container className={items.length > 6 ? classes.engHeaderPadded : classes.engHeader} alignItems="center">
          <Grid item xs={6} container>
            <Select id="viewType" label="View" 
              value={view} 
              onChange={(e) => {setView(e.target.value)}} 
              className={classes.selectContainer} classes={{select: classes.select}} size="small"
              startAdornment={<AssignmentsIcon fontSize="small" className={classes.adornIcon}/>}
            >
              <MenuItem value="assigned" className={classes.option}>Assigned Engagements</MenuItem>
              <MenuItem value="all" className={classes.option}>All Engagements</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6} container justifyContent="space-evenly">
            <Grid className={classes.iconGrid}>
              <Tooltip title="Not Assigned"><NotIcon fontSize="small"/></Tooltip>
            </Grid>
            <Grid className={classes.iconGrid}>
              <Tooltip title="Assigned - Reader"><ReaderIcon fontSize="small"/></Tooltip>
            </Grid>
            <Grid className={classes.iconGrid}>
              <Tooltip title="Assigned - Editor"><AssignedIcon fontSize="small"/></Tooltip>
            </Grid>
            <Grid className={classes.iconGrid}>
              <Tooltip title="Assigned - Approver"><ApproveIcon fontSize="small"/></Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="flex-start" alignContent="flex-start" className={classes.listRoot}>
        {
          _.map(items, eng => {
            return (
              <Grid key={eng.id} container className={classes.itemRow} alignItems="center">
                <Grid item xs={6} container justifyContent="space-between" alignItems="flex-start" style={{flexWrap: "nowrap"}}>
                  <Typography className={classes.itemName}>
                    {eng.name}
                    {eng.isTraining && <Tooltip title="Training Engagement"><SchoolIcon fontSize="small" className={classes.trainingIcon}/></Tooltip>}
                  </Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="space-evenly">
                  <Grid className={classes.iconGrid}>
                    <Radio name={eng.id.toString()} size="small" value="not" checked={eng.role === null} onChange={(e) => onCheckChanged(e, eng)}/>
                  </Grid>
                  <Grid className={classes.iconGrid}>
                    <Radio name={eng.id.toString()} size="small" value="reader" checked={eng.role === "reader"} onChange={(e) => onCheckChanged(e, eng)}/>
                  </Grid>
                  <Grid className={classes.iconGrid}>
                    <Radio name={eng.id.toString()} size="small" value="user" checked={eng.role === "user" || eng.role === "User"} onChange={(e) => onCheckChanged(e, eng)}/>
                  </Grid>
                  <Grid className={classes.iconGrid}>
                    <Radio name={eng.id.toString()} size="small" value="approver" checked={eng.role === "approver"} onChange={(e) => onCheckChanged(e, eng)}/>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        }
        </Grid>

      </WaitOverlay>
    </Grid>
  );
}

export default UserEngagements;

const buildStyles   = makeStyles()(theme => ({
  listRoot  : {
    // padding     : theme.spacing(1),   
    height   : 300,
    overflowY   : "auto", 
  },  
  list  : {
    maxHeight   : 300,
    overflowY   : "auto",
  },
  engHeader   : {
    // width         : "calc(100% - 17px)",
    // marginRight   : 16.8,
    marginBottom  : theme.spacing(0.5),
    borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
  },
  engHeaderPadded   : {
    marginBottom  : theme.spacing(0.5),
    borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
    paddingRight: 18,
  },
  iconGrid  : {
    height  : 41.33,
    width   : 41.33,
    display : "flex",
    alignItems  : "center",
    justifyContent  : "center",
    "& svg"   : {
      // color       : theme.palette.grey[500],
    }
  },
  subTitle  : {
    fontSize    : 20,
    fontWeight  : 300,
    color       : theme.palette.grey[500],
    "& svg"   : {
      marginRight   : theme.spacing(1),
      marginBottom  : theme.spacing(-0.5),
    },
  },
  icon        : {
    // marginRight : theme.spacing(0.5),
    marginTop : theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    color     : theme.palette.grey[500],
  },
  trainingIcon : {
    marginLeft: theme.spacing(1),
    marginBottom: -6,
    color     : theme.palette.grey[500],
  },
  itemName    : {
    fontSize    : 16,
    fontWeight  : 300,
  },
  selectContainer : {
    // marginLeft    : theme.spacing(2),
    fontSize: 16,
  },
  adornIcon: {
    marginRight: theme.spacing(0.5),
  }
}));

function findUserAssignment(user, assignments, engagementId){
  return _.find(assignments, asg => asg.entityType === "eng" && asg.entityId === engagementId);
}