import { APP_ACTIONS } from '../store/actions/app-actions';

export const DEFAULT_STATE     = {
    isWorking           : false,
    error               : null,
    status              : null,
};

export const NOT_WORKING   = {
    isWorking           : false,
    status              : null
};

export const NO_ERROR   = {
    error       : null
};

export const INITIALIZED    = {
    isWorking     : false,
    isInitialized : true,
    error         : null,
};


//--------
//helper function for just changing the state to not working and no errors
export function allClear(state){
    return {
        ...state, 
        ...NOT_WORKING,
        ...NO_ERROR
    };
}

//A common set of reducer handler functions for specific actions
export function getDefaultHandlers(ActionTypes){
    let handlers    = {
        [APP_ACTIONS.CLEAR_ALL_ERRORS]  : clearError,
    };

    handlers[ActionTypes.WORKING]       = beginWorking;
    handlers[ActionTypes.ERROR]         = reduceError;
    handlers[ActionTypes.CLEAR_ERROR]   = clearError;

    return handlers;
}

//--------------
// Creates a reducer on the fly out of an object map
export function createReducer(initialState, handlers) {
    return function reducer(state = initialState, action) {
      if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action)
      } 
      else {
        return state
      }
    }
  }

  //--------------
// A common function for reducers to handle the Working state
function beginWorking(state, action){
    return {
        ...state,
        isWorking   : true,
        status      : (action.meta ? action.meta.status : null) || "Loading..."
    };
}

//--------------
// A common function for reducers to handle the Error state
function reduceError(state, action){
    return {
        ...state,
        ...NOT_WORKING,
        error       : action.error
    };
}

function clearError(state, action){
    return {
        ...state,
        ...NO_ERROR
    };
}