import React from 'react';
import { createRoot } from "react-dom/client";
import configureStore from './store/store-config';
import App from './App';
// import './index.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const baseUrl     = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const store       = configureStore();

const render = () => {
  const root = createRoot(rootElement);
  root.render(<App store={store} baseUrl={baseUrl}/>);
};

//Render the app the first time through
render();

//Try to accept any HMR changes, and not re-render the whole app...
if(module.hot) {
    module.hot.accept('./App', () => {
        render();
    });
}