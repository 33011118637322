import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { loadAllEngagements } from 'store/actions/admin-actions';
import { selectAllEngagements } from 'store/selectors/admin-selectors';

///---
// Manages the list of all engagements for admins
export function useAllEngagements(orderArray, filter){
  const allEngagements  = useSelector(selectAllEngagements);
  const dispatch        = useDispatch();

  useEffect(() => {
    if(!allEngagements){
      dispatch(loadAllEngagements());    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items   = useMemo(() => {
    if(allEngagements){
      let filtered  = filter ? _.filter(allEngagements.items, filter) : allEngagements.items;
      const ordered = _.orderBy(filtered, orderArray || ["id"]);
      return ordered;
    }
    else return null;
  }, [allEngagements, orderArray, filter]);

  return items;
}