import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useInputHandler } from 'hooks/general-hooks';
import Validation from 'utils/validation-helper';
import { adminDialogClasses } from 'features/common/dialog-classes';
import { updateWorking } from 'store/actions/builder-actions';
import { selectStatus } from 'store/selectors/builder-selectors';
import { selectFullDocType } from 'store/selectors/admin-selectors';

const ChecklistEditorHeader = ({checklist, isEditable, children}) => {
  const { classes }   = buildStyles();
  const dispatch  = useDispatch();
  const status    = useSelector(state => selectStatus(state, "builder"));
  const original  = useMemo(() => _.pick(checklist, ["name", "description"]), [checklist]);
  const docType   = useSelector(state => selectFullDocType(state, checklist?.documentTypeId));
  const [item, onChange]   = useInputHandler(original, null, onValidate);
  const [validation, setValidation]	= useState({canSave: true});
  

  function onValidate(toValidate){    
    //Validate that there is a name
    const overall   = Validation.validatePropertyCondition(validation, "name", toValidate, val => val.length > 3, "Name is required (and must be at least 3 characters).");
    const hasErrors = _.some(overall, v => v.hasError);
    setValidation({
      ...overall,
      canSave   : !hasErrors,
    });    
  }

  function onTextBlur(e){
    if(isEditable && !shallowEqual(item, original)){
      dispatch(updateWorking(checklist.id, item));
    }
  }

  return (
    <Grid id="header-view" container spacing={1} className={classes.root}>
      <Grid item md={9} sm={7} container>
        <Grid item container alignItems="flex-end" className={classes.formRow}>
          <TextField id="name" value={item.name} onChange={onChange} onBlur={onTextBlur} disabled={!isEditable || status.isWorking} 
              fullWidth autoFocus={true} className={classes.primaryTextField} size="small" label="Checklist Name" {...validation.name?.fieldProps} />   
        </Grid> 

        <Grid item container alignItems="flex-end" className={classes.formRow}>
          <TextField id="docTypeName" value={docType?.name} disabled={true} fullWidth className={classes.textField} size="small" label="Document Type"/>   
        </Grid>      
      </Grid>
      <Grid item md={3} sm={5} container>
        {children}
      </Grid>

      <Grid container alignItems="flex-end" className={classes.formRow}>
        <TextField id="description" value={item.description} onChange={onChange} onBlur={onTextBlur} disabled={!isEditable || status.isWorking} 
          multiline minRows={3} fullWidth className={classes.textField} size="small" label="Description" error={validation?.description?.hasError}/>   
      </Grid> 

    </Grid>
  );
}

export default ChecklistEditorHeader;

const buildStyles   = makeStyles()(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  primaryTextField  : {
    "& input"   : {
      fontSize    : 24,
      fontWeight  : 300,
    },
  },
  growCol   : {
    flexGrow  : 1,
    paddingLeft   : theme.spacing(1),
  },
  collapseCol   : {
    padding     : `${theme.spacing(0)} ${theme.spacing(1)}`, //`
    borderRight : `1px solid ${theme.palette.grey[300]}`, //`
  },
  iconButton  : {
    marginRight   : theme.spacing(1),
  },
  actionButton  : {
    marginRight   : theme.spacing(1),
  },
  labelIcon   : {
    marginBottom  : theme.spacing(-0.5),
    marginRight   : theme.spacing(0.5),
  },  
  ...adminDialogClasses(theme),
}));