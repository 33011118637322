import { statusKeys } from 'store/api-middleware';
import { NO_OP } from 'store/store-helpers';
import { REPORT_API_PATHS } from 'utils/report-helpers';
import Apis, { downloadFile } from '../quanta-api';

const LOADING         = "REPORTS_LOADING";
const ERROR           = "REPORTS_ERROR";
const SET_DATA_LOADED = "REPORTS_SET_DATA_LOADED";
const CL_DATA_LOADED  = "REPORTS_CL_DATA_LOADED";

const DOWNLOADING   = "REPORTS_DOWNLOADING";
const DOWNLOADED    = "REPORTS_DOWNLOADED";
const DOWNLOAD_ERROR = "REPORTS_DOWNLOAD_ERROR";

export const loadSetForReport = (id) => async (dispatch, getState) => {

  const state   = getState().reports;
  if(state.set && state.set.id === id){
    //TODO: compare load time??
    return state.set;   //just return the existing item
  }
  else if(state.status.isLoading){
    return null;        //don't load if already loading
  }

  dispatch({type : LOADING });
  const result    = await Apis.Reports.get(`reviewsets/${id}`);

  if(result && result.ok){
    dispatch({type: SET_DATA_LOADED, id: id, data : result.data });
  }
  else{
    dispatch({type: ERROR, id: id, data: result });
  }
}

export const loadCheckListReportData = (setId, docId) => async (dispatch, getState) => {
  
  dispatch({type: LOADING});  

  const setP        = Apis.Reviewsets.get(`/${setId}`);
  const docP        = Apis.Documents.get(`/${docId}`)
  const [setR, docR]  = await Promise.all([setP, docP]);

  if(setR && docR && setR.ok && docR.ok){
    const result  = { set : setR.data, doc : docR.data };
    dispatch({type: CL_DATA_LOADED, data : result });
  }
  else{
    const badResult   = !setR.ok ? setR : docR;
    dispatch({type: ERROR, data: badResult });
  }
}

//NOTE: This action type ignored by the reducers. they're used byt the reviewset-report-button
// to check the status of a report that is in the queue, and no long-term state is necessary.
// const REPORT_STATUS_CHECKING = "REPORTS_CHECKING_STATUS";
const REPORT_STATUS_CHECKED = "REPORTS_CHECKED_STATUS";

export const createCheckReportStatusAction = (setId) => ({
  type: REPORT_STATUS_CHECKED,
  api: {
    endpoint: Apis.Reports,
    verb: "get",
    path: REPORT_API_PATHS.reviewSetStatus(setId),
    status: {
      statusType: NO_OP,  //no need to notify the reducer about the status change
      statusKey: statusKeys.reportStatus,
    }
  },
});

export const downloadReport = (report) => async (dispatch, getState) => {

  dispatch({type: DOWNLOADING, config: report});
  const result  = await Apis.Reports.get(report.url, { responseType: "arraybuffer"});

  if(result.ok){
    const isOk  = await downloadFile(result, "text/csv");
    if(!isOk){
      console.log("Download Report failed.");
    }
    dispatch({type: DOWNLOADED, data: result, config: report});
  }
  else{
    dispatch({type: DOWNLOAD_ERROR, error: result, config: report});
  }
}

export const REPORT_ACTIONS   = {
  LOADING     : LOADING,
  SET_DATA_LOADED  : SET_DATA_LOADED,
  CL_DATA_LOADED   : CL_DATA_LOADED,
  ERROR       : ERROR,
  DOWNLOADING : DOWNLOADING,
  DOWNLOADED  : DOWNLOADED,
  DOWNLOAD_ERROR : DOWNLOAD_ERROR,
  REPORT_STATUS_CHECKED,
};