import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import SchoolIcon from '@mui/icons-material/School';
import ArchiveIcon from '@mui/icons-material/Archive';
import { ItemList } from 'features/common';
import { useDialogs } from 'hooks';
import { loadEngagements } from 'store/actions/admin-actions';
import { selectStatusByKey, selectEngagements } from 'store/selectors/admin-selectors';
import ClienTableCellialog from '../dialogs/client-dialog';
import ProjecTableCellialog from '../dialogs/project-dialog';
import ProtocolDialog from '../dialogs/protocol-dialog';
import SiteDialog from '../dialogs/site-dialog';
import { TableCell, TableRow } from '@mui/material';

const EngagementsList = () => {
  const [isTraining, setIsTraining]   = useState(false);
  const status      = useSelector(state => selectStatusByKey(state, "engagements"));
  const engagements = useSelector(selectEngagements);
  const [dialogs, openDialog, closeDialog]  = useDialogs();
  const dispatch    = useDispatch();

  let items     = engagements?.items;
  if(isTraining){
    items   = _.filter(engagements?.items, eng => eng.isTraining);
  }

  function onRefresh(props, query){
    dispatch(loadEngagements(query, props.pageNumber, props.pageSize));
  }

  function onFiltered(option){
    //Note: server can't filter fully on training, so need to do that here 
    setIsTraining(option.key === "training");
  }

  const gridConfig  = {
    title     : "Engagements",
    baseUrl   : "/admin/engagements",
    searchPlaceholder   : "Search by name...",
    pageSizeKey : "admin-eng-list",
    keyFunc   : item => item.id,
    filterOptions   : [
      { key: 'all',       label: 'All',     },
      { key: 'current',   label: 'Current',  isDefault: true},
      { key: 'training',  label: 'Training', },
      { key: 'archived',  label: 'Archived', }
    ],
    onFiltered   : onFiltered,
    actions   : {
      onEditClient    : id => openDialog("client", id),
      onEditProject   : id => openDialog("project", id),
      onEditProtocol  : id => openDialog("protocol", id),
      onEditSite      : id => openDialog("site", id),
    },
    cols  : GRID_HEADER,
  }

  return (
    <>
      <ItemList 
        config={gridConfig} 
        items={items} 
        
        pagination={engagements?.pagination} 
        isWorking={status.isWorking}
        onRefresh={onRefresh}
        addUrl={"/admin/engagements/add"}
        RowComponent={EngagementListItem}
      />

      <ClienTableCellialog clientId={dialogs?.client} onClose={() => closeDialog("client")}/>
      <ProjecTableCellialog projectId={dialogs?.project} onClose={() => closeDialog("project")}/>
      <ProtocolDialog protocolId={dialogs?.protocol} onClose={() => closeDialog("protocol")}/>
      <SiteDialog siteId={dialogs?.site} onClose={() => closeDialog("site")}/>
    </>
  );
}

export default EngagementsList;

const buildStyles   = makeStyles()(theme => ({
  listCard  : {
    width       : "100%",
  },
  editButton  : {
    textTransform   : "none",
    fontWeight      : "700",
    padding         : 0,
    color           : "#337ab7",
    "& span"        : {
      textAlign       : "left",
      justifyContent  : "flex-start",
    },
  },
  itemCol   : {
    verticalAlign   : "middle !important",
    "& svg"   : {
      color         : theme.palette.grey[500],
    }
  },
}));

function EngagementListItem({item, actions}){
  const { classes }   = buildStyles();
  return (
    <TableRow>
      <TableCell className={classes.itemCol} sx={{minWidth: "55px"}}>{item.id}</TableCell>
      <TableCell className={classes.itemCol}>
        {item.isTraining && <Tooltip title="Training Engagement"><SchoolIcon fontSize="small" /></Tooltip>}
        {item.isArchived && <Tooltip title="Archived Engagement"><ArchiveIcon fontSize="small" /></Tooltip>}
      </TableCell>
      <TableCell className={classes.itemCol}>
        <Link to={`/admin/engagements/${item.id}`} className={classes.editButton} title="Click to view / edit engagement">{item.name || `Engagement ${item.id}`}</Link>
      </TableCell>
      <TableCell className={classes.itemCol}>
        <Button onClick={() => actions.onEditClient(item.client.id)} className={classes.editButton} title="Click to view / edit client">
          {item.client.name}
        </Button>
      </TableCell>
      <TableCell className={classes.itemCol}>
        <Button onClick={() => actions.onEditProject(item.project.id)} className={classes.editButton} title="Click to view / edit project">
          {item.project.title}
        </Button>
      </TableCell>
      <TableCell className={classes.itemCol}>
        <Button onClick={() => actions.onEditProtocol(item.protocol.id)} className={classes.editButton} title="Click to view / edit protocol">
          {item.protocol.name}
        </Button>
      </TableCell>
      <TableCell className={classes.itemCol}>
        <Button onClick={() => actions.onEditSite(item.site.id)} className={classes.editButton} title="Click to view / edit site">
          {item.site.name}
        </Button>
      </TableCell>
    </TableRow>
  );
}

const GRID_HEADER   = [
  {
    id        : 0,
    sortKey   : "id",
    defaulTableCellDir: "desc",
    label     : "Id",
    style     : {minWidth: "60px"},
  },
  {
    id        : 5,    //Archived / Training column
    label     : "",
    style     : {minWidth: "25px"},
    filters   : [
      { key: "current",   label: "Current Engagements", isDefault: true },
      { key: "all",       label: "All Engagements" },
      { key: "archived",  label: "Archived Engagements" },
      { key: "training",  label: "Training Engagements" },
    ],
  },
  {
    id        : 10,
    sortKey   : "name",
    label     : "Name",
    style     : {minWidth: "150px"},    
  },
  {
    id        : 20,
    sortKey   : "cli",
    label     : "Client",
  },
  {
    id        : 30,
    label     : "Project",
    sortKey   : "prj",
    // style     : {minWidth: "60px"},
  },
  {
    id        : 40,
    sortKey   : "prot",
    label     : "Protocol",
    // style     : {minWidth: "150px"},
  },
  {
    id        : 50,
    sortKey   : "site",
    label     : "Site",
    // style     : {minWidth: "150px"},
  },
  // {
  //   id        : 50,
  //   label     : "Actions",
  //   style     : {minWidth: "45px"},
  // }
];