import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BookIcon from '@mui/icons-material/BookOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import FilterIcon from '@mui/icons-material/Filter';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import Icon from '@mui/material/Icon';
import { useEngagement } from 'hooks';
import StatCard from './parts/engagement-stat-card';
import EngagementAreaChart from './parts/engagement-area-chart';
import EngagementIcon from '../home/engagement-icon';
import { IfBlock, WaitContainer } from 'features/common';
import { appRoutes } from 'utils/route-helpers';
import { loadEngagementReviewSetCounts, loadEngagementDocumentCounts } from 'store/actions/metrics-actions';
import { selectStatusByKey, selectEngagementReviewSetData, selectEngagementDocumentData } from 'store/selectors/metrics-selectors';
import { itemColors } from './engagements-config';
import { useBoolState } from 'hooks/general-hooks';
import EngagementChartsDialog from './parts/engagement-charts-dialog';
import { Link } from 'react-router-dom';

const EngagementDashboard = () => {

  const { classes }     = buildStyles();
  const engagement  = useEngagement();
  const dispatch    = useDispatch();
  const status      = useSelector(state => selectStatusByKey(state, "rsCounts"));
  const rsData      = useSelector(selectEngagementReviewSetData);
  const docData     = useSelector(selectEngagementDocumentData);

  const [isPopupOpen, togglePopup] = useBoolState(false);

  useEffect(() => {
    async function loadData(){
      const result    = dispatch(loadEngagementReviewSetCounts(engagement.id));
      const result2   = dispatch(loadEngagementDocumentCounts(engagement.id));
      await Promise.all([result, result2]);
    }
    if(!status.lastId || status.lastId !== engagement.id) loadData();
  }, [engagement.id, status.lastId, dispatch]);

  const onPopup = () => {
    togglePopup();
  }

  return (
        <Grid id="engagement-dashboard" container direction="column" className={classes.dashboardRoot}>
          <Grid className={classes.top}>
            
            <Grid container className={classes.headerRow}>
              <Grid item sm={6} xs={12} container justifyContent="flex-start" alignItems="flex-start" wrap="nowrap" className={classes.titleGrid}>
                  <EngagementIcon engagement={engagement} />
                  <Typography component="p" className={classes.title}>
                    {engagement.name}
                  </Typography>
                <Typography variant="h6" className={classes.subTitle}>
                  {engagement.description}
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12} container spacing={1} className={classes.titleActions} alignItems="center">
                <Button className={classes.linkButton} title="Home" variant="outlined" size="small" component={Link} to="/"><HomeIcon fontSize="small"/></Button>
                <Button className={classes.linkButton} title="Engagement ReviewSets" variant="outlined" size="small" component={Link} to={appRoutes.engagements.reviewsets} startIcon={<BookIcon />}>ReviewSets</Button>
                <Button className={classes.linkButton} title="Engagement Documents" variant="outlined" size="small" component={Link} to={appRoutes.engagements.documents} startIcon={<DescriptionIcon />}>Documents</Button>
                <Button className={classes.linkButton} title="Engagement Reports" variant="outlined" size="small" component={Link} to={appRoutes.engagements.reports} startIcon={<AssignmentIcon />}>Reports</Button>
                <Button className={classes.linkButton} title="Chart Popup" variant="outlined" size="small" onClick={onPopup}><FilterIcon fontSize="small" /></Button>
              </Grid>
            </Grid>

              <Grid item container direction="row">
                <WaitContainer isWaiting={status.isWorking} className={classes.waitContainer} message="Loading dashboard data...">
                  <Grid item sm={6} container justifyContent="center">
                    <EngagementAreaChart chartData={rsData?.chartData}/>
                    <Typography className={classes.chartTitle}><Icon className="material-icons-outlined">book_outlined</Icon>ReviewSets</Typography>
                  </Grid>
                  <Grid item sm={6} container justifyContent="center">
                    <EngagementAreaChart chartData={docData?.chartData} />
                    <Typography className={classes.chartTitle}><Icon className="material-icons-outlined">description_outlined</Icon>Documents</Typography>
                  </Grid>
                </WaitContainer>
              </Grid>

          </Grid>

          <Grid container className={classes.widgetRow} spacing={4}>
            <IfBlock condition={!status.isWorking}>
              <Grid item xs={12} sm={6} container justifyContent="center">
                <Grid container direction="column" spacing={4} justifyContent="center" style={{width: "75%"}}>
                  <StatCard title="Total ReviewSets" color={itemColors.created} icon={<BookIcon fontSize="small"/>} stat={{curr: rsData?.total.toLocaleString()}} to={appRoutes.engagements.reviewsets} />
                  <StatCard title="Approved ReviewSets" color={itemColors.approved} icon={<BookIcon fontSize="small"/>} stat={{curr: rsData?.approved.toLocaleString()}} to={`${appRoutes.engagements.reviewsets}?filter=status:approved`}/>
                  {rsData?.hasPre &&
                  <StatCard title="PreApproved ReviewSets" color={itemColors.pending} icon={<BookIcon fontSize="small"/>} stat={{curr: rsData?.preApproved.toLocaleString()}} to={`${appRoutes.engagements.reviewsets}?filter=status:preapproved`}/>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="center">
                <Grid container direction="column" spacing={4} justifyContent="center" style={{width: "75%"}}>
                  <StatCard title="Total Documents" color={itemColors.created} icon={<DescriptionIcon fontSize="small"/>} stat={{curr: docData?.total.toLocaleString()}} to={appRoutes.engagements.documents}/>
                  <StatCard title="Approved Documents" color={itemColors.approved} icon={<DescriptionIcon fontSize="small"/>} stat={{curr: docData?.approved.toLocaleString()}} to={`${appRoutes.engagements.documents}?filter=status:approved`}/>
                  {docData?.hasPre &&
                    <StatCard title="PreApproved Documents" color={itemColors.pending} icon={<DescriptionIcon fontSize="small"/>} stat={{curr: docData?.preApproved.toLocaleString()}} to={`${appRoutes.engagements.documents}?filter=status:preapproved`}/>
                  }
                </Grid>
              </Grid>
            </IfBlock>
          </Grid>

          <EngagementChartsDialog isOpen={isPopupOpen} onClose={togglePopup} status={status} rsData={rsData} docData={docData} engagement={engagement}/>
        </Grid>
  );
}

export default EngagementDashboard;

const buildStyles   = makeStyles()(theme => ({
  dashboardRoot  : {
    marginLeft      : theme.spacing(-2),
    width           : `calc(100% + ${theme.spacing(4)})`,
    // background      : theme.palette.grey[50],
  },
  top   : {
    background      : theme.palette.primary.main,
    minHeight       : 400,
    padding         : theme.spacing(2),
    paddingBottom   : theme.spacing(12),
    [theme.breakpoints.down('md')] : {
      minHeight: "auto",
    }
  },
  widgetRow   : {
    padding         : theme.spacing(2),
    marginTop       : theme.spacing(-9),
  },
  titleGrid: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    },
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginLeft  : theme.spacing(1),
    marginBottom: theme.spacing(1),
    color       : theme.palette.common.white,
    "& svg, .MuiIcon-root"   : {
      marginRight   : theme.spacing(1),
      marginBottom  : -5,
    },    
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    marginBottom  : theme.spacing(3),
    color     : theme.palette.common.white,
  },
  titleActions : {
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      padding: `${theme.spacing(0.5)} ${theme.spacing(0)}`, //`
    }
  },
  chartTitle  : {
    fontSize    : 20,
    fontWeight  : 400,
    marginLeft  : theme.spacing(1),
    marginTop   : theme.spacing(2),
    marginBottom: theme.spacing(-1),
    color       : theme.palette.common.white,
    "& svg, .MuiIcon-root"   : {
      fontSize      : 22,
      fontWeight    : 300,
      marginRight   : theme.spacing(1),
      marginBottom  : theme.spacing(-0.5),
      color       : theme.palette.grey[200],
    },
  },
  linkButton  : {
    marginLeft    : theme.spacing(1),
    color         : theme.palette.common.white,
    borderColor   : theme.palette.grey[400],
    "&:hover"   : {
      color       : theme.palette.common.white,
      background  : `${theme.palette.common.white}3`,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    }
  },
  waitContainer   : {
    width     : "35%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop   : 65,
    color     : theme.palette.grey[200],
  }
}));

// function calcMetric(key, data){
//   if(!data) return null;
//   const curr  = data[0] || {}, prev   = data[1] || {};
//   let currVal = 0, prevVal = 0;

//   if(_.isString(key)){
//     currVal   = curr[key];
//     prevVal  = prev[key];
//   }
//   else if(_.isFunction(key)){
//     currVal   = key(curr);
//     prevVal  = key(prev);    
//   }

//   const diff  = currVal - prevVal;
//   let pctDiff = (currVal - prevVal) / (prevVal || 1);
//   pctDiff     = (pctDiff < 0 ? pctDiff * -100 : pctDiff * 100).toFixed(0);
//   // pctDiff     = (pctDiff).toFixed(2);
//   return {
//     curr      : currVal,
//     prev      : prevVal,
//     diff      : diff,
//     pctDiff   : diff === 0 ? "no change" : `${pctDiff}%`,
//     direction : diff > 0 ? "up" : (diff === 0 ? "even" : "down"),
//   };
// }