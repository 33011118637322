import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { toastr } from 'react-redux-toastr';
import ChecklistEditorHeader from './parts/checklist-editor-header';
import ChecklistEditorBody from './parts/checklist-editor-body';
import { useBoolState } from 'hooks/general-hooks';
import { WaitContainer, ErrorDisplay, WaitButton, ConfirmDialog } from 'features/common';
import { selectChecklist, selectStatus } from 'store/selectors/builder-selectors';
import { selectFullDocType } from 'store/selectors/admin-selectors';
import { loadDocType } from 'store/actions/admin-actions';
import { loadChecklist, startChecklist, saveCheckList, clearStatus, reloadChecklist } from 'store/actions/builder-actions';
import { getChangedItems, hasGhosts, canSaveChecklist } from 'utils/checklist-helpers';
import { Chip } from '@mui/material';
import { isTrue } from 'utils/general-helpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const BuilderView = () => {
  const { classes }     = buildStyles();
  const dispatch    = useDispatch();
  const { checklistId: id, action }   = useParams();
  const { search }  = useLocation();
  const navigate = useNavigate();
  const status      = useSelector(state => selectStatus(state, "checklists"));
  const working     = useSelector(state => selectChecklist(state, id));
  const original    = useSelector(state => state.builder.original);
  const docType     = useSelector(state => selectFullDocType(state, working?.documentTypeId));
  const [isConfirming, toggleConfirming]  = useBoolState(false);
  const isDraft      = useMemo(() => isTrue(docType?.propertyBag?.isDraft), [docType]);

  //Determin if the document type is in use
  const inUse = useMemo(() => { return !docType ? null : Boolean(docType && docType.documentsCount > 0); }, [docType]);
  const isNew = useMemo(() => Boolean(id <= 0), [id]);
  const canSave = useMemo(() => canSaveChecklist(working), [working]);

  useEffect(() => {
    if(inUse === true){
      //this doc type is in use, so need to redirect to the print view
      navigate(`/admin/checklists/${id}/print`, { replace: true });
    }
  }, [id, inUse, navigate]);

  //Editable is determined by the url and the document type...
  const isEditable  = useMemo(() => {
    if(action === "edit" && !!docType && !inUse) {
      console.log("doc type editable", action, docType?.name, inUse);
      return true;
    }
    else{
      console.log("doc type NOT editable", action, docType?.name, inUse);
      return false;
    }
  }, [action, docType, inUse]);

  //effect to get doctype and determine if we're editable or not
  useEffect(() => {
    const docTypeId   = original?.documentTypeId;
    if(docTypeId && !docType){

      async function getDocType(){
        await dispatch(loadDocType(docTypeId));        
      }
      getDocType();
    }
  }, [original, docType, dispatch]);

  //effect to load the full version of the checklist
  useEffect(() => {
    if(id > 0 && (!working || working.id !== id)){
      async function load(){
        return await dispatch(loadChecklist(id));
      }
      load();
    }
    else if(id === "-1"){
      //get the doctype from the QueryString
      const urlP  = new URLSearchParams(search);
      const docTypeId   = parseInt(urlP.get("doctype"));
      dispatch(startChecklist(docTypeId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  //message to display when a user closes the checklist and there are pending changes
  const closePrompt   = useMemo(() => {
    return (
      <div>
        Would you like to save your changes?
        <ul className={classes.confirmList}>
          <li><label className={classes.liPrime}>Yes:</label>save changes and close</li>
          <li><label className={classes.liPrime}>No:</label>lose changes and close</li>
          <li><label className={classes.liPrime}>Cancel:</label>don't close</li>
        </ul>
      </div>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  function onClearError(){
    dispatch(clearStatus("builder"));
    dispatch(clearStatus("checklists"));
  }

  function onPrint(){
    if(isNew) return;
    navigate(`/admin/checklists/${id}/print`);
  }
  
  function doClose(){
    navigate("/admin/documenttypes?filter=current");
  }

  function onClose(){
    const hasChanges  = isChanged(original, working);
    if(hasChanges){
      toggleConfirming();
      return;
    }
    else{
      doClose();
    }
  }

  function undoAndClose(){
    toggleConfirming();
    dispatch(reloadChecklist());
    doClose();
  }

  async function onSave(andClose = false){
    const oModel  = _.pick(original, ["id", "documentTypeId", "name", "description", "propertyBag"]);
    const wModel  = _.pick(working, ["id", "documentTypeId", "name", "description", "propertyBag"]);

    let model   = {
      ...oModel,
      ...wModel
    };

    model.checkListItems  = getChangedItems(original, working);
    //Make sure there are changes to save
    if(shallowEqual(oModel, wModel) && (!model.checkListItems || model.checkListItems.length === 0)){
      //Check for "ghost" items - items that have been added then deleted within the same session.
      if(hasGhosts(original, working)){
        //Just need to trigger a refresh in the reducer...
        await dispatch(reloadChecklist());
        toastr.success("Changes saved successfully");
        if(andClose) doClose();
        return;
      }

      toastr.warning("There are no changes to save.");
      if(andClose) doClose();
      return;
    }

    const result  = await dispatch(saveCheckList(original.id, model));
    if(result.ok){
      toastr.success("Checklist Saved Successfully");
      if(wModel.id < 0){
        //If it's a new list, need to switch to the edit view for this item
        const newId   = result.response.id;
        navigate(`/admin/checklists/${newId}/edit`, { replace: true });  //`
      }
    }
    else{
      //deal with failure
      console.error("Failed to save checklist.", result);
      // toastr.error("Checklist failed to save");
    }

    if(andClose === true) doClose();
    return;
  }

  return (
    <Grid id="data-view" container justifyContent="center" className={classes.root}>

      <ErrorDisplay error={status.error} onClose={onClearError}></ErrorDisplay>

      <Grid item xs={12}>
        <WaitContainer isWaiting={!working || !docType} message="Working...">
          {working && 
            <ChecklistEditorHeader checklist={working} isEditable={isEditable}>
              <Grid container justifyContent="flex-end" alignItems="flex-start">
                <Grid container>
                  {isEditable && <WaitButton color="primary" className={classes.actionButton } endIcon={<SaveIcon />} isWaiting={status.isWorking} disabled={!canSave || status.isWorking} onClick={() => onSave(false)} title="Save changes">Save</WaitButton>}
                  <IconButton
                    color="primary"
                    onClick={onPrint}
                    className={classes.icoButton}
                    disabled={status.isWorking || isNew}
                    title="Print this Checklist"
                    size="large"><PrintIcon fontSize="small"/></IconButton>
                  <IconButton
                    color="default"
                    className={classes.icoButton}
                    onClick={onClose}
                    disabled={status.isWorking}
                    title="Close this Checklist"
                    size="large"><CloseIcon fontSize="small"/></IconButton>
                </Grid>
                <Grid container justifyContent="center" alignItems="flex-start">
                  {isDraft && <Chip className={classes.statusChip} icon={<EditIcon fontSize="small"/>} label="Draft" title="This is a draft Checklist"/> }
                </Grid>
              </Grid>
            </ChecklistEditorHeader>
          }
          {working && <ChecklistEditorBody checklist={working} isEditable={isEditable} />}
        </WaitContainer>        
      </Grid>

      {isEditable && 
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={12} container justifyContent="flex-end">
            {isEditable && 
              <WaitButton color="primary" onClick={() => onSave(false)} endIcon={<SaveIcon />} disabled={!canSave || status.isWorking} isWaiting={status.isWorking} title="Save changes">
                Save
              </WaitButton>
            } 
            <IconButton
              color="primary"
              onClick={onPrint}
              className={classes.icoButton}
              disabled={status.isWorking || isNew}
              title="Print this Checklist"
              size="large"><PrintIcon fontSize="small"/></IconButton>
            <IconButton
              color="default"
              className={classes.icoButton}
              onClick={onClose}
              disabled={status.isWorking}
              title="Close this Checklist"
              size="large"><CloseIcon fontSize="small"/></IconButton>
          </Grid>
          <ConfirmDialog open={isConfirming} title="Close checklist" prompt={closePrompt} onYes={() => onSave(true)} onNo={undoAndClose} onCancel={toggleConfirming} isBusy={status.isWorking}/>
        </Grid>
      }
    </Grid>
  );
}

export default BuilderView;

const buildStyles   = makeStyles()(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  headerRow   : {
    flexGrow    : 1,
    marginTop   : theme.spacing(-2),
    marginBottom: theme.spacing(1),
  },
  growCol   : {
    flexGrow  : 1,
  },   
  subtle  : {
    color     : theme.palette.grey[400],
  },
  headerText  : {
    marginLeft  : theme.spacing(0.5),
    display     : "inline-block",
    fontSize    : 24,
  },
  statusChip   : {
    marginLeft  : theme.spacing(2),    
  },
  confirmList   : {
    listStyle   : "none",
    paddingLeft  : theme.spacing(1),
    marginTop   : theme.spacing(1),
  },
  liPrime   : {
    width     : 75,
  }
}));

function isChanged(original, working){
  const oModel  = _.pick(original, ["id", "documentTypeId", "name", "description"]);
  const wModel  = _.pick(working, ["id", "documentTypeId", "name", "description"]);
  
  let model   = {
    ...oModel,
    ...wModel
  };

  model.checkListItems  = getChangedItems(original, working);
  
  //Determine if there are changes to save
  const propsSame = shallowEqual(oModel, wModel);
  return (!propsSame || model.checkListItems?.length > 0);
}