import { SET_ACTIONS } from '../actions/reviewset-actions';
import { DOC_ACTIONS } from '../actions/document-actions';
import { DOCS_ACTIONS } from '../actions/documents-actions';
import { createReducer } from 'utils/reducer-helper';

const INITIAL_STATE = {
  error               : null,
  isLoading           : false,
  isWorking           : false,
  item                : null,

  itemTime            : null,
  isSaving            : false,  
  
  itemStatus          : {
    itemTime      : null,
    isLoading     : false,
    isWorking     : false,
    isSaving      : false,
    isDocChanged  : false,
    error         : null,
  },

  approving           : { isWorking: false, error: null },
};

function itemLoading(state, action){
  return {
    ...state,
    error       : null,
    isLoading   : true,
    itemStatus  : {
      ...state.itemStatus,
      isLoading   : true,
      error       : null,
    },
  };
}

function itemLoaded(state, action){
  return {
    ...state,
    isLoading       : false,
    error           : null,
    item            : action.response,
    itemTime        : new Date(),
    itemStatus  : {
      ...state.itemStatus,
      isLoading   : false,
      error       : null,
      itemTime    : new Date(),
      isDocChanged: false,
    },
  }
}

function error(state, action){
  return {
    ...state,
    isLoading         : false,
    error             : action.response,
    item              : null,
    itemTime          : null,
    itemStatus  : {
      ...state.itemStatus,
      isLoading   : false,
      error       : action.response,
      itemTime    : null,
    },
  };
}

function itemSaving(state, action){
  return {
    ...state,
    error       : null,
    isSaving    : true,
    itemStatus  : {
      ...state.itemStatus,
      isSaving    : true,
      error       : null,
    },
  };
}

function itemSaved(state, action){
  return {
    ...state,
    isSaving      : false,
    item          : {
      ...state.item,
      ...action.data,
    },
    itemTime      : new Date(),
    itemStatus  : {
      ...state.itemStatus,
      isSaving    : true,
      itemTime    : new Date(),
    },
  };
}

function itemCreated(state, action){
  return {
    ...state,
    isSaving      : false,    //TODO: remove, per itemStatus
    item          : null,     //leave it empty so it is loaded correctly
    itemTime      : null,     //TODO: remove, per itemStatus
    itemStatus  : {
      ...state.itemStatus,
      isSaving    : true,
      error       : null,
      itemTime    : null,
    },
  };
}

function itemSaveFailed(state, action){
  return {
    ...state,
    isSaving      : false,
    error         : action.response,
    itemStatus  : {
      ...state.itemStatus,
      isSaving    : false,
      error       : action.response,
    },
  };
}

function itemApproving(state, action){
  return {
    ...state,
    approving     : { isWorking: true, error: null },
  };

}

function itemApproved(state, action){
  //The response is the updated ReviewSet, so just replace it
  return {
    ...state,
    item    : action.response,
    approving     : { isWorking: false, error: null },
  };
}

function itemApproveFailed(state, action){
  return {
    ...state,
    approving     : { isWorking: false, error: action.response },
  };
}

function itemApproveClear(state, action){
  return {
    ...state,
    approving: {isWorking: false, error: null },
  };
}

function docChanged(state, action){
  return {
    ...state,
    itemStatus  : {
      ...state.itemStatus,
      isDocChanged  : true,
    }
  }
}

//----------------------
// The action map that is turned into the reducer
const setsReducer    = {

  [SET_ACTIONS.ITEM_LOADING]     : itemLoading,
  [SET_ACTIONS.ITEM_LOADED]      : itemLoaded,
  [SET_ACTIONS.ITEM_ERROR]       : error,

  [SET_ACTIONS.ITEM_SAVING]      : itemSaving,
  [SET_ACTIONS.ITEM_SAVED]       : itemSaved,
  [SET_ACTIONS.ITEM_CREATED]     : itemCreated,
  [SET_ACTIONS.ITEM_SAVE_FAILED] : itemSaveFailed,

  [SET_ACTIONS.APPROVING]         : itemApproving,
  [SET_ACTIONS.APPROVED]          : itemApproved,
  [SET_ACTIONS.APPROVE_FAILED]    : itemApproveFailed,
  [SET_ACTIONS.APPROVE_CLEARED]   : itemApproveClear,

  //Recognize changes in the document
  [DOC_ACTIONS.CHECKLIST_APPROVED]  : docChanged,
  [DOCS_ACTIONS.CREATED]            : docChanged,
  [DOCS_ACTIONS.REMOVED]            : docChanged,
};

//The reducer
export default createReducer(INITIAL_STATE, setsReducer);