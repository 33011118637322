import React from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Grid, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'features/common';
import { CheckList } from './checklists';
import { checkListSelected } from 'store/actions/document-actions';

function DocumentBody(){
  const { classes }             = buildStyles();
  const dispatch            = useDispatch();
  const checkLists          = useSelector(state => state.doc.itemCheckLists);
  const index               = useSelector(state => state.doc.checkListIndex);

  const setIndex = (e, i) => {
    return dispatch(checkListSelected(i));
  }
  
  // console.log("** document body rendered");

  //-- Gets the label and possibly the tooltip for the tab.  Handles names that are too long to appear cleanly as a tab label.
  const getCheckListProps = (checklist) => {
    if(checklist.name.length > 50){
      return {
        label: `${checklist.name.slice(0, 47)}...`,
        title: checklist.name,
      };
    }
    else{
      return { label: checklist.name }
    }
  }

  return (
    <Grid id="document-body" container spacing={2} className={classes.root}>
      <Tabs className={classes.tabs} value={index} onChange={setIndex}>
        {_.map(checkLists, cl => <Tab key={cl.id} {...getCheckListProps(cl)} sx={{fontSize: "0.9rem"}}/>)}
      </Tabs>
      <Grid item container className={classes.content}>
        {_.map(checkLists, (cl, idx) => 
          <TabPanel id={cl.id} key={cl.id} value={index} index={idx}>
            <CheckList checkList={cl} />
          </TabPanel>)
        }
      </Grid>

    </Grid>
  );
}

export default DocumentBody;

const buildStyles   = makeStyles()(theme => ({
  root  : {
    margin  : `0 ${theme.spacing(2)}`,
  },
  tabs  : {
    width     : "100%",
    "& span"  : {
      fontSize    : "12px",
    }
  },
}));