import _, { compact, filter, reverse, sortBy, uniq } from 'lodash';
// import blue from '@mui/material/colors/blue';
import { red, yellow } from '@mui/material/colors';
import { shallowEqual } from 'react-redux';

export const INP_TYPES  = {
  bool    : "Bool",
  text    : "Text",
  comment : "Comment",
  option  : "Option",
};

export const ITEM_TYPES   = {
  assertion : "Assertion",
  section   : "Section",
};

export const APPROVE_TYPES  = {
  approve   : "APPROVE",
  preApprove: "PREAPPROVE",
  unApprove : "UNAPPROVE",
};

export function isComment(input){
  return input?.discriminator === INP_TYPES.comment;
}
export function isBool(input){
  return input?.discriminator === INP_TYPES.bool;
}
export function isText(input){
  return input?.discriminator === INP_TYPES.text;
}
export function isOption(input){
  return input?.discriminator === INP_TYPES.option;
}

export function isCommentRequiredOnReject(input){
  return isComment(input) && Boolean(input.propertyBag?.isRequiredOnReject);
}

//Gets the status record of the checklist with the specific status type requested
export const getCheckListStatusRecord = function(reviewRecord, checkListId, statusType = 'APPROVE'){
    
  if(_.isArray(statusType)){
      return _.filter(reviewRecord.checkListStatuses, cls => cls.checkListId === checkListId && statusType.indexOf(cls.status) >= 0);
  }

  return _.find(reviewRecord.checkListStatuses, {checkListId: checkListId, status: statusType});
}

//Gets the status of this checklist
// 0: pending (unapproved)
// 50: pre-approved
// 100: approved
export const getCheckListStatus = function(list, rrec){
  const statuses      = getCheckListStatusRecord(rrec, list.id, ['APPROVE', 'PREAPPROVE']);
  if(!statuses || statuses.length === 0) return 0;
  const final         = _.find(statuses, {status: 'APPROVE'});

  if(final){
      return 100;
  }
  else{
      const pre       = _.find(statuses, {status: 'PREAPPROVE'});
      return Boolean(pre) ? 50 : 0;
  }
};

//-----------------
// NOTE: Only want to use this once in the reducer, so it is applied universally...
//-----------------
export function prepareCheckList(list){
  const result  = {
    ...list,
    checkListItems  : addCheckListItemKeys(list.checkListItems),
  };

  return result;
}

//-----------------
// Will enumerate a list of CheckListItems and add keys to the items based on the hierarchy and relationships.
// Returns a new list of CheckListItems with the keys included.
//-----------------
export function addCheckListItemKeys(items){
  if(items && items.length > 0 && items[0].key) return items;

  let sectionKey     = 0;
  let assertionKey   = 0;

  const sections  = _.filter(items, item => isSection(item));
  const ordered   = _.orderBy(sections, ["ordinal"], ["asc"]);
  const output    = _.flatMap(ordered, section => {
      let me          = _.merge({}, section, {key: `${++sectionKey}`, isSection: true, isAssertion: false});
      let kids        = _.filter(items, {parentId: me.id});
      kids            = _.orderBy(kids, ["ordinal"], ["asc"]);
      assertionKey    = 0;        //reset the assertion key
      kids            = _.map(kids, kid => _.merge({}, kid, {key: `${sectionKey}.${++assertionKey}`, isSection: false, isAssertion: true}));
      me.kids         = _.map(kids, k => k.id); //the kids array is just a list of ids
      return [me, ...kids];
  });

  return output;
}

//-----------------
// Will prepare an item to be displayed / used in a checklist.
//  used by the AssertionItem component to shape the information as necessary
//-----------------
export function prepareItem(item, doc){
  const responses   = doc.primaryReviewRecord.checkListResponses;
  const inputIds    = _.map(item.checkListInputs, inp => inp.id);
  const boolInput   = _.find(item.checkListInputs, inp => inp.discriminator === "Bool");
  const myResponses = _.compact(_.map(inputIds, id => _.find(responses, rsp => rsp.checkListInputId === id)));
  const bResponse   = _.find(myResponses, rsp => rsp.checkListInputId === boolInput.id);

  let props   = {
    inputs          : item.checkListInputs,
    responses       : myResponses,
    primaryInput    : boolInput,
    primaryResponse : bResponse,
    isFinished      : Boolean(inputIds.length === myResponses.length),
    isConfirmed     : Boolean(bResponse && bResponse.boolResponse === true),
    isRejected      : Boolean(bResponse && bResponse.boolResponse === false),
    isNa            : Boolean(bResponse && bResponse.notApplicable === true),
    isEditable      : doc.statusCode === 0 && doc.isPrimary,   //only editable if the doc is pending and this is the primary review record
    labels          : doc.inputLabels,
  };

  props.className    = props.isConfirmed ? "confirmed" : (props.isRejected ? "rejected" : (props.isNa ? "na" : ""));
  return props;
}
//-----------------
// Will look at a CheckListItem and determine if it is a Section or not.
// Returns true if the item is a section, otherwise false
//-----------------
export function isSection(item){
  if(item.isSection === undefined){
      const itemType          = (item.discriminator || '').toLowerCase();
      const isSection         = Boolean(itemType === 'section');
      return isSection;
  }
  else{
      return item.isSection;
  }
}

export const responseMap   = {
  base        : (input, response) => {
    return {
      isUpdate          : Boolean(response?.id),
      checkListInputId  : input.id,
      id                : response ? response.id : undefined,   //NOTE: null doesn't work, needs to be undefined     
      discriminator     : input.discriminator,
      //CheckListId and ReviewRecordId are added up the chain by the assertion item
    }
  },
  confirm     : {
    isDelete      : false,
    boolResponse  : true,
  },
  reject     : {
    isDelete      : false,
    boolResponse  : false,
  },
  na      : {
    isDelete      : false,
    boolResponse  : null,
    notApplicable : true,
  },
  delete  : {
    isDelete      : true,
    boolResponse  : null,
    notApplicable : null,
  },
  comment   : (value) => {
    return {
      discriminator : "Comment",
      isDelete      : false,
      textResponse  : value,
    }
  },
  text   : (value) => {
    return {
      discriminator : "Text",
      isDelete      : false,
      textResponse  : value,
    }
  },
};

//----------------
// Generates styles for the status classes of an assertion / item
//
export function statusBackgrounds(theme){
  return {
    "&.confirmed"   : {
      background      : theme.statuses.confirmed.background, //blue[50],
    },
    "&.rejected"   : {
      background      : red[50],
    },
    "&.na"   : {
      background      : yellow[50],
    },
  };
}

//----------
// Called by document-reducer to sort through the check list status records and return
// the record(s) that are relevant to the reducer
export function reduceCheckListStatuses(reviewRecord){
  if(!reviewRecord.checkListStatuses || reviewRecord.checkListStatuses.length === 0) return [];

  //deal with multiple checklists
  const statuses = reviewRecord.checkListStatuses;
  const checklistIds = uniq(statuses.map(cls => cls.checkListId));
  
  //Need to see if the most recent status is disapproved, in which case the checklist is disapproved
  const nonDisapproved = checklistIds.flatMap(clId => {
    const filtered = statuses.filter(s => s.checkListId === clId);
    const sorted  = sortBy(filtered, cls => new Date(cls.statusDate));
    const ordered = reverse(sorted);
    if(ordered[0].status === "DISAPPROVE") return null;
    else return filter(ordered, cls => cls.status !== "DISAPPROVE");  //otherwise, just return all non-disapprove items  
  });

  return compact(nonDisapproved);
  
}

export const defaultInputs = {
  bool : {
    id                : -1,
    discriminator     : "Bool",
    ordinal           : 0,
    booleanAllowFalse : true,
    caption           : "",
    allowNA           : false,
    toolTip           : "",
  },

  text : {
    id                : -1,
    ordinal           : 1,
    discriminator     : "Text",
    caption           : "",
    textEmptyMessage  : "",
    toolTip           : "",
    textMultiLine     : true,
    textMaxLength     : 0,
  },

  option : {
    id                : -1,
    ordinal           : 2,
    discriminator     : "Option",
    optionGroupName   : "group_1",
    optionType        : 2,
    caption           : "",
    toolTip           : "",
    propertyBag       : {
      choices     : "",
    },
  },  
};

export const defaultSection = (id) => ({
  id                  : id,
  ordinal             : 0,
  discriminator       : "Section",
  sectionName         : null,
  sectionDescription  : null,
  parentId            : null,
  checkListInputs     : [],
  isExpanded          : true,  
});

export const defaultAssertion = (parentId, id) => ({
  id                  : id,
  ordinal             : 1,
  discriminator       : "Assertion",
  assertionText       : null,
  parentId            : parentId,
  checkListInputs     : [{...defaultInputs.bool}],
});

export const defaultChecklist = (docTypeId) => ({
    id              : -1,
    name            : "",
    documentTypeId  : docTypeId,
    description     : "",
    checkListItems  : [{...defaultSection(-1)}],
});

export function createWorkingChecklist(original, previouslyExpanded){
  //Get the items, and if there are ones that were previously expanded, flag them accordingly
  let items   = _.orderBy(original.checkListItems, i => i.ordinal);
  if(_.isArray(previouslyExpanded) && previouslyExpanded.length > 0){
    items   = _.map(items, i => {
      //Need to check id, but also name in case it was a new item that now has a real Id.
      const match   = _.find(previouslyExpanded, p => p.id === i.id || p.sectionName === i.sectionName);
      return !!match ? {...i, isExpanded: true} : i;
    });
  }

  //Get the protocol information so I know what the confirm/reject labels should be
  const cLabel  = original.documentType?.protocol?.propertyBag?.qConfirmLabel || "Confirm";
  const rLabel  = original.documentType?.protocol?.propertyBag?.qRejectLabel || "Reject";

  return {       //working version, to be modified
    id            : original.id,
    documentTypeId: original.documentTypeId,
    name          : original.name || "",
    description   : original.description || "",
    props         : {
      confirmLabel  : cLabel,
      rejectLabel   : rLabel,
    },
    checklistItems: items,
    propertyBag: original.propertyBag
    // isDraft: Boolean(original.propertyBag?.isDraft)
  }
}

const sectionFieldList  = ["id", "discriminator", "sectionName", "sectionDescription", "ordinal", "checkListId"];
const assertFieldList   = ["id", "discriminator", "assertionText", "parentId", "ordinal", "propertyBag", "checkListId", "checkListInputs"];
const inputFieldList    = ["id", "discriminator", "caption", "toolTip", "allowNA", "allowNotes", "booleanAllowFalse", "checkListId", "checkListItemId", "ordinal", "propertyBag", "textMultiLine", "textMaxLength", "textEmptyMessage", "optionType", "optionAllowMultiple", "optionGroupName"];

export function getChangedItems(original, working){
  const oItems  = original.checkListItems;
  const wItems  = working.checklistItems;

  let deleted   = _.filter(wItems, i => i.isDeleted);
  //remove any children from removed sections
  const deletedSectionIds   = _.reduce(deleted, (result, d) => {return isSection(d) ? [...result, d.id] : result }, []);
  const deletedChildren     = _.filter(wItems, i => deletedSectionIds.indexOf(i.parentId) >= 0)
  deleted       = [...deleted, ...deletedChildren];
  deleted       = _.map(deleted, d => { return {..._.pick(d, assertFieldList), propertyBag: {isDeleted: true}}}); //flag them as deleted

  const deletedIds  = _.map(deleted, d => d.id);

  //Before the next phase, Update all the ordinals, to remove any gaps (due to delete items...)
  let ord   = 0;
  const wOrdered    = _.compact(_.map(wItems, i => {
    if(i.isDeleted || deletedIds.indexOf(i.id) >= 0) return null;
    else return {...i, ordinal: ord++ };
  }));

  //items that have been added (and aren't in the deleted list)
  const added   = _.map(_.filter(wOrdered, i => i.id < 0), a => _.pick(a, [...assertFieldList, ...sectionFieldList]));  // could be either

  //find items that have been changed (and aren't in the deleted list)
  let changed = _.map(_.filter(wOrdered, w => w.id > 0), w => {
    const o   = _.find(oItems, i => i.id === w.id);
    const c   = getChangedItem(o, w);
    return c;
  });
  changed   = _.compact(changed);   //remove all nulls

  const allChanges  = [...added, ...changed, ...deleted];

  //filter out all deleted items that aren't saved yet
  const allValid  = _.filter(allChanges, c => c.id >= 0 || (!c.propertyBag || !c.propertyBag?.isDeleted));
  return allValid;
}

function getChangedItem(original, working){
  if(isSection(original)){
    const o   = _.pick(original, sectionFieldList);
    const w   = _.pick(working, sectionFieldList);
    return shallowEqual(o, w) ? null : w;
  }
  else{
    const o   = _.pick(original, assertFieldList);
    const w   = _.pick(working, assertFieldList);

    const modelEqual   = shallowEqual(o, w);
    const inpChanged   = hasChangedInputs(o, w);

    if(!modelEqual || inpChanged){
      let result  = w;
      // result.checkListInputs  = _.map(w.checkListInputs, i => _.pick(i, inputFieldList));
      return result;
    }
    else{
      return null;
    }
  }
}

function hasChangedInputs(original, working){
  if(original.checkListInputs.length !== working.checkListInputs.length) return true; //an input has been removed or added
  const newInp = _.find(working.checklistInputs, inp => inp.id < 0);
  if(!!newInp) return true;    //an input has been added (might be true if they removed and added an item)
  
  //Check for changes
  const c   = _.find(original.checkListInputs, oi => {
    const wi  = _.find(working.checkListInputs, i => i.id === oi.id);
    const isChanged   = !shallowEqual(_.pick(oi, inputFieldList), _.pick(wi, inputFieldList));
    return isChanged;
  });

  return !!c;


}

//--- Finds changed items that don't require saving,
// e.g. a new item that has subsequently been deleted.  So no need to 
// send to the server a request to delete an item that doesn't exist remotely.
// used to see if the list should be refreshed (if the user saves when there are no "real" changes)
export function hasGhosts(original, working){
  const ghosts  = _.filter(working.checklistItems, i => i.id < 0 && i.isDeleted);
  return Boolean(ghosts && ghosts.length > 0);
}

//--Checks to see if the checklist is in a state where it can be saved.
export function canSaveChecklist(working){
  if(!working) return false;
  else if(working.name?.length < 3) return false;
  
  //Make sure there is at least 1 non-deleted item
  const items   = _.filter(working.checklistItems, cl => !cl.isDeleted);
  if(items.length === 0) return false;

  //Make sure all checklist items have text...
  const missingName   = _.filter(items, i => !i.assertionText && !i.sectionName);
  if(missingName.length > 0) return false;

  return true;
}