import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { authChanged, initializeApp } from '../../store/actions/app-actions';
import Initializing from './initializing';
import Login from './login';
import ErrorView from 'features/common/error-view';
import ReportsView from 'features/reports/reports-view';
import HomeView from 'features/home/home-view';
import AppRouter from './app-router';
import { appRoutes } from 'utils/route-helpers';
import ProtectedRoute from 'features/common/protected-route';

const AppContainer = ({serverConfig}) => {
  const { isInitialized, isAuthenticated, isLoadingSettings, error, tokenError, initializeError } = useSelector(rootState => rootState.app);
  const dispatch = useDispatch();
  
  const { instance } = useMsal();
  const isMsalAuthed = useIsAuthenticated();

  const [isAuthInitialized, setAuthInitialized] = useState(false);
  const isError = useMemo(() => Boolean(tokenError || error || initializeError), [tokenError, error, initializeError]);
  const isBusy = useMemo(() => !isError && (isLoadingSettings || !isInitialized || !isAuthInitialized), [isError, isLoadingSettings, isInitialized, isAuthInitialized]);

  useEffect(() => {
    async function doEffect(){
      await dispatch(initializeApp(serverConfig));
    }
  
    if(!isInitialized){
      doEffect()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function doEffect(){
      if(!isAuthenticated && isMsalAuthed){ //If the store doesn't know yet
        const activeAccount = instance.getActiveAccount();
        await dispatch(authChanged(activeAccount));  
      }
      setAuthInitialized(true); 
    }  
    doEffect();
  }, [instance, isAuthenticated, isMsalAuthed, dispatch]);

  return (
      <div id="app-container">
        { isBusy && <Initializing/>} 
        { isError && <ErrorView error={initializeError || error} message="Oops, something went wrong!" />}
        { (!isBusy && !isError) && 
          <Routes>
            <Route path={appRoutes.reports.list} >
              <Route path="*" element={
                <ProtectedRoute>
                  <ReportsView />
                </ProtectedRoute>
              } />
            </Route>

            <Route path={appRoutes.login} element={<Login />} />
            
            <Route path="/" element={
              <ProtectedRoute>
                <HomeView />
              </ProtectedRoute>
            } />

            <Route path="/*" element={
              <ProtectedRoute>
                <AppRouter />
              </ProtectedRoute>
            } />

          </Routes>            
        }           
      </div>        
  );
}

export default AppContainer;